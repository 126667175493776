import { Breadcrumb } from "flowbite-react";
import Card from "../components/NewProducts/Card";
import TokenData from "../components/TokenInfo/TokenData";
import { HiHome } from "react-icons/hi";

const AllProductsPage = () => {
  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto">
      <div className="container">
        <Breadcrumb className="my-6 ">
          <Breadcrumb.Item href="/" icon={HiHome}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/allProducts">{"Products"}</Breadcrumb.Item>
        </Breadcrumb>
        <h2 className="mb-4 text-3xl font-bold !leading-tight text-center text-black dark:text-white sm:text-4xl md:text-[45px]">Popular tokens</h2>
        <div className="flex flex-wrap gap-10 justify-center py-16">
          {TokenData.map((cardItem) => (
            <Card key={cardItem.id} cardItem={cardItem} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default AllProductsPage;
