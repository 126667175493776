import React, { useEffect } from "react";
import {
  Element,
  animateScroll as scroll,
  scrollSpy as scrollSpy1,
} from "react-scroll";

const ScrollSpy = ({ descr }) => {
  useEffect(() => {
    scrollSpy1.update();
  }, []);

  return (
    <div className="flex relative justify-center">
      <div className="w-full md:w-3/4 px-4">
        {descr.map((item, key) => (
          <Element name={item.title} key={key} className="mb-8">
            <div className="flex items-center">
              <h2 className="flex-2 text-xl font-bold min-w-[200px]">
                {item.title}
              </h2>
              <hr className="flex-1 border-1 border-purple-500 w-full ml-10" />
            </div>
            <div className="my-10">{item.text}</div>
            {item.img ? (
              <div className="flex justify-center">
                <img src={item.img} alt={item.title} className="rounded-xl" />
              </div>
            ) : null}
          </Element>
        ))}
      </div>
    </div>
  );
}
export default ScrollSpy;
