import { useTranslation } from "react-i18next";
import AboutSection from "../components/AboutSection/AboutSection";
import AboutSectionTwo from "../components/AboutSectionTwo/AboutSection";
import Brands from "../components/Brands/Brands";
import Parallax from "../components/Parallax/Parallax";
import Testimonials from "../components/Testimonials/Testimonials";
import Features from "./../components/Features/Features";
import NewProducts from "./../components/NewProducts/NewProducts";

const MainPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <Parallax />
        <div className="h-fit bg-black flex justify-center items-center ">
          <div className="w-full mx-auto text-center my-5">
            <h2 className="mb-4 text-3xl font-bold !leading-tight text-white sm:text-4xl md:text-[45px]">
              {t("mainPage.features.title")}
            </h2>
            <p className="text-base mx-[20px] lg:mx-[250px] !leading-relaxed text-white md:text-lg ">
              {t("mainPage.features.descr")}
            </p>
          </div>
        </div>
        <Features />
        <NewProducts />
        <div
          className="footer_img h-[120px] w-full"
          style={{ backgroundPosition: "top" }}
        ></div>
        <AboutSection />
        <AboutSectionTwo />
        <Brands />
        <Testimonials />
      </div>
    </>
  );
};

export default MainPage;
