import { useRef } from "react";
import { motion } from "framer-motion";

const SingleFeature = ({ feature }) => {
  const { icon, title, paragraph } = feature;
  const ref = useRef(null);

  return (
    <motion.div className="w-full bg-black/50 rounded-3xl  backdrop-blur-xl">
    <div className="p-5">
      <div className="flex mb-5 items-center gap-3 2xl:gap-10">
        <div className="flex h-[70px] w-[70px] items-center justify-center rounded-l text-primary bg-black">
          {icon}
        </div>
        <h3 className="text-lg font-bold text-white xl:text-xl">
        {title}
        </h3>
      </div>

      <div className="pr-2.5 text-sm xl:text-base leading-relaxed text-white">
        <span>
          {paragraph}
        </span>
      </div>
    </div>
  </motion.div>
  );
};

export default SingleFeature;
