import { useTranslation } from "react-i18next";
import jsonData from "../public/locales/ru/translation.json";

const TariffsPage = () => {
  const { t } = useTranslation();
  function Table(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      const tariff = jsonData.Tariffs.table.tbody["r" + i];
      if ("stars" in tariff) {
        paragraphs.push(
          <tr key={i} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{t(`Tariffs.table.tbody.r${i}.1`)}</td>
            <td className="px-6 py-4">{t(`Tariffs.table.tbody.r${i}.2`)}</td>
            <td className="px-6 py-4">
              {t(`Tariffs.table.tbody.r${i}.3`)}
              <span className="text-red-700">{tariff.stars}</span>
            </td>
            <td className="px-6 py-4">{t(`Tariffs.table.tbody.r${i}.4`)}</td>
          </tr>
        );
      } else if (tariff && "link" in tariff) {
        paragraphs.push(
          <tr key={i} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{t(`Tariffs.table.tbody.r${i}.1`)}</td>
            <td className="px-6 py-4">{t(`Tariffs.table.tbody.r${i}.2`)}</td>
            <td className="px-6 py-4">
              {t(`Tariffs.table.tbody.r${i}.3`)}
              <a href={tariff.link} className="text-blue-500">
                {tariff.link}
              </a>
            </td>
            <td className="px-6 py-4">{t(`Tariffs.table.tbody.r${i}.4`)}</td>
          </tr>
        );
      } else {
        paragraphs.push(
          <tr key={i} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{t(`Tariffs.table.tbody.r${i}.1`)}</td>
            <td className="px-6 py-4"> {t(`Tariffs.table.tbody.r${i}.2`)}</td>
            <td className="px-6 py-4"> {t(`Tariffs.table.tbody.r${i}.3`)}</td>
            <td className="px-6 py-4">{t(`Tariffs.table.tbody.r${i}.4`)}</td>
          </tr>
        );
      }
    }
    return <>{paragraphs}</>;
  }

  function Paragraphs(p, end, star = false) {
    const paragraphs = [];

    for (let i = 1; i <= end; i++) {
      for (let j = 0; j < i; j++) {
        paragraphs.push(
          <span className="text-red-700" key={j + i}>
            *
          </span>
        );
      }
      paragraphs.push(
        <>
          <span key={i} className="mt-5">
            {t(`Tariffs.CommentTariffs.paragraph${p}.descr.${i}`)}
          </span>
          <br />
        </>
      );
    }
    return <>{paragraphs}</>;
  }
  function Paragraph(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="mt-5">
          {t(`Invate.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <>{paragraphs}</>;
  }

  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">{t("Invate.paragraph1.title")}</h2>
        {Paragraph(1, 2)}

        <p className="mt-5">
          {t(`Invate.paragraph1.descr.3`)}
          <span className="font-bold"> {t(`Invate.paragraph1.descr.4`)}</span>
        </p>
        <p className="mt-5">{t(`Invate.paragraph1.descr.5`)}</p>
        <p className="mt-5 text-green-700 dark:text-green-400 font-bold">{t(`Invate.paragraph1.descr.6`)}</p>

        <ul className="list-disc list-inside ps-10 ">
          <li className="font-normal mt-5">{t(`Invate.paragraph1.descr.7`)}</li>
          <li className="font-normal my-3">{t(`Invate.paragraph1.descr.8`)}</li>
          <li className="font-normal my-3">{t(`Invate.paragraph1.descr.9`)}</li>
          <li className="font-normal my-3">{t(`Invate.paragraph1.descr.10`)}</li>
          <li className="font-normal my-3">{t(`Invate.paragraph1.descr.11`)}</li>
        </ul>

        <p className="mt-5">{t(`Invate.paragraph1.descr.12`)}</p>

        <ul className="list-disc list-inside ps-10 ">
          <li className="mt-5">{t(`Invate.paragraph1.descr.13`)}</li>
          <li className="">{t(`Invate.paragraph1.descr.14`)}</li>
          <li className="">{t(`Invate.paragraph1.descr.15`)}</li>
        </ul>
        <p className="mt-5">
          {t(`Invate.paragraph1.descr.16`)}
          <span className="font-bold text-green-700 dark:text-green-400 "> {t(`Invate.paragraph1.descr.17`)}</span>
        </p>
        {Paragraph(1, 20, 18)}
        <p className="mt-5 font-normal">{t(`Invate.paragraph1.descr.21`)}</p>
        <p className="mt-5 font-bold">{t(`Invate.paragraph1.descr.22`)}</p>
        <p className="mt-5 font-normal  ">{t(`Invate.paragraph1.descr.23`)}</p>
        <p className="mt-5">{t(`Invate.paragraph1.descr.24`)}</p>
        <p className="">{t(`Invate.paragraph1.descr.25`)}</p>

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10 text-blue-700 dark:text-blue-400">{t("Tariffs.title")}</h2>

        <table className="block overflow-auto rounded-3xl text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
          <thead className="text-xs text-black uppercase bg-gray-50 dark:bg-gray-700 dark:text-white">
            <tr>
              <th scope="col" className="px-6 py-3">
                {t("Tariffs.table.thead.1")}
              </th>
              <th scope="col" className="px-6 py-3 ">
                {t("Tariffs.table.thead.2")}
              </th>
              <th scope="col" className="px-6 py-3 ">
                {t("Tariffs.table.thead.3")}
              </th>
              <th scope="col" className="px-6 py-3 ">
                {t("Tariffs.table.thead.4")}
              </th>
            </tr>
          </thead>
          <tbody>
            {Table(1, 15)}
            <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
              <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{t(`Tariffs.table.tbody.r16.1`)}</td>
              <td className="px-6 py-4">
                {t(`Tariffs.table.tbody.r16.2`)}
                <span className="text-red-700">****</span>
              </td>
              <td className="px-6 py-4">{t(`Tariffs.table.tbody.r16.3`)}</td>
              <td className="px-6 py-4">{t(`Tariffs.table.tbody.r16.4`)}</td>
            </tr>
            {Table(1, 41, 17)}
          </tbody>
        </table>

        <div className="mt-10">{Paragraphs(1, 5)}</div>
        <p className="mt-10">{t("Tariffs.CommentTariffs.paragraph2.descr.1")}</p>
        <p className="mt-10">
          {t("Tariffs.CommentTariffs.paragraph2.descr.2")}
          <a className="text-blue-500" href="/TariffsTermPage">
            {t("Tariffs.CommentTariffs.paragraph2.descr.link")}
          </a>
        </p>
      </div>
    </section>
  );
};

export default TariffsPage;
