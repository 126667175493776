import { useTranslation } from "react-i18next";

const ListCountriesPage = () => {
  const { t } = useTranslation();
  return (
    <section className="py-16 md:py-20 lg:py-24 h-fit md:h-fit text-black dark:text-white">
      <div className="container">
        <h1 className="text-3xl font-bold pt-9 leading-8">
          {t("ListCountries.paragraph1.title")}
        </h1>
        <p className="py-3 text-lg font-normal leading-8">
        {t("ListCountries.paragraph1.descr.1")}
        </p>
        <p className="py-3 text-lg font-normal leading-8">
          {t("ListCountries.paragraph2.title")}
          <a href="/termsOfUse" className="text-primary">
          {t("ListCountries.paragraph2.link")}
          </a>
          :
        </p>
        <ul className="flex flex-col list-disc pl-10 gap-1 text-gray-600 dark:text-gray-300">
          <li> {t("ListCountries.paragraph2.descr.1")}</li>
          <li>{t("ListCountries.paragraph2.descr.2")}</li>
          <li>{t("ListCountries.paragraph2.descr.3")}</li>
          <li>{t("ListCountries.paragraph2.descr.4")}</li>
          <li>{t("ListCountries.paragraph2.descr.5")}</li>
          <li>{t("ListCountries.paragraph2.descr.6")}</li>
          <li>{t("ListCountries.paragraph2.descr.7")}</li>
          <li> {t("ListCountries.paragraph2.descr.8")}</li>
          <li> {t("ListCountries.paragraph2.descr.9")}</li>
          <li> {t("ListCountries.paragraph2.descr.10")}</li>
          <li> {t("ListCountries.paragraph2.descr.11")}</li>
          <li> {t("ListCountries.paragraph2.descr.12")}</li>
          <li> {t("ListCountries.paragraph2.descr.13")}</li>
          <li> {t("ListCountries.paragraph2.descr.14")}</li>
          <li> {t("ListCountries.paragraph2.descr.15")}</li>
          <li> {t("ListCountries.paragraph2.descr.16")}</li>
          <li> {t("ListCountries.paragraph2.descr.17")}</li>
          <li> {t("ListCountries.paragraph2.descr.18")}</li>
          <li> {t("ListCountries.paragraph2.descr.19")}</li>
          <li> {t("ListCountries.paragraph2.descr.20")}</li>
          <li> {t("ListCountries.paragraph2.descr.21")}</li>
          <li> {t("ListCountries.paragraph2.descr.22")}</li>
          <li> {t("ListCountries.paragraph2.descr.23")}</li>
          <li> {t("ListCountries.paragraph2.descr.24")}</li>
          <li> {t("ListCountries.paragraph2.descr.25")}</li>
          <li> {t("ListCountries.paragraph2.descr.26")}</li>
          <li> {t("ListCountries.paragraph2.descr.27")}</li>
          <li> {t("ListCountries.paragraph2.descr.28")}</li>
          <li> {t("ListCountries.paragraph2.descr.29")}</li>
          <li> {t("ListCountries.paragraph2.descr.30")}</li>
          <li> {t("ListCountries.paragraph2.descr.31")}</li>
          <li> {t("ListCountries.paragraph2.descr.32")}</li>
          <li> {t("ListCountries.paragraph2.descr.33")}</li>
          <li> {t("ListCountries.paragraph2.descr.34")}</li>
          <li> {t("ListCountries.paragraph2.descr.35")}</li>
        </ul>
      </div>
    </section>
  );
};

export default ListCountriesPage;
