import { useTranslation } from "react-i18next";
import jsonData from "../public/locales/ru/translation.json";

const TariffsTermPage = () => {
  const { t } = useTranslation();

  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="mt-5">
          {t(`TariffsTermPage.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <>{paragraphs}</>;
  }


  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph1.title")}
        </h2>
        <p className="mt-5 font-bold text-green-400">
          {t(`TariffsTermPage.paragraph1.descr.1`)}
        </p>

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph2.title")}
        </h2>
        {Paragraphs(2, 10)}

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph3.title")}
        </h2>
        {Paragraphs(3, 1)}

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph4.title")}
        </h2>
        {Paragraphs(4, 15)}

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph5.title")}
        </h2>
        {Paragraphs(5, 9)}

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph6.title")}
        </h2>
        {Paragraphs(6, 1)}

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph7.title")}
        </h2>
        {Paragraphs(7, 10)}

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph8.title")}
        </h2>
        {Paragraphs(8, 3)}

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph9.title")}
        </h2>
        {Paragraphs(9, 15)}

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph10.title")}
        </h2>
        {Paragraphs(10, 5)}

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph11.title")}
        </h2>
        {Paragraphs(11, 8)}

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph12.title")}
        </h2>
        {Paragraphs(12, 5)}

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph13.title")}
        </h2>
        {Paragraphs(13, 2)}

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph14.title")}
        </h2>
        {Paragraphs(14, 5)}

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph15.title")}
        </h2>
        <p className="mt-5">{t(`TariffsTermPage.paragraph15.descr.1`)}</p>
        <p className="mt-5">
          {t(`TariffsTermPage.paragraph15.descr.2`)}
          <a className="text-blue-500" href="https://newreality.exchange">
            https://newreality.exchange
          </a>
        </p>
        <p className="mt-5">
          {t(`TariffsTermPage.paragraph15.descr.3.1`)}
          <a className="text-blue-500" href="https://newreality.exchange">
            https://newreality.exchange
          </a>
        </p>
        <p className="mt-5">
          {t(`TariffsTermPage.paragraph15.descr.3.2`)}
          <a className="text-blue-500" href="https://newreality.exchange">
            https://newreality.exchange
          </a>
        </p>
        <p className="mt-5">
          {t(`TariffsTermPage.paragraph15.descr.4`)}
          <a className="text-blue-500" href="https://p2p.newreality.exchange">
            https://p2p.newreality.exchange.
          </a>
        </p>
        <p className="mt-5">{t(`TariffsTermPage.paragraph15.descr.5`)}</p>

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph16.title")}
        </h2>
        {Paragraphs(16, 5)}
        <p className="mt-5">
          {t(`TariffsTermPage.paragraph16.descr.6`)}
          <a className="text-blue-500" href="https://wallet.newreality.club">
          https://wallet.newreality.club
          </a>
        </p>
        {Paragraphs(16, 11, 7)}

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph17.title")}
        </h2>
        {Paragraphs(17, 18)}

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph18.title")}
        </h2>
        <p className="mt-5">
          {t(`TariffsTermPage.paragraph18.descr.1`)}
          <a className="text-blue-500" href=" https://webpayment.bond">
            https://webpayment.bond
          </a>
        </p>
        {Paragraphs(18, 5, 2)}

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph19.title")}
        </h2>
        {Paragraphs(19, 10)}

        <h2 className="text-2xl font-bold leading-8 text-center my-10">
          {t("TariffsTermPage.paragraph20.title")}
        </h2>
        {Paragraphs(20, 6)}
      </div>
    </section>
  );
};

export default TariffsTermPage;
