import { useTranslation } from "react-i18next";

const RegulationPage = () => {
  const { t } = useTranslation();

  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    paragraphs.push(
      <p className="py-3 text-lg font-bold leading-8">
        {t(`Regulation.paragraph${p}.descr.1`)}
      </p>
    );
    for (let i = start + 1; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="py-3 text-lg font-normal leading-8">
          {t(`Regulation.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <div>{paragraphs}</div>;
  }

  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <div className="mx-auto max-w-[1248px]">
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph1.title")}
          </h1>
          {Paragraphs(1, 3)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph2.title")}
          </h1>
          {Paragraphs(2, 9)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph3.title")}
          </h1>
          {Paragraphs(3, 5)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph4.title")}
          </h1>
          {Paragraphs(4, 55)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph5.title")}
          </h1>
          {Paragraphs(5, 23)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph6.title")}
          </h1>
          {Paragraphs(6, 20)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph7.title")}
          </h1>
          {Paragraphs(7, 8)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph8.title")}
          </h1>
          {Paragraphs(8, 8)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph9.title")}
          </h1>
          {Paragraphs(9, 29)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph10.title")}
          </h1>
          {Paragraphs(10, 15)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph11.title")}
          </h1>
          {Paragraphs(11, 2)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph12.title")}
          </h1>
          {Paragraphs(12, 10)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph13.title")}
          </h1>

          {Paragraphs(13, 5)}
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph14.title")}
          </h1>
          {Paragraphs(14, 12)}
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph15.title")}
          </h1>
          {Paragraphs(15, 13)}
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph16.title")}
          </h1>
          {Paragraphs(16, 8)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph17.title")}
          </h1>
          {Paragraphs(17, 25)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph18.title")}
          </h1>
          {Paragraphs(18, 35)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph19.title")}
          </h1>
          {Paragraphs(19, 5)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph20.title")}
          </h1>
          {Paragraphs(20, 10)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph21.title")}
          </h1>
          {Paragraphs(21, 11)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph22.title")}
          </h1>
          {Paragraphs(22, 4)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph23.title")}
          </h1>
          {Paragraphs(23, 35)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph24.title")}
          </h1>
          {Paragraphs(24, 7)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph25.title")}
          </h1>
          {Paragraphs(25, 12)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph26.title")}
          </h1>
          {Paragraphs(26, 9)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph27.title")}
          </h1>
          {Paragraphs(27, 3)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph28.title")}
          </h1>
          {Paragraphs(28, 6)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph29.title")}
          </h1>
          {Paragraphs(29, 9)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph30.title")}
          </h1>
          {Paragraphs(30, 15)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph31.title")}
          </h1>
          {Paragraphs(31, 3)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph32.title")}
          </h1>
          {Paragraphs(32, 15)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph33.title")}
          </h1>
          {Paragraphs(33, 3)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph34.title")}
          </h1>
          {Paragraphs(34, 32)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph35.title")}
          </h1>
          {Paragraphs(35, 27)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph36.title")}
          </h1>
          {Paragraphs(36, 17)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Regulation.paragraph37.title")}
          </h1>
          {Paragraphs(37, 17)}
        </div>
      </div>
    </section>
  );
};

export default RegulationPage;
