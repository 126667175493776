import { Translation } from "react-i18next";

let GIFTC_descr = [
  {
    title: (
      <Translation>
        {(t) => <>{t("GIFTCODE.paragraph1.title")}</>}
      </Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("GIFTCODE.paragraph1.descr.1")}</p>
              <div className="flex flex-col">
                <p>{t("GIFTCODE.paragraph1.descr.2")}</p>
                <p>{t("GIFTCODE.paragraph1.descr.3")}</p>
                <p>{t("GIFTCODE.paragraph1.descr.4")}</p>
                <p>{t("GIFTCODE.paragraph1.descr.5")}</p>
              </div>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat1.png"),
  },
  {
    title: (
      <Translation>
        {(t) => <>{t("GIFTCODE.paragraph2.title")}</>}
      </Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("GIFTCODE.paragraph2.descr.1")}</p>
              <p>{t("GIFTCODE.paragraph2.descr.2")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>
        {(t) => <>{t("GIFTCODE.paragraph3.title")}</>}
      </Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("GIFTCODE.paragraph3.descr.1")}</p>
              <p>{t("GIFTCODE.paragraph3.descr.2")}</p>
              <p>{t("GIFTCODE.paragraph3.descr.3")}</p>
              <p>{t("GIFTCODE.paragraph3.descr.4")}</p>
              <p>{t("GIFTCODE.paragraph3.descr.5")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>
        {(t) => <>{t("GIFTCODE.paragraph4.title")}</>}
      </Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("GIFTCODE.paragraph4.descr.1")}</li>
                <li>{t("GIFTCODE.paragraph4.descr.2")}</li>
                <li>{t("GIFTCODE.paragraph4.descr.3")}</li>
                <li>
                  {t("GIFTCODE.paragraph4.descr.4")}
                  <a href="https://giftcode.bond" className="text-blue-400">
                    https://giftcode.bond
                  </a>
                </li>
                <li>{t("GIFTCODE.paragraph4.descr.5")}</li>
                <li>{t("GIFTCODE.paragraph4.descr.6")}</li>
                <li>{t("GIFTCODE.paragraph4.descr.7")}</li>
                <li>{t("GIFTCODE.paragraph4.descr.8")}</li>
                <li>{t("GIFTCODE.paragraph4.descr.9")}</li>
                <li>{t("GIFTCODE.paragraph4.descr.10")}</li>
                <li>{t("GIFTCODE.paragraph4.descr.11")}</li>
                <li>
                  {t("GIFTCODE.paragraph4.descr.12")}
                  <a
                    href="https://giftcode.bond/wp.pdf"
                    className="text-blue-400"
                  >
                    https://giftcode.bond/wp.pdf
                  </a>
                </li>
              </ul>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat3.jpg"),
  },
  {
    title: (
      <Translation>
        {(t) => <>{t("GIFTCODE.paragraph5.title")}</>}
      </Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("GIFTCODE.paragraph5.descr.1")}</p>
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("GIFTCODE.paragraph5.descr.2")}</li>
                <li>{t("GIFTCODE.paragraph5.descr.3")}</li>
                <li>{t("GIFTCODE.paragraph5.descr.4")}</li>
                <li>{t("GIFTCODE.paragraph5.descr.5")}</li>
              </ul>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat3.jpg"),
  },
  {
    title: (
      <Translation>
        {(t) => <>{t("GIFTCODE.paragraph6.title")}</>}
      </Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4 gap-8">
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("GIFTCODE.paragraph6.descr.1")}</li>
                <li>{t("GIFTCODE.paragraph6.descr.2")}</li>
                <li>{t("GIFTCODE.paragraph6.descr.3")}</li>
                <li>{t("GIFTCODE.paragraph6.descr.4")}</li>
                <li>{t("GIFTCODE.paragraph6.descr.5")}</li>
              </ul>
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("GIFTCODE.paragraph6.descr.6")}</li>
                <li>{t("GIFTCODE.paragraph6.descr.7")}</li>
                <li>{t("GIFTCODE.paragraph6.descr.8")}</li>
                <li>{t("GIFTCODE.paragraph6.descr.9")}</li>
                <li>{t("GIFTCODE.paragraph6.descr.10")}</li>
                <li>{t("GIFTCODE.paragraph6.descr.11")}</li>
                <li>{t("GIFTCODE.paragraph6.descr.12")}</li>
              </ul>
              <div>
                <p className="mb-3">{t("GIFTCODE.paragraph6.descr.13")}</p>
                <ul className="flex flex-col list-disc pl-4 gap-5">
                  <li>{t("GIFTCODE.paragraph6.descr.14")}</li>
                  <li>{t("GIFTCODE.paragraph6.descr.15")}</li>
                  <li>{t("GIFTCODE.paragraph6.descr.16")}</li>
                  <li>{t("GIFTCODE.paragraph6.descr.17")}</li>
                  <li>{t("GIFTCODE.paragraph6.descr.18")}</li>
                </ul>
              </div>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>
        {(t) => <>{t("GIFTCODE.paragraph7.title")}</>}
      </Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4 gap-3">
              <p>{t("GIFTCODE.paragraph7.descr.1")}</p>
              <p>{t("GIFTCODE.paragraph7.descr.2")}</p>
              <p>{t("GIFTCODE.paragraph7.descr.3")}</p>
              <p>{t("GIFTCODE.paragraph7.descr.4")}</p>
              <p>{t("GIFTCODE.paragraph7.descr.5")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
]
 
let BOND_descr =  [
  {
    title: (
      <Translation>{(t) => <>{t("BOND.paragraph1.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("BOND.paragraph1.descr.1")}</p>
              <div className="flex flex-col">
                <p>{t("BOND.paragraph1.descr.2")}</p>
                <p>{t("BOND.paragraph1.descr.3")}</p>
                <p>{t("BOND.paragraph1.descr.4")}</p>
                <p>{t("BOND.paragraph1.descr.5")}</p>
              </div>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat1.png"),
  },
  {
    title: (
      <Translation>{(t) => <>{t("BOND.paragraph2.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("BOND.paragraph2.descr.1")}</p>
              <p>{t("BOND.paragraph2.descr.2")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("BOND.paragraph3.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("BOND.paragraph3.descr.1")}</p>
              <p>{t("BOND.paragraph3.descr.2")}</p>
              <p>{t("BOND.paragraph3.descr.3")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("BOND.paragraph4.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("BOND.paragraph4.descr.1")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("BOND.paragraph5.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("BOND.paragraph5.descr.1")}</p>
              <p>{t("BOND.paragraph5.descr.2")}</p>
              <p>{t("BOND.paragraph5.descr.3")}</p>
              <p>{t("BOND.paragraph5.descr.4")}</p>
              <p>{t("BOND.paragraph5.descr.5")}</p>
              <p>{t("BOND.paragraph5.descr.6")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("BOND.paragraph6.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("BOND.paragraph6.descr.1")}</li>
                <li>{t("BOND.paragraph6.descr.2")}</li>
                <li>{t("BOND.paragraph6.descr.3")}</li>
                <li>
                  {t("BOND.paragraph6.descr.4")}
                  <a href="https://bond.money" className="text-blue-400">
                    https://bond.money
                  </a>
                </li>
                <li>{t("BOND.paragraph6.descr.5")}</li>
                <li>{t("BOND.paragraph6.descr.6")}</li>
                <li>{t("BOND.paragraph6.descr.7")}</li>
                <li>{t("BOND.paragraph6.descr.8")}</li>
                <li>{t("BOND.paragraph6.descr.9")}</li>
                <li>{t("BOND.paragraph6.descr.10")}</li>
                <li>{t("BOND.paragraph6.descr.11")}</li>
                <li>
                  {t("BOND.paragraph6.descr.12")}
                  <a
                    href="https://bond.money/wp.pdf"
                    className="text-blue-400"
                  >
                    https://bond.money/wp.pdf
                  </a>
                </li>
              </ul>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat3.jpg"),
  },
  {
    title: (
      <Translation>{(t) => <>{t("BOND.paragraph7.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("BOND.paragraph5.descr.1")}</p>
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("BOND.paragraph7.descr.2")}</li>
                <li>{t("BOND.paragraph7.descr.3")}</li>
                <li>{t("BOND.paragraph7.descr.4")}</li>
                <li>{t("BOND.paragraph7.descr.5")}</li>
              </ul>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat3.jpg"),
  },
  {
    title: (
      <Translation>{(t) => <>{t("BOND.paragraph8.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4 gap-8">
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("BOND.paragraph8.descr.1")}</li>
                <li>{t("BOND.paragraph8.descr.2")}</li>
                <li>{t("BOND.paragraph8.descr.3")}</li>
                <li>{t("BOND.paragraph8.descr.4")}</li>
                <li>{t("BOND.paragraph8.descr.5")}</li>
              </ul>
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("BOND.paragraph8.descr.6")}</li>
                <li>{t("BOND.paragraph8.descr.7")}</li>
                <li>{t("BOND.paragraph8.descr.8")}</li>
                <li>{t("BOND.paragraph8.descr.9")}</li>
                <li>{t("BOND.paragraph8.descr.10")}</li>
                <li>{t("BOND.paragraph8.descr.11")}</li>
                <li>{t("BOND.paragraph8.descr.12")}</li>
              </ul>
              <div>
                <p className="mb-3">{t("BOND.paragraph8.descr.13")}</p>
                <ul className="flex flex-col list-disc pl-4 gap-5">
                  <li>{t("BOND.paragraph8.descr.14")}</li>
                  <li>{t("BOND.paragraph8.descr.15")}</li>
                  <li>{t("BOND.paragraph8.descr.16")}</li>
                  <li>{t("BOND.paragraph8.descr.17")}</li>
                  <li>{t("BOND.paragraph8.descr.18")}</li>
                </ul>
              </div>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("BOND.paragraph9.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4 gap-3">
              <p>{t("BOND.paragraph9.descr.1")}</p>
              <p>{t("BOND.paragraph9.descr.2")}</p>
              <p>{t("BOND.paragraph9.descr.3")}</p>
              <p>{t("BOND.paragraph9.descr.4")}</p>
              <p>{t("BOND.paragraph9.descr.5")}</p>
              <p>{t("BOND.paragraph9.descr.6")}</p>
              <p>{t("BOND.paragraph9.descr.7")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
]

let DELTA_descr = [
  {
    title: (
      <Translation>
        {(t) => <>{t("DELTAKEY.paragraph1.title")}</>}
      </Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("DELTAKEY.paragraph1.descr.1")}</p>
              <div className="flex flex-col">
                <p>{t("DELTAKEY.paragraph1.descr.2")}</p>
                <p>{t("DELTAKEY.paragraph1.descr.3")}</p>
                <p>{t("DELTAKEY.paragraph1.descr.4")}</p>
                <p>{t("DELTAKEY.paragraph1.descr.5")}</p>
              </div>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat1.png"),
  },
  {
    title: (
      <Translation>
        {(t) => <>{t("DELTAKEY.paragraph2.title")}</>}
      </Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("DELTAKEY.paragraph2.descr.1")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>
        {(t) => <>{t("DELTAKEY.paragraph3.title")}</>}
      </Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("DELTAKEY.paragraph3.descr.1")}</p>
              <p>{t("DELTAKEY.paragraph3.descr.2")}</p>
              <p>{t("DELTAKEY.paragraph3.descr.3")}</p>
              <p>{t("DELTAKEY.paragraph3.descr.4")}</p>
              <p>{t("DELTAKEY.paragraph3.descr.5")}</p>
              <p>{t("DELTAKEY.paragraph3.descr.6")}</p>
              <p>{t("DELTAKEY.paragraph3.descr.7")}</p>
              <p>{t("DELTAKEY.paragraph3.descr.8")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>
        {(t) => <>{t("DELTAKEY.paragraph4.title")}</>}
      </Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("DELTAKEY.paragraph4.descr.1")}</li>
                <li>{t("DELTAKEY.paragraph4.descr.2")}</li>
                <li>{t("DELTAKEY.paragraph4.descr.3")}</li>
                <li>
                  {t("DELTAKEY.paragraph4.descr.4")}
                  <a href="https://deltakey.ltd" className="text-blue-400">
                    https://deltakey.ltd
                  </a>
                </li>
                <li>{t("DELTAKEY.paragraph4.descr.5")}</li>
                <li>{t("DELTAKEY.paragraph4.descr.6")}</li>
                <li>{t("DELTAKEY.paragraph4.descr.7")}</li>
                <li>{t("DELTAKEY.paragraph4.descr.8")}</li>
                <li>{t("DELTAKEY.paragraph4.descr.9")}</li>
                <li>{t("DELTAKEY.paragraph4.descr.10")}</li>
                <li>{t("DELTAKEY.paragraph4.descr.11")}</li>
                <li>
                  {t("DELTAKEY.paragraph4.descr.12")}
                  <a
                    href="https://deltakey.ltd/wp.pdf"
                    className="text-blue-400"
                  >
                    https://deltakey.ltd/wp.pdf
                  </a>
                </li>
              </ul>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat3.jpg"),
  },
  {
    title: (
      <Translation>
        {(t) => <>{t("DELTAKEY.paragraph5.title")}</>}
      </Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("DELTAKEY.paragraph5.descr.1")}</p>
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("DELTAKEY.paragraph5.descr.2")}</li>
                <li>{t("DELTAKEY.paragraph5.descr.3")}</li>
                <li>{t("DELTAKEY.paragraph5.descr.4")}</li>
                <li>{t("DELTAKEY.paragraph5.descr.5")}</li>
              </ul>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat3.jpg"),
  },
  {
    title: (
      <Translation>
        {(t) => <>{t("DELTAKEY.paragraph6.title")}</>}
      </Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4 gap-8">
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("DELTAKEY.paragraph6.descr.1")}</li>
                <li>{t("DELTAKEY.paragraph6.descr.2")}</li>
                <li>{t("DELTAKEY.paragraph6.descr.3")}</li>
                <li>{t("DELTAKEY.paragraph6.descr.4")}</li>
                <li>{t("DELTAKEY.paragraph6.descr.5")}</li>
              </ul>
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("DELTAKEY.paragraph6.descr.6")}</li>
                <li>{t("DELTAKEY.paragraph6.descr.7")}</li>
                <li>{t("DELTAKEY.paragraph6.descr.8")}</li>
                <li>{t("DELTAKEY.paragraph6.descr.9")}</li>
                <li>{t("DELTAKEY.paragraph6.descr.10")}</li>
                <li>{t("DELTAKEY.paragraph6.descr.11")}</li>
                <li>{t("DELTAKEY.paragraph6.descr.12")}</li>
              </ul>
              <div>
                <p className="mb-3">{t("DELTAKEY.paragraph6.descr.13")}</p>
                <ul className="flex flex-col list-disc pl-4 gap-5">
                  <li>{t("DELTAKEY.paragraph6.descr.14")}</li>
                  <li>{t("DELTAKEY.paragraph6.descr.15")}</li>
                  <li>{t("DELTAKEY.paragraph6.descr.16")}</li>
                  <li>{t("DELTAKEY.paragraph6.descr.17")}</li>
                  <li>{t("DELTAKEY.paragraph6.descr.18")}</li>
                </ul>
              </div>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>
        {(t) => <>{t("DELTAKEY.paragraph7.title")}</>}
      </Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4 gap-3">
              <p>{t("DELTAKEY.paragraph7.descr.1")}</p>
              <p>{t("DELTAKEY.paragraph7.descr.2")}</p>
              <p>{t("DELTAKEY.paragraph7.descr.3")}</p>
              <p>{t("DELTAKEY.paragraph7.descr.4")}</p>
              <p>{t("DELTAKEY.paragraph7.descr.5")}</p>
              <p>{t("DELTAKEY.paragraph7.descr.6")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
]

let ATMC_descr = [
  {
    title: (
      <Translation>{(t) => <>{t("ATMC.paragraph1.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("ATMC.paragraph1.descr.1")}</p>
              <div className="flex flex-col">
                <p>{t("ATMC.paragraph1.descr.2")}</p>
                <p>{t("ATMC.paragraph1.descr.3")}</p>
                <p>{t("ATMC.paragraph1.descr.4")}</p>
                <p>{t("ATMC.paragraph1.descr.5")}</p>
              </div>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat1.png"),
  },
  {
    title: (
      <Translation>{(t) => <>{t("ATMC.paragraph2.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("ATMC.paragraph2.descr.1")}</p>
              <p>{t("ATMC.paragraph2.descr.2")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("ATMC.paragraph3.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("ATMC.paragraph3.descr.1")}</p>
              <p>{t("ATMC.paragraph3.descr.2")}</p>
              <p>{t("ATMC.paragraph3.descr.3")}</p>
              <p>{t("ATMC.paragraph3.descr.4")}</p>
              <p>{t("ATMC.paragraph3.descr.5")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("ATMC.paragraph4.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("ATMC.paragraph4.descr.1")}</li>
                <li>{t("ATMC.paragraph4.descr.2")}</li>
                <li>{t("ATMC.paragraph4.descr.3")}</li>
                <li>
                  {t("ATMC.paragraph4.descr.4")}
                  <a href="https://atm.credit" className="text-blue-400">
                    https://atm.credit
                  </a>
                </li>
                <li>{t("ATMC.paragraph4.descr.5")}</li>
                <li>{t("ATMC.paragraph4.descr.6")}</li>
                <li>{t("ATMC.paragraph4.descr.7")}</li>
                <li>{t("ATMC.paragraph4.descr.8")}</li>
                <li>{t("ATMC.paragraph4.descr.9")}</li>
                <li>{t("ATMC.paragraph4.descr.10")}</li>
                <li>{t("ATMC.paragraph4.descr.11")}</li>
                <li>
                  {t("ATMC.paragraph4.descr.12")}
                  <a
                    href="https://atm.credit/wp.pdf"
                    className="text-blue-400"
                  >
                    https://atm.credit/wp.pdf
                  </a>
                </li>
              </ul>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat3.jpg"),
  },
  {
    title: (
      <Translation>{(t) => <>{t("ATMC.paragraph5.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("ATMC.paragraph5.descr.1")}</p>
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("ATMC.paragraph5.descr.2")}</li>
                <li>{t("ATMC.paragraph5.descr.3")}</li>
                <li>{t("ATMC.paragraph5.descr.4")}</li>
                <li>{t("ATMC.paragraph5.descr.5")}</li>
              </ul>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat3.jpg"),
  },
  {
    title: (
      <Translation>{(t) => <>{t("ATMC.paragraph6.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4 gap-8">
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("ATMC.paragraph6.descr.1")}</li>
                <li>{t("ATMC.paragraph6.descr.2")}</li>
                <li>{t("ATMC.paragraph6.descr.3")}</li>
                <li>{t("ATMC.paragraph6.descr.4")}</li>
                <li>{t("ATMC.paragraph6.descr.5")}</li>
              </ul>
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("ATMC.paragraph6.descr.6")}</li>
                <li>{t("ATMC.paragraph6.descr.7")}</li>
                <li>{t("ATMC.paragraph6.descr.8")}</li>
                <li>{t("ATMC.paragraph6.descr.9")}</li>
                <li>{t("ATMC.paragraph6.descr.10")}</li>
                <li>{t("ATMC.paragraph6.descr.11")}</li>
                <li>{t("ATMC.paragraph6.descr.12")}</li>
              </ul>
              <div>
                <p className="mb-3">{t("ATMC.paragraph6.descr.13")}</p>
                <ul className="flex flex-col list-disc pl-4 gap-5">
                  <li>{t("ATMC.paragraph6.descr.14")}</li>
                  <li>{t("ATMC.paragraph6.descr.15")}</li>
                  <li>{t("ATMC.paragraph6.descr.16")}</li>
                  <li>{t("ATMC.paragraph6.descr.17")}</li>
                  <li>{t("ATMC.paragraph6.descr.18")}</li>
                </ul>
              </div>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("ATMC.paragraph7.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4 gap-3">
              <p>{t("ATMC.paragraph7.descr.1")}</p>
              <p>{t("ATMC.paragraph7.descr.2")}</p>
              <p>{t("ATMC.paragraph7.descr.3")}</p>
              <p>{t("ATMC.paragraph7.descr.4")}</p>
              <p>{t("ATMC.paragraph7.descr.5")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
]

let ELIOS_descr =  [
  {
    title: (
      <Translation>{(t) => <>{t("ELIOS.paragraph1.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("ELIOS.paragraph1.descr.1")}</p>
              <div className="flex flex-col">
                <p>{t("ELIOS.paragraph1.descr.2")}</p>
                <p>{t("ELIOS.paragraph1.descr.3")}</p>
                <p>{t("ELIOS.paragraph1.descr.4")}</p>
                <p>{t("ELIOS.paragraph1.descr.5")}</p>
              </div>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("ELIOS.paragraph2.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("ELIOS.paragraph2.descr.1")}</p>
              <p>{t("ELIOS.paragraph2.descr.2")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("ELIOS.paragraph3.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("ELIOS.paragraph3.descr.1")}</p>
              <p>{t("ELIOS.paragraph3.descr.2")}</p>
              <p>{t("ELIOS.paragraph3.descr.3")}</p>
              <ul className="list-disc pl-4">
                <li>{t("ELIOS.paragraph3.descr.4")}</li>
                <li>{t("ELIOS.paragraph3.descr.5")}</li>
                <li>{t("ELIOS.paragraph3.descr.6")}</li>
                <li>{t("ELIOS.paragraph3.descr.7")}</li>
                <li>{t("ELIOS.paragraph3.descr.8")}</li>
                <li>{t("ELIOS.paragraph3.descr.9")}</li>
              </ul>
              <p>{t("ELIOS.paragraph3.descr.10")}</p>
              <p>{t("ELIOS.paragraph3.descr.11")}</p>
              <p>{t("ELIOS.paragraph3.descr.12")}</p>
              <p>{t("ELIOS.paragraph3.descr.13")}</p>
              <p>{t("ELIOS.paragraph3.descr.14")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("ELIOS.paragraph4.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("ELIOS.paragraph4.descr.1")}</li>
                <li>{t("ELIOS.paragraph4.descr.2")}</li>
                <li>{t("ELIOS.paragraph4.descr.3")}</li>
                <li>
                  {t("ELIOS.paragraph4.descr.4")}
                  <a href="https://elios.gold" className="text-blue-400">
                    https://elios.gold
                  </a>
                </li>
                <li>{t("ELIOS.paragraph4.descr.5")}</li>
                <li>{t("ELIOS.paragraph4.descr.6")}</li>
                <li>{t("ELIOS.paragraph4.descr.7")}</li>
                <li>{t("ELIOS.paragraph4.descr.8")}</li>
                <li>{t("ELIOS.paragraph4.descr.9")}</li>
                <li>{t("ELIOS.paragraph4.descr.10")}</li>
                <li>{t("ELIOS.paragraph4.descr.11")}</li>
                <li>
                  {t("ELIOS.paragraph4.descr.12")}
                  <a
                    href="https://elios.gold/wp.pdf"
                    className="text-blue-400"
                  >
                    https://elios.gold/wp.pdf
                  </a>
                </li>
              </ul>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat3.jpg"),
  },
  {
    title: (
      <Translation>{(t) => <>{t("ELIOS.paragraph5.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("ELIOS.paragraph5.descr.1")}</p>
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("ELIOS.paragraph5.descr.2")}</li>
                <li>{t("ELIOS.paragraph5.descr.3")}</li>
                <li>{t("ELIOS.paragraph5.descr.4")}</li>
                <li>{t("ELIOS.paragraph5.descr.5")}</li>
              </ul>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat3.jpg"),
  },
  {
    title: (
      <Translation>{(t) => <>{t("ELIOS.paragraph6.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4 gap-8">
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("ELIOS.paragraph6.descr.1")}</li>
                <li>{t("ELIOS.paragraph6.descr.2")}</li>
                <li>{t("ELIOS.paragraph6.descr.3")}</li>
                <li>{t("ELIOS.paragraph6.descr.4")}</li>
                <li>{t("ELIOS.paragraph6.descr.5")}</li>
              </ul>
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("ELIOS.paragraph6.descr.6")}</li>
                <li>{t("ELIOS.paragraph6.descr.7")}</li>
                <li>{t("ELIOS.paragraph6.descr.8")}</li>
                <li>{t("ELIOS.paragraph6.descr.9")}</li>
                <li>{t("ELIOS.paragraph6.descr.10")}</li>
                <li>{t("ELIOS.paragraph6.descr.11")}</li>
                <li>{t("ELIOS.paragraph6.descr.12")}</li>
              </ul>
              <div>
                <p className="mb-3">{t("ELIOS.paragraph6.descr.13")}</p>
                <ul className="flex flex-col list-disc pl-4 gap-5">
                  <li>{t("ELIOS.paragraph6.descr.14")}</li>
                  <li>{t("ELIOS.paragraph6.descr.15")}</li>
                  <li>{t("ELIOS.paragraph6.descr.16")}</li>
                  <li>{t("ELIOS.paragraph6.descr.17")}</li>
                  <li>{t("ELIOS.paragraph6.descr.18")}</li>
                </ul>
              </div>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("ELIOS.paragraph7.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4 gap-3">
              <p>{t("ELIOS.paragraph7.descr.1")}</p>
              <p>{t("ELIOS.paragraph7.descr.2")}</p>
              <p>{t("ELIOS.paragraph7.descr.3")}</p>
              <p>{t("ELIOS.paragraph7.descr.4")}</p>
              <p>{t("ELIOS.paragraph7.descr.5")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
]

let NEWRL_descr = [
  {
    title: (
      <Translation>{(t) => <>{t("NEWRL.paragraph1.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("NEWRL.paragraph1.descr.1")}</p>
              <div className="flex flex-col">
                <p>{t("NEWRL.paragraph1.descr.2")}</p>
                <p>{t("NEWRL.paragraph1.descr.3")}</p>
                <p>{t("NEWRL.paragraph1.descr.4")}</p>
                <p>{t("NEWRL.paragraph1.descr.5")}</p>
              </div>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat1.png"),
  },
  {
    title: (
      <Translation>{(t) => <>{t("NEWRL.paragraph2.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("NEWRL.paragraph2.descr.1")}</p>
              <p>{t("NEWRL.paragraph2.descr.2")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("NEWRL.paragraph3.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("NEWRL.paragraph3.descr.1")}</p>
              <p>{t("NEWRL.paragraph3.descr.2")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("NEWRL.paragraph4.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("NEWRL.paragraph4.descr.1")}</li>
                <li>{t("NEWRL.paragraph4.descr.2")}</li>
                <li>{t("NEWRL.paragraph4.descr.3")}</li>
                <li>
                  {t("NEWRL.paragraph4.descr.4")}
                  <a
                    href="https://newreality.bond"
                    className="text-blue-400"
                  >
                    https://newreality.bond
                  </a>
                </li>
                <li>{t("NEWRL.paragraph4.descr.5")}</li>
                <li>{t("NEWRL.paragraph4.descr.6")}</li>
                <li>{t("NEWRL.paragraph4.descr.7")}</li>
                <li>{t("NEWRL.paragraph4.descr.8")}</li>
                <li>{t("NEWRL.paragraph4.descr.9")}</li>
                <li>{t("NEWRL.paragraph4.descr.10")}</li>
                <li>{t("NEWRL.paragraph4.descr.11")}</li>
                <li>
                  {t("NEWRL.paragraph4.descr.12")}
                  <a
                    href="https://newreality.bond/wp.pdf"
                    className="text-blue-400"
                  >
                    https://newreality.bond/wp.pdf
                  </a>
                </li>
              </ul>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat3.jpg"),
  },
  {
    title: (
      <Translation>{(t) => <>{t("NEWRL.paragraph5.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("NEWRL.paragraph5.descr.1")}</p>
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("NEWRL.paragraph5.descr.2")}</li>
                <li>{t("NEWRL.paragraph5.descr.3")}</li>
                <li>{t("NEWRL.paragraph5.descr.4")}</li>
                <li>{t("NEWRL.paragraph5.descr.5")}</li>
              </ul>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat3.jpg"),
  },
  {
    title: (
      <Translation>{(t) => <>{t("NEWRL.paragraph6.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4 gap-8">
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("NEWRL.paragraph6.descr.1")}</li>
                <li>{t("NEWRL.paragraph6.descr.2")}</li>
                <li>{t("NEWRL.paragraph6.descr.3")}</li>
                <li>{t("NEWRL.paragraph6.descr.4")}</li>
                <li>{t("NEWRL.paragraph6.descr.5")}</li>
              </ul>
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("NEWRL.paragraph6.descr.6")}</li>
                <li>{t("NEWRL.paragraph6.descr.7")}</li>
                <li>{t("NEWRL.paragraph6.descr.8")}</li>
                <li>{t("NEWRL.paragraph6.descr.9")}</li>
                <li>{t("NEWRL.paragraph6.descr.10")}</li>
                <li>{t("NEWRL.paragraph6.descr.11")}</li>
                <li>{t("NEWRL.paragraph6.descr.12")}</li>
              </ul>
              <div>
                <p className="mb-3">{t("NEWRL.paragraph6.descr.13")}</p>
                <ul className="flex flex-col list-disc pl-4 gap-5">
                  <li>{t("NEWRL.paragraph6.descr.14")}</li>
                  <li>{t("NEWRL.paragraph6.descr.15")}</li>
                  <li>{t("NEWRL.paragraph6.descr.16")}</li>
                  <li>{t("NEWRL.paragraph6.descr.17")}</li>
                  <li>{t("NEWRL.paragraph6.descr.18")}</li>
                </ul>
              </div>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("NEWRL.paragraph7.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4 gap-3">
              <p>{t("NEWRL.paragraph7.descr.1")}</p>
              <p>{t("NEWRL.paragraph7.descr.2")}</p>
              <p>{t("NEWRL.paragraph7.descr.3")}</p>
              <p>{t("NEWRL.paragraph7.descr.4")}</p>
              <p>{t("NEWRL.paragraph7.descr.5")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
]

let UNIT_descr = [
  {
    title: (
      <Translation>{(t) => <>{t("UNITRANSFER.paragraph1.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("UNITRANSFER.paragraph1.descr.1")}</p>
              <div className="flex flex-col">
                <p>{t("UNITRANSFER.paragraph1.descr.2")}</p>
                <p>{t("UNITRANSFER.paragraph1.descr.3")}</p>
                <p>{t("UNITRANSFER.paragraph1.descr.4")}</p>
                <p>{t("UNITRANSFER.paragraph1.descr.5")}</p>
              </div>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat1.png"),
  },
  {
    title: (
      <Translation>{(t) => <>{t("UNITRANSFER.paragraph2.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("UNITRANSFER.paragraph2.descr.1")}</p>
              <p>{t("UNITRANSFER.paragraph2.descr.2")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("UNITRANSFER.paragraph3.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("UNITRANSFER.paragraph3.descr.1")}</p>
              <p>{t("UNITRANSFER.paragraph3.descr.2")}</p>
              <p>{t("UNITRANSFER.paragraph3.descr.3")}</p>
              <p>{t("UNITRANSFER.paragraph3.descr.4")}</p>
              <p>{t("UNITRANSFER.paragraph3.descr.5")}</p>
              <p>{t("UNITRANSFER.paragraph3.descr.6")}</p>
              <p>{t("UNITRANSFER.paragraph3.descr.7")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("UNITRANSFER.paragraph4.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("UNITRANSFER.paragraph4.descr.1")}</li>
                <li>{t("UNITRANSFER.paragraph4.descr.2")}</li>
                <li>{t("UNITRANSFER.paragraph4.descr.3")}</li>
                <li>
                  {t("UNITRANSFER.paragraph4.descr.4")}
                  <a href="https://unitransfer.in" className="text-blue-400">
                  https://unitransfer.in
                  </a>
                </li>
                <li>{t("UNITRANSFER.paragraph4.descr.5")}</li>
                <li>{t("UNITRANSFER.paragraph4.descr.6")}</li>
                <li>{t("UNITRANSFER.paragraph4.descr.7")}</li>
                <li>{t("UNITRANSFER.paragraph4.descr.8")}</li>
                <li>{t("UNITRANSFER.paragraph4.descr.9")}</li>
                <li>{t("UNITRANSFER.paragraph4.descr.10")}</li>
                <li>{t("UNITRANSFER.paragraph4.descr.11")}</li>
                <li>
                  {t("UNITRANSFER.paragraph4.descr.12")}
                  <a
                    href="https://unitransfer.in/wp.pdf"
                    className="text-blue-400"
                  >
                    https://unitransfer.in/wp.pdf
                  </a>
                </li>
              </ul>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat3.jpg"),
  },
  {
    title: (
      <Translation>{(t) => <>{t("UNITRANSFER.paragraph5.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("UNITRANSFER.paragraph5.descr.1")}</p>
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("UNITRANSFER.paragraph5.descr.2")}</li>
                <li>{t("UNITRANSFER.paragraph5.descr.3")}</li>
                <li>{t("UNITRANSFER.paragraph5.descr.4")}</li>
                <li>{t("UNITRANSFER.paragraph5.descr.5")}</li>
              </ul>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat3.jpg"),
  },
  {
    title: (
      <Translation>{(t) => <>{t("UNITRANSFER.paragraph6.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4 gap-8">
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("UNITRANSFER.paragraph6.descr.1")}</li>
                <li>{t("UNITRANSFER.paragraph6.descr.2")}</li>
                <li>{t("UNITRANSFER.paragraph6.descr.3")}</li>
                <li>{t("UNITRANSFER.paragraph6.descr.4")}</li>
                <li>{t("UNITRANSFER.paragraph6.descr.5")}</li>
              </ul>
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("UNITRANSFER.paragraph6.descr.6")}</li>
                <li>{t("UNITRANSFER.paragraph6.descr.7")}</li>
                <li>{t("UNITRANSFER.paragraph6.descr.8")}</li>
                <li>{t("UNITRANSFER.paragraph6.descr.9")}</li>
                <li>{t("UNITRANSFER.paragraph6.descr.10")}</li>
                <li>{t("UNITRANSFER.paragraph6.descr.11")}</li>
                <li>{t("UNITRANSFER.paragraph6.descr.12")}</li>
              </ul>
              <div>
                <p className="mb-3">{t("UNITRANSFER.paragraph6.descr.13")}</p>
                <ul className="flex flex-col list-disc pl-4 gap-5">
                  <li>{t("UNITRANSFER.paragraph6.descr.14")}</li>
                  <li>{t("UNITRANSFER.paragraph6.descr.15")}</li>
                  <li>{t("UNITRANSFER.paragraph6.descr.16")}</li>
                  <li>{t("UNITRANSFER.paragraph6.descr.17")}</li>
                  <li>{t("UNITRANSFER.paragraph6.descr.18")}</li>
                </ul>
              </div>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("UNITRANSFER.paragraph7.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4 gap-3">
              <p>{t("UNITRANSFER.paragraph7.descr.1")}</p>
              <p>{t("UNITRANSFER.paragraph7.descr.2")}</p>
              <p>{t("UNITRANSFER.paragraph7.descr.3")}</p>
              <p>{t("UNITRANSFER.paragraph7.descr.4")}</p>
              <p>{t("UNITRANSFER.paragraph7.descr.5")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
]

let QNTS_descr = [
  {
    title: (
      <Translation>{(t) => <>{t("QUANTS.paragraph1.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("QUANTS.paragraph1.descr.1")}</p>
              <div className="flex flex-col">
                <p>{t("QUANTS.paragraph1.descr.2")}</p>
                <p>{t("QUANTS.paragraph1.descr.3")}</p>
                <p>{t("QUANTS.paragraph1.descr.4")}</p>
                <p>{t("QUANTS.paragraph1.descr.5")}</p>
              </div>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat1.png"),
  },
  {
    title: (
      <Translation>{(t) => <>{t("QUANTS.paragraph2.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("QUANTS.paragraph2.descr.1")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("QUANTS.paragraph3.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("QUANTS.paragraph3.descr.1")}</p>
              <p>{t("QUANTS.paragraph3.descr.2")}</p>
              <p>{t("QUANTS.paragraph3.descr.3")}</p>
              <p>{t("QUANTS.paragraph3.descr.4")}</p>
              <p>{t("QUANTS.paragraph3.descr.5")}</p>
              <p>{t("QUANTS.paragraph3.descr.6")}</p>
              <p>{t("QUANTS.paragraph3.descr.7")}</p>
              <p>{t("QUANTS.paragraph3.descr.8")}</p>
              <p>{t("QUANTS.paragraph3.descr.9")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("QUANTS.paragraph4.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("QUANTS.paragraph4.descr.1")}</li>
                <li>{t("QUANTS.paragraph4.descr.2")}</li>
                <li>{t("QUANTS.paragraph4.descr.3")}</li>
                <li>
                  {t("QUANTS.paragraph4.descr.4")}
                  <a href="https://quant.host" className="text-blue-400">
                  https://quant.host
                  </a>
                </li>
                <li>{t("QUANTS.paragraph4.descr.5")}</li>
                <li>{t("QUANTS.paragraph4.descr.6")}</li>
                <li>{t("QUANTS.paragraph4.descr.7")}</li>
                <li>{t("QUANTS.paragraph4.descr.8")}</li>
                <li>{t("QUANTS.paragraph4.descr.9")}</li>
                <li>{t("QUANTS.paragraph4.descr.10")}</li>
                <li>{t("QUANTS.paragraph4.descr.11")}</li>
                <li>
                  {t("QUANTS.paragraph4.descr.12")}
                  <a
                    href="https://quant.host/wp.pdf"
                    className="text-blue-400"
                  >
                    https://quantum.host/wp.pdf
                  </a>
                </li>
              </ul>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat3.jpg"),
  },
  {
    title: (
      <Translation>{(t) => <>{t("QUANTS.paragraph5.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4">
              <p>{t("QUANTS.paragraph5.descr.1")}</p>
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("QUANTS.paragraph5.descr.2")}</li>
                <li>{t("QUANTS.paragraph5.descr.3")}</li>
                <li>{t("QUANTS.paragraph5.descr.4")}</li>
                <li>{t("QUANTS.paragraph5.descr.5")}</li>
              </ul>
            </div>
          </>
        )}
      </Translation>
    ),
    // img: require("../../images/brands/info/cat3.jpg"),
  },
  {
    title: (
      <Translation>{(t) => <>{t("QUANTS.paragraph6.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4 gap-8">
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("QUANTS.paragraph6.descr.1")}</li>
                <li>{t("QUANTS.paragraph6.descr.2")}</li>
                <li>{t("QUANTS.paragraph6.descr.3")}</li>
                <li>{t("QUANTS.paragraph6.descr.4")}</li>
                <li>{t("QUANTS.paragraph6.descr.5")}</li>
              </ul>
              <ul className="flex flex-col list-disc pl-4 gap-5">
                <li>{t("QUANTS.paragraph6.descr.6")}</li>
                <li>{t("QUANTS.paragraph6.descr.7")}</li>
                <li>{t("QUANTS.paragraph6.descr.8")}</li>
                <li>{t("QUANTS.paragraph6.descr.9")}</li>
                <li>{t("QUANTS.paragraph6.descr.10")}</li>
                <li>{t("QUANTS.paragraph6.descr.11")}</li>
                <li>{t("QUANTS.paragraph6.descr.12")}</li>
              </ul>
              <div>
                <p className="mb-3">{t("QUANTS.paragraph6.descr.13")}</p>
                <ul className="flex flex-col list-disc pl-4 gap-5">
                  <li>{t("QUANTS.paragraph6.descr.14")}</li>
                  <li>{t("QUANTS.paragraph6.descr.15")}</li>
                  <li>{t("QUANTS.paragraph6.descr.16")}</li>
                  <li>{t("QUANTS.paragraph6.descr.17")}</li>
                  <li>{t("QUANTS.paragraph6.descr.18")}</li>
                </ul>
              </div>
            </div>
          </>
        )}
      </Translation>
    ),
  },
  {
    title: (
      <Translation>{(t) => <>{t("QUANTS.paragraph7.title")}</>}</Translation>
    ),
    text: (
      <Translation>
        {(t) => (
          <>
            <div className=" flex flex-col space-y-4 gap-3">
              <p>{t("QUANTS.paragraph7.descr.1")}</p>
              <p>{t("QUANTS.paragraph7.descr.2")}</p>
              <p>{t("QUANTS.paragraph7.descr.3")}</p>
              <p>{t("QUANTS.paragraph7.descr.4")}</p>
              <p>{t("QUANTS.paragraph7.descr.5")}</p>
              <p>{t("QUANTS.paragraph7.descr.6")}</p>
            </div>
          </>
        )}
      </Translation>
    ),
  },
]



const TokenData = [
  {
    id: 0,
    imgs: [
      require("../../images/brands/info/giftC1.png"),
      require("../../images/brands/info/giftc4.jpg"),
      require("../../images/brands/info/giftc5.png"),
      require("../../images/brands/info/giftc6.png"),
    ],
    small_descr: (
      <Translation>{(t) => <>{t("GIFTCODE.small_descr")}</>}</Translation>
    ),
    descr: GIFTC_descr,
    bg: require("../../images/abstract/1.png"),
    buy_link: "https://webpayment.bond/giftc.php",
    type: "Unbound Token",
    category: "Gift cards and codes",
    icon: require("../../images/brands/gift1.png"),
    tiker: "GIFTC",
    title: "GiftCode",
    base_price: "€1.00",
    selling_price: "€1.15",
    emission: "€8,000,000.00",
    exponent: '8',
    allocation: "",
    sale: "open sale",
    percent: "",
    popular: false,
    link: "https://giftcode.bond/",
  },
  {
    id: 1,
    imgs: [
      require("../../images/brands/bond/bond1.png"),
      require("../../images/brands/bond/bond2.png"),
    ],
    small_descr: (
      <Translation>{(t) => <>{t("BOND.small_descr")}</>}</Translation>
    ),
    descr: BOND_descr,
    bg: require("../../images/abstract/2.png"),
    buy_link: "https://webpayment.bond/bond.php",
    type: "Unbound Token",
    category: "Reinvestment",
    icon: require("../../images/brands/bond.png"),
    tiker: "Bond",
    title: "Bond Money",
    base_price: "€1.00",
    selling_price: "€1.10",
    emission: "€10,000,000.00",
    exponent: '2',
    allocation: "",
    sale: "open sale",
    percent: "",
    popular: false,
    link: "https://bond.money/",
    legal: "EU",
  },
  {
    id: 2,
    imgs: [
      require("../../images/brands/deltakey/1.png"),
    ],
    small_descr: (
      <Translation>{(t) => <>{t("DELTAKEY.small_descr")}</>}</Translation>
    ),
    descr: DELTA_descr,
    bg: require("../../images/abstract/3.png"),
    buy_link: "https://webpayment.bond/dlk.php",
    type: "Unbound Token",
    category: "Software and hardware development",
    icon: require("../../images/brands/delta.png"),
    tiker: "DELTAKEY",
    title: "Delta Key",
    base_price: "€1,00",
    selling_price: "€1,20",
    allocation: "35%",
    emission: "€10,000,000.00",
    exponent: '2',
    sale: "open sale",
    percent: "",
    popular: false,
    link: "https://deltakey.ltd/",
    legal: "EU",
  },

  {
    id: 3,
    imgs: [
      require("../../images/brands/info/atm-ico.png"),
      require("../../images/brands/info/atm2.png"),
    ],
    small_descr: (
      <Translation>{(t) => <>{t("ATMC.small_descr")}</>}</Translation>
    ),
    descr: ATMC_descr,
    bg: require("../../images/abstract/4.jpg"),
    buy_link: "https://webpayment.bond/atmc.php",
    type: "Unbound Token",
    category: "Production Of Cryptomats",
    icon: require("../../images/brands/atmc1.png"),
    tiker: "ATMC",
    title: "ATM Credit",
    base_price: "€1.00",
    selling_price: "€1.10",
    emission: "€6,000,000.00",
    exponent: '8',
    allocation: "35%",
    sale: "Token sale",
    percent: "",
    popular: true,
    link: "https://atm.credit/",
    legal: "EU",
  },

  {
    id: 4,
    imgs: [
      require("../../images/brands/giftc/giftc1.png"),
      require("../../images/brands/giftc/giftc2.png"),
      require("../../images/brands/giftc/giftc3.png"),
      require("../../images/brands/giftc/giftc4.png"),
    ],
    small_descr: (
      <Translation>{(t) => <>{t("ELIOS.small_descr")}</>}</Translation>
    ),
    descr: ELIOS_descr,
    bg: require("../../images/abstract/5.png"),
    buy_link: "https://webpayment.bond/elios.php",
    type: "Stable Token",
    category: "Gift cards and codes",
    icon: require("../../images/brands/gift.png"),
    tiker: "Elios",
    title: "Elios Gold",
    base_price: "€1.00",
    selling_price: "€1.15",
    emission: "€4,500,000.00",
    exponent: '8',
    allocation: "∞",
    sale: "open sale",
    percent: "",
    popular: true,
    link: "https://elios.gold/",
    legal: "EU",
  },
  {
    id: 5,
    imgs: [
      require("../../images/brands/newreality/1.png"),
      require("../../images/brands/newreality/2.png")
    ],
    small_descr: (
      <Translation>{(t) => <>{t("NEWRL.small_descr")}</>}</Translation>
    ),
    descr: NEWRL_descr,
    bg: require("../../images/abstract/6.png"),
    buy_link: "https://webpayment.bond/nrl.php",
    type: "Native Token",
    category: "StableCoin",
    icon: require("../../images/brands/newR.png"),
    tiker: "NEWRL",
    title: "New Reality GC",
    base_price: "€1.00",
    selling_price: "€1.20",
    emission: "€5,000,000.00",
    exponent: '2',
    allocation: "∞",
    sale: "open sale",
    percent: "",
    popular: false,
    link: "https://newreality.exchange/",
    legal: "EU",
  },
  {
    id: 6,
    imgs: [
      require("../../images/brands/uniTransfer/1.png"),
      require("../../images/brands/uniTransfer/2.png")
    ],
    small_descr: (
      <Translation>{(t) => <>{t("UNITRANSFER.small_descr")}</>}</Translation>
    ),
    descr: UNIT_descr,
    bg: require("../../images/abstract/7.jpg"),
    buy_link: "https://webpayment.bond/unt.php",
    type: "Unbound Token",
    category: "Money Transfers",
    icon: require("../../images/brands/unitransfer.png"),
    tiker: "UNIT",
    title: "UniTransfer",
    base_price: "€1.00",
    selling_price: "€1.15",
    emission: "€4,900,000.00",
    exponent: '2',
    allocation: "∞",
    sale: "open sale",
    percent: "",
    popular: true,
    link: "https://unitransfer.in/",
    legal: "EU",
  },
  {
    id: 7,
    imgs: [
      require("../../images/brands/quant/quant1.png"),
      require("../../images/brands/quant/quant2.png"),
      require("../../images/brands/quant/quant3.png"),
      require("../../images/brands/quant/quant4.png"),
    ],
    small_descr: (
      <Translation>{(t) => <>{t("QUANT.small_descr")}</>}</Translation>
    ),
    descr: QNTS_descr,
    bg: require("../../images/abstract/8.jpg"),
    buy_link: "https://webpayment.bond/qnts.php",
    type: "Utility Token",
    category: "Access to in-game currencies",
    icon: require("../../images/brands/quant/qnt.png"),
    tiker: "QNTS",
    title: "Quant S",
    base_price: "€1.00",
    selling_price: "€1.25",
    emission: "€5,000,000.00",
    exponent: '8',
    allocation: "36%",
    sale: "open sale",
    percent: "",
    popular: false,
    //total_limit: "150 holders per country",
    //free_limit: "150 holders per country",
    link: "https://quant.host/",
  },
];
export default TokenData;

