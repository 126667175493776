import { useTranslation } from "react-i18next";

const ValidatorsPage = () => {
  const { t } = useTranslation();

  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="py-3 text-lg font-normal leading-8">
          {t(`Forvalidators.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <div>{paragraphs}</div>;
  }

  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <div className="mx-auto max-w-[1248px]">
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Forvalidators.paragraph1.title")}
          </h1>
          {Paragraphs(1, 3)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Forvalidators.paragraph2.title")}
          </h1>
          {Paragraphs(2, 4)}
          <p className="py-3 text-lg leading-8 font-bold">
            {t(`Forvalidators.paragraph2.descr.5`)}
          </p>
          <ul className="list-none list-inside ps-10 flex flex-col gap-5">
            <li className="text-green-700 dark:text-green-400 font-bold">
              {t(`Forvalidators.paragraph2.descr.6`)}
            </li>
            <ul className="list-disc list-inside ps-10">
              <li>{t(`Forvalidators.paragraph2.descr.7`)}</li>
              <li>{t(`Forvalidators.paragraph2.descr.8`)}</li>
            </ul>
            <li className="text-green-700 dark:text-green-400 font-bold">
              {t(`Forvalidators.paragraph2.descr.9`)}
            </li>
            <ul className="list-disc list-inside ps-10">
              <li>{t(`Forvalidators.paragraph2.descr.10`)}</li>
              <li>{t(`Forvalidators.paragraph2.descr.11`)}</li>
            </ul>
            <li className="text-green-700 dark:text-green-400 font-bold">
              {t(`Forvalidators.paragraph2.descr.12`)}
            </li>
            <ul className="list-disc list-inside ps-10">
              <li>{t(`Forvalidators.paragraph2.descr.13`)}</li>
              <li>{t(`Forvalidators.paragraph2.descr.14`)}</li>
            </ul>
            <li className="text-green-700 dark:text-green-400 font-bold">
              {t(`Forvalidators.paragraph2.descr.15`)}
            </li>
            <ul className="list-disc list-inside ps-10">
              <li>{t(`Forvalidators.paragraph2.descr.16`)}</li>
              <li>{t(`Forvalidators.paragraph2.descr.17`)}</li>
            </ul>
            <li className="text-green-700 dark:text-green-400 font-bold">
              {t(`Forvalidators.paragraph2.descr.18`)}
            </li>
            <ul className="list-disc list-inside ps-10">
              <li>
                {t(`Forvalidators.paragraph2.descr.20`)}
                <a
                  href="https://newreality.bond/cryptoATMs"
                  className="text-blue-400"
                >
                  CryptoATMs
                </a>
                {t(`Forvalidators.paragraph2.descr.35`)}
                <span className="text-blue-400">sales@newreality.ltd.</span>
              </li>
            </ul>
            <li className="text-green-700 dark:text-green-400 font-bold">
              {t(`Forvalidators.paragraph2.descr.21`)}
            </li>
            <ul className="list-disc list-inside ps-10">
              <li>
                <a
                  href="https://newreality.bond/cryptoATMs"
                  className="text-blue-400"
                >
                  {t(`Forvalidators.paragraph2.descr.36`)}
                </a>
                {t(`Forvalidators.paragraph2.descr.22`)}
              </li>
            </ul>
            <li className="text-green-700 dark:text-green-400 font-bold">
              {t(`Forvalidators.paragraph2.descr.23`)}
            </li>
            <ul className="list-disc list-inside ps-10">
              <li>{t(`Forvalidators.paragraph2.descr.24`)}</li>
              <li>{t(`Forvalidators.paragraph2.descr.25`)}</li>
            </ul>
            <li className="text-green-700 dark:text-green-400 font-bold">
              {t(`Forvalidators.paragraph2.descr.26`)}
            </li>
            <ul className="list-disc list-inside ps-10">
              <li>{t(`Forvalidators.paragraph2.descr.27`)}</li>
              <li>{t(`Forvalidators.paragraph2.descr.28`)}</li>
            </ul>
          </ul>

          <p className="pt-3 text-lg leading-8 ">
            {t(`Forvalidators.paragraph2.descr.29`)}
          </p>
          <p className="text-lg leading-8 ">
            {t(`Forvalidators.paragraph2.descr.30`)}
          </p>

          <ul className="pt-10 list-none list-inside ps-10 font-bold flex flex-col gap-5">
            <li>{t(`Forvalidators.paragraph2.descr.31`)}</li>
            <li>{t(`Forvalidators.paragraph2.descr.32`)}</li>
            <li>{t(`Forvalidators.paragraph2.descr.33`)}</li>
          </ul>
          <p className="py-3 text-lg leading-8 text-gray-600 dark:text-gray-400">
            {t(`Forvalidators.paragraph2.descr.34`)}
          </p>

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Forvalidators.paragraph3.title")}
          </h1>
          <p className="py-3 text-lg leading-8">
            {t(`Forvalidators.paragraph3.descr.1`)}
          </p>
          <p className="py-3 text-lg leading-8">
            {t(`Forvalidators.paragraph3.descr.2`)}
          </p>
          <p className="py-3 text-lg leading-8 font-bold">
            {t(`Forvalidators.paragraph3.descr.3`)}
          </p>
          <ul className="list-none list-inside ps-10 flex flex-col gap-5">
            <li>{t(`Forvalidators.paragraph3.descr.4`)}</li>
            <li> {t(`Forvalidators.paragraph3.descr.5`)}</li>
            <li className="font-bold">
              {t(`Forvalidators.paragraph3.descr.6`)}
            </li>
            <ul className="flex flex-col gap-5 list-disc list-inside ps-10 ">
              <li>{t(`Forvalidators.paragraph2.descr.7`)}</li>
              <li>{t(`Forvalidators.paragraph2.descr.8`)}</li>
            </ul>
            <li className="font-bold">
              {t(`Forvalidators.paragraph3.descr.9`)}
            </li>
            <ul className="flex flex-col gap-5 list-disc list-inside ps-10 ">
              <li>{t(`Forvalidators.paragraph2.descr.10`)}</li>
              <li>{t(`Forvalidators.paragraph2.descr.11`)}</li>
            </ul>
            <li className="font-bold">
              {t(`Forvalidators.paragraph3.descr.12`)}
            </li>
            <ul className="flex flex-col gap-5 list-disc list-inside ps-10 ">
              <li>{t(`Forvalidators.paragraph2.descr.13`)}</li>
              <li>{t(`Forvalidators.paragraph2.descr.14`)}</li>
              <li>{t(`Forvalidators.paragraph2.descr.15`)}</li>
            </ul>
          </ul>

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Forvalidators.paragraph4.title")}
          </h1>
          {Paragraphs(4, 6)}
          <p className="py-3 text-lg leading-8">
            {t(`Forvalidators.paragraph4.descr.7`)}
            <a href="https://newreality.cc" className="text-blue-400">
              {t(`Forvalidators.paragraph4.descr.8`)}
            </a>
            {t(`Forvalidators.paragraph4.descr.9`)}
            <span className="text-blue-400">contact@newreality.ltd.</span>
          </p>
          <p className="py-3 text-lg leading-8">
            {t(`Forvalidators.paragraph4.descr.10`)}
          </p>

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Forvalidators.paragraph5.title")}
          </h1>
          {Paragraphs(5, 3)}

          <p className="py-3 text-lg leading-8 font-bold">
            {t(`Forvalidators.paragraph5.descr.4`)}
          </p>

          <ul className="list-none list-inside ps-10 flex flex-col gap-5">
            <li className="text-green-700 dark:text-green-400 font-bold">
              {t(`Forvalidators.paragraph5.descr.5`)}
            </li>
            <ul className="list-disc list-inside ps-10">
              <li>{t(`Forvalidators.paragraph5.descr.6`)}</li>
              <li>{t(`Forvalidators.paragraph5.descr.7`)}</li>
            </ul>
            <li className="text-green-700 dark:text-green-400 font-bold">
              {t(`Forvalidators.paragraph5.descr.8`)}
            </li>
            <ul className="list-disc list-inside ps-10">
              <li>{t(`Forvalidators.paragraph5.descr.9`)}</li>
              <li>{t(`Forvalidators.paragraph5.descr.10`)}</li>
              <li>{t(`Forvalidators.paragraph5.descr.11`)}</li>
            </ul>
            <li className="text-green-700 dark:text-green-400 font-bold">
              {t(`Forvalidators.paragraph5.descr.12`)}
            </li>
            <ul className="list-disc list-inside ps-10">
              <li>{t(`Forvalidators.paragraph5.descr.13`)}</li>
              <li>{t(`Forvalidators.paragraph5.descr.14`)}</li>
            </ul>
            <li className="text-green-700 dark:text-green-400 font-bold">
              {t(`Forvalidators.paragraph5.descr.15`)}
            </li>
            <ul className="list-disc list-inside ps-10">
              <li>{t(`Forvalidators.paragraph5.descr.16`)}</li>
              <li>{t(`Forvalidators.paragraph5.descr.17`)}</li>
            </ul>
          </ul>

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Forvalidators.paragraph6.title")}
          </h1>
          <p className="py-3 text-lg leading-8">
            {t(`Forvalidators.paragraph6.descr.1`)}
            <a
              href="https://newreality.bond/cryptoATMs"
              className="text-blue-400"
            >
              New Reality
            </a>
            {t(`Forvalidators.paragraph6.descr.2`)}
          </p>
          <p className="py-3 text-lg leading-8">
            {t(`Forvalidators.paragraph6.descr.3`)}
            <a
              href="https://newreality.bond/cryptoATMs"
              className="text-blue-400"
            >
              {t(`Forvalidators.paragraph6.descr.4`)}
            </a>
            {t(`Forvalidators.paragraph6.descr.5`)}
            <span className="text-blue-400">contact@newreality.ltd.</span>
          </p>
          <p className="py-3 text-lg leading-8">
            {t(`Forvalidators.paragraph6.descr.6`)}
          </p>
        </div>
      </div>
    </section>
  );
};

export default ValidatorsPage;
