import { useTranslation } from "react-i18next";

const TermsOfUsePage = () => {
  const { t } = useTranslation();

  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="py-3 text-lg font-normal leading-8">
          {t(`TermsOfUsePage.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <div>{paragraphs}</div>;
  }
  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph1.title")}
        </h2>
        {Paragraphs(1, 3)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph2.title")}
        </h2>
        {Paragraphs(2, 3)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph3.title")}
        </h2>
        <h3 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t(`TermsOfUsePage.paragraph3.descr.1`)}
        </h3>
        {Paragraphs(3, 16, 2)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph4.title")}
        </h2>
        {Paragraphs(4, 14)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph5.title")}
        </h2>
        {Paragraphs(5, 4)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph6.title")}
        </h2>
        {Paragraphs(6, 2)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph7.title")}
        </h2>
        {Paragraphs(7, 10)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph8.title")}
        </h2>
        {Paragraphs(8, 6)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph9.title")}
        </h2>
        {Paragraphs(9, 23)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph10.title")}
        </h2>
        {Paragraphs(10, 8)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph11.title")}
        </h2>
        {Paragraphs(11, 7)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph12.title")}
        </h2>
        {Paragraphs(12, 12)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph13.title")}
        </h2>
        {Paragraphs(13, 15)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph14.title")}
        </h2>
        {Paragraphs(14, 11)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph15.title")}
        </h2>
        {Paragraphs(15, 13)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph16.title")}
        </h2>
        {Paragraphs(16, 5)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph17.title")}
        </h2>
        {Paragraphs(17, 16)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph18.title")}
        </h2>
        {Paragraphs(18, 24)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph19.title")}
        </h2>
        {Paragraphs(19, 7)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph20.title")}
        </h2>
        {Paragraphs(20, 14)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph21.title")}
        </h2>
        {Paragraphs(21, 20)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph22.title")}
        </h2>
        {Paragraphs(22, 54)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph23.title")}
        </h2>
        {Paragraphs(23, 1)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph24.title")}
        </h2>
        {Paragraphs(24, 1)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph25.title")}
        </h2>
        {Paragraphs(25, 6)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph26.title")}
        </h2>
        {Paragraphs(26, 5)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph27.title")}
        </h2>
        {Paragraphs(27, 10)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph28.title")}
        </h2>
        {Paragraphs(28, 35)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph29.title")}
        </h2>
        {Paragraphs(29, 17)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph30.title")}
        </h2>
        {Paragraphs(30, 14)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph31.title")}
        </h2>
        {Paragraphs(31, 12, 2)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph32.title")}
        </h2>
        {Paragraphs(32, 44)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph33.title")}
        </h2>
        {Paragraphs(33, 8)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph34.title")}
        </h2>
        {Paragraphs(34, 12)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph35.title")}
        </h2>
        {Paragraphs(35, 7)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph36.title")}
        </h2>
        {Paragraphs(36, 1)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph37.title")}
        </h2>
        {Paragraphs(37, 3)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph38.title")}
        </h2>
        {Paragraphs(38, 54)}

        <h2 className="text-3xl font-bold leading-8 text-center mt-10 my-10">
          {t("TermsOfUsePage.paragraph46.title")}
        </h2>
        {Paragraphs(46, 85)}

        <ul className="flex flex-col list-disc pl-10 gap-5 text-gray-600 dark:text-gray-300">
          <li>
            <a href="https://newreality.exchange/" className="text-blue-500">
              https://newreality.exchange/
            </a>
          </li>
          <li>
            <a href="https://newreality.bond" className="text-blue-500">
              https://newreality.bond
            </a>
          </li>
          <li>
            <a href="https://newreality.cc" className="text-blue-500">
              https://newreality.cc
            </a>
          </li>
          <li>
            <a href="https://newreality.in" className="text-blue-500">
              https://newreality.in
            </a>
          </li>
          <li>
            <a href="https://newreality.fund" className="text-blue-500">
              https://newreality.fund
            </a>
          </li>
          <li>
            <a href="https://newreality.ltd" className="text-blue-500">
              https://newreality.ltd
            </a>
          </li>
          <li>
            <a href="https://newreality.uk" className="text-blue-500">
              https://newreality.uk
            </a>
          </li>
          <li>
            <a href="https://newreality.club" className="text-blue-500">
            https://newreality.club
            </a>
          </li>
          <li>
            <a href="https://assethub.cz" className="text-blue-500">
              https://assethub.cz
            </a>
          </li>
          <li>
            <a href="https://assethub.top" className="text-blue-500">
              https://assethub.top
            </a>
          </li>
          <li>
            <a href="https://asset48.pl" className="text-blue-500">
              https://asset48.pl
            </a>
          </li>
        </ul>

        <p className="py-3 text-lg font-normal leading-8">
          {t(`TermsOfUsePage.paragraph46.descr.86`)}
        </p>
        
        <ul className="flex flex-col list-disc pl-10 gap-5 text-gray-600 dark:text-gray-300">
          <li>
            <a href="https://atm.credit" className="text-blue-500">
              https://atm.credit
            </a>
          </li>
          <li>
            <a href="https://bond.money" className="text-blue-500">
              https://bond.money
            </a>
          </li>
          <li>
            <a href="https://deltakey.ltd" className="text-blue-500">
              https://deltakey.ltd
            </a>
          </li>
          <li>
            <a href="https://elios.gold" className="text-blue-500">
              https://elios.gold
            </a>
          </li>
          <li>
            <a href="https://giftcode.bond" className="text-blue-500">
              https://giftcode.bond
            </a>
          </li>
          <li>
            <a href="https://webpayment.bond" className="text-blue-500">
              https://webpayment.bond
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default TermsOfUsePage;
