import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEn from "./public/locales/en/translation.json";
import translationRu from "./public/locales/ru/translation.json";
import translationCs from "./public/locales/cs/translation.json";
import translationPl from "./public/locales/pl/translation.json";
import translationDe from "./public/locales/de/translation.json";
import translationFr from "./public/locales/fr/translation.json";
import translationKk from "./public/locales/kk/translation.json";
import translationZh from "./public/locales/zh/translation.json";
import translationAr from "./public/locales/ar/translation.json";
import translationUk from "./public/locales/uk/translation.json";
import translationEs from "./public/locales/es/translation.json";
import translationPt from "./public/locales/pt/translation.json";
import translationHi from "./public/locales/hi/translation.json";
import translationIt from "./public/locales/it/translation.json";

const resources = {
  en: {
    translation: translationEn,
  },
  ru: {
    translation: translationRu,
  },
  cs: {
    translation: translationCs,
  },
  pl: {
    translation: translationPl,
  },
  de: {
    translation: translationDe,
  },
  fr: {
    translation: translationFr,
  },
  kk: {
    translation: translationKk,
  },
  zh: {
    translation: translationZh,
  },
  ar: {
    translation: translationAr,
  },
  uk: {
    translation: translationUk,
  },
  es: {
    translation: translationEs,
  },
  pt: {
    translation: translationPt,
  },
  hi: {
    translation: translationHi,
  },
  it: {
    translation: translationIt,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  debug: false,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;