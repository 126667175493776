import { useTranslation } from "react-i18next";

const EmtPage = () => {
  const { t } = useTranslation();

  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="py-3 text-lg font-normal leading-8">
          {t(`Emt.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <div>{paragraphs}</div>;
  }

  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <div className="mx-auto max-w-[1248px]">
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Emt.paragraph1.title")}
          </h1>
          {Paragraphs(1, 8)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Emt.paragraph2.title")}
          </h1>
          {Paragraphs(2, 5)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Emt.paragraph3.title")}
          </h1>
          {Paragraphs(3, 4)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Emt.paragraph4.title")}
          </h1>
          {Paragraphs(4, 35)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Emt.paragraph5.title")}
          </h1>
          {Paragraphs(5, 6)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Emt.paragraph6.title")}
          </h1>
          {Paragraphs(6, 10)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Emt.paragraph7.title")}
          </h1>
          {Paragraphs(7, 3)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Emt.paragraph8.title")}
          </h1>
          {Paragraphs(8, 2)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Emt.paragraph9.title")}
          </h1>
          {Paragraphs(9, 4)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Emt.paragraph10.title")}
          </h1>
          {Paragraphs(10, 2)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Emt.paragraph11.title")}
          </h1>
          {Paragraphs(11, 4)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Emt.paragraph12.title")}
          </h1>
          {Paragraphs(12, 45)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Emt.paragraph13.title")}
          </h1>
          {Paragraphs(13, 47)}

        </div>
      </div>
    </section>
  );
};

export default EmtPage;
