import { useTranslation } from "react-i18next";
const LaunchTokenPage = () => {
  const { t } = useTranslation();

  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
   
      paragraphs.push(
        <p key={i} className="py-3 text-lg font-normal leading-8">
          {t(`LaunchToken.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <div>{paragraphs}</div>;
  }

  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <div className="mx-auto max-w-[1248px]">
          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("LaunchToken.paragraph1.title")}</h1>
          {Paragraphs(1, 1)}
          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("LaunchToken.paragraph2.title")}</h1>
          <p className="py-3 text-lg font-bold leading-8 text-green-700 dark:text-green-400">{t("LaunchToken.paragraph2.descr.1")}</p>
          <p className="py-3 text-lg font-normal leading-8">{t("LaunchToken.paragraph2.descr.2")}</p>
          <p className="py-3 text-lg font-bold leading-8 text-green-700 dark:text-green-400">{t("LaunchToken.paragraph3.title")}</p>
          {Paragraphs(3, 1)}
          <p className="py-3 text-lg font-bold leading-8 text-green-700 dark:text-green-400">{t("LaunchToken.paragraph4.title")}</p>
          {Paragraphs(4, 1)}
          <p className="py-3 text-lg font-bold leading-8 text-green-700 dark:text-green-400">{t("LaunchToken.paragraph5.title")}</p>
          {Paragraphs(5, 1)}
          <p className="py-3 text-lg font-bold leading-8 text-green-700 dark:text-green-400">{t("LaunchToken.paragraph6.title")}</p>
          {Paragraphs(6, 1)}
          <p className="py-3 text-lg font-bold leading-8 text-green-700 dark:text-green-400">{t("LaunchToken.paragraph7.title")}</p>
          {Paragraphs(7, 1)}
          <p className="py-3 text-lg font-bold leading-8 text-green-700 dark:text-green-400">{t("LaunchToken.paragraph8.title")}</p>
          {Paragraphs(8, 1)}
          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("LaunchToken.paragraph9.title")}</h1>
          {Paragraphs(9, 7)}
          <div className="mt-5">
            <a href="/contact" className="py-3  text-lg font-normal leading-8 text-blue-600">
              {t("LaunchToken.paragraph9.descr.8")}
            </a>
            <span className="py-3 text-lg font-bold leading-8">{t("LaunchToken.paragraph9.descr.9")}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LaunchTokenPage;
