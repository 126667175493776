import { useTranslation } from "react-i18next";

const UnboundPage = () => {
  const { t } = useTranslation();

  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="py-3 text-lg font-normal leading-8">
          {t(`UnboundTokens.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <div>{paragraphs}</div>;
  }

  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <div className="mx-auto max-w-[1248px]">
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("UnboundTokens.paragraph1.title")}
          </h1>
          {Paragraphs(1, 1)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("UnboundTokens.paragraph2.title")}
          </h1>
          {Paragraphs(2, 6)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("UnboundTokens.paragraph3.title")}
          </h1>
          {Paragraphs(3, 7)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("UnboundTokens.paragraph4.title")}
          </h1>
          {Paragraphs(4, 12)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("UnboundTokens.paragraph5.title")}
          </h1>
          {Paragraphs(5, 7)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("UnboundTokens.paragraph6.title")}
          </h1>
          {Paragraphs(6, 4)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("UnboundTokens.paragraph7.title")}
          </h1>
          {Paragraphs(7, 37)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("UnboundTokens.paragraph8.title")}
          </h1>
          {Paragraphs(8, 9)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("UnboundTokens.paragraph9.title")}
          </h1>
          {Paragraphs(9, 17)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("UnboundTokens.paragraph10.title")}
          </h1>
          {Paragraphs(10, 2)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("UnboundTokens.paragraph11.title")}
          </h1>
          {Paragraphs(11, 6)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("UnboundTokens.paragraph12.title")}
          </h1>
          {Paragraphs(12, 2)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("UnboundTokens.paragraph13.title")}
          </h1>

          {Paragraphs(13, 9)}
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("UnboundTokens.paragraph14.title")}
          </h1>
          {Paragraphs(14, 7)}
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("UnboundTokens.paragraph15.title")}
          </h1>
          {Paragraphs(15, 7)}
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("UnboundTokens.paragraph16.title")}
          </h1>
          {Paragraphs(16, 7)}
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("UnboundTokens.paragraph17.title")}
          </h1>
          {Paragraphs(17, 24)}
        </div>
      </div>
    </section>
  );
};

export default UnboundPage;
