import { Carousel } from "flowbite-react";

function CarouselC({imgs}) {
  return (
    <div className="h-52 sm:h-72 md:h-[30rem] xl:h-96 2xl:h-full ">
      <Carousel>
        {imgs.map((img, index) => (
          <img className="object-cover h-full" key={index} src={img} alt={"img"} />
        ))}
      </Carousel>
    </div>
  );
}

export default CarouselC;