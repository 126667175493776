import { Helmet } from "react-helmet";
import ContactInfoBox from "../components/Contact/ContactInfoBox";

const ContactPage = () => {
  
  return (
    <section className="py-16 md:py-20 lg:py-24 h-fit md:h-screen flex flex-col justify-center contact_img">
      <Helmet>
        <script src="https://newreality.cc/js/min/jquery.min.js"></script>
        <script id="sbinit" src="https://newreality.cc/js/main.js"></script>
      </Helmet>
      <div className="container">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full  h-full px-4 ">
            <ContactInfoBox />
          </div>
        </div>
      </div>
      <div id="sb-tickets">
        <Helmet>
          <script
              id="sbinit"
              src="https://newreality.cc/js/main.js?mode=tickets"
            ></script>
        </Helmet>
      </div>
    </section>
  );
};

export default ContactPage;
