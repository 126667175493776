import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./i18n";
import MainPage from "./pages/MainPage";
import { useTheme } from "./providers/ThemeProvider";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import AboutPage from "./pages/AboutPage";
import StandardtsPage from "./pages/StandardtsPage";
import ContactPage from "./pages/ContactPage";
import FaqPage from "./pages/FaqPage";
import TokenPage from "./pages/TokenPage";
import { useEffect, useState } from "react";
import CookieNotice from "./pages/CookieNotice";
import ListCountriesPage from "./pages/ListCountriesPage";
import RiskWarningPage from "./pages/RiskWarningPage";
import AllProductsPage from "./pages/AllProductsPage";
import PrivacyNoticePage from "./pages/PrivacyNoticePage";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import CreateToken from "./pages/CreateToken";
import MobileMenu from "./components/MobileMenu/MobileMenu";
import ARTPage from "./pages/ARTPage";
import EmtPage from "./pages/EmtPage";
import UnboundPage from "./pages/UnboundPage";
import RegulationPage from "./pages/RegulationPage";
import СlassifyingPage from "./pages/СlassifyingPage";
import TermsPage from "./pages/TermsPage";
import i18n from "./i18n";
import TariffsPage from "./pages/TariffsPage";
import TariffsTermPage from "./pages/TariffsTermPage";
import BlockchainPage from "./pages/BlockchainPage";
import ValidatorsPage from "./pages/ValidatorsPage";
import CryptoATMsPage from "./pages/CryptoATMsPage";
import PresalePage from "./pages/PresalePage";
import MerchantPage from "./pages/MerchantPage";
import MerchantConfigPage from "./pages/MerchantConfigPage";
import LaunchTokenPage from "./pages/LaunchTokenPage";
import ExchangeDescPage from "./pages/ExchangeDescPage";

function App() {
  const { theme } = useTheme();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  return (
    <div className={`${theme} w-full`}>
      <div className="dark:bg-black w-full">
        <BrowserRouter>
          <Header toggleMobileMenu={toggleMobileMenu} isMobileMenuOpen={isMobileMenuOpen} />
          <MobileMenu isOpen={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(false)} />
          <Routes>
            <Route path="*" element={<MainPage />} />
            <Route path="popularTokens" element={<AllProductsPage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="legal" element={<StandardtsPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="faq" element={<FaqPage />} />
            <Route path="cookieNotice" element={<CookieNotice />} />
            <Route path="termsOfUse" element={<TermsOfUsePage />} />
            <Route path="privacyNotice" element={<PrivacyNoticePage />} />
            <Route path="listOfProhibitedCountries" element={<ListCountriesPage />} />
            <Route path="RiskWarning" element={<RiskWarningPage />} />
            <Route path="ARTPage" element={<ARTPage />} />
            <Route path="EmtPage" element={<EmtPage />} />
            <Route path="UnboundPage" element={<UnboundPage />} />
            <Route path="TariffsPage" element={<TariffsPage />} />
            <Route path="BlockchainPage" element={<BlockchainPage />} />
            <Route path="Forvalidators" element={<ValidatorsPage />} />
            <Route path="TariffsTermPage" element={<TariffsTermPage />} />
            <Route path="СlassifyingPage" element={<СlassifyingPage />} />
            <Route path="RegulationPage" element={<RegulationPage />} />
            <Route path="Createtoken" element={<CreateToken />} />
            <Route path="TermsPage" element={<TermsPage />} />
            <Route path="CryptoATMs" element={<CryptoATMsPage />} />
            <Route path="token/:tiker" element={<TokenPage />} />
            <Route path="Presale" element={<PresalePage />} />
            <Route path="Merchant" element={<MerchantPage />} />
            <Route path="MerchantConfig" element={<MerchantConfigPage />} />
            <Route path="LaunchToken" element={<LaunchTokenPage />} />
            <Route path="ExchangeDesc" element={<ExchangeDescPage />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
