import { useTranslation } from "react-i18next";

const CryptoATMsPage = () => {
  const { t } = useTranslation();

  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="py-3 text-lg font-normal leading-8">
          {t(`CryptoATMs.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <div>{paragraphs}</div>;
  }

  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <div className="mx-auto max-w-[1248px]">
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("CryptoATMs.paragraph1.title")}
          </h1>
          <p className="py-3 text-lg text-center font-bold">
            {t(`CryptoATMs.paragraph1.descr.1`)}
          </p>

          <div className="flex flex-col gap-2 md:flex-row md:gap-10 mt-16">
            <img
              className="h-[400px] w-[400px] rounded-lg"
              src={require("../images/atms/atm1.png")}
            />
            <div className="flex flex-col gap-5 grow">
              <h2 className="font-bold text-xl mb-5">
                {t("CryptoATMs.paragraph2.title")}
              </h2>
              <ul className="list-disc list-inside">
                <li>{t("CryptoATMs.paragraph2.descr.1")}</li>
                <li>{t("CryptoATMs.paragraph2.descr.2")}</li>
                <li>{t("CryptoATMs.paragraph2.descr.3")}</li>
                <li>{t("CryptoATMs.paragraph2.descr.4")}</li>
                <li>{t("CryptoATMs.paragraph2.descr.5")}</li>
                <li>{t("CryptoATMs.paragraph2.descr.6")}</li>
                <li>{t("CryptoATMs.paragraph2.descr.7")}</li>
                <li>{t("CryptoATMs.paragraph2.descr.8")}</li>
              </ul>
              <p className="font-bold">{t("CryptoATMs.paragraph2.descr.9")}</p>
            </div>
          </div>

          <div className="flex flex-col gap-2 md:flex-row md:gap-10 mt-16">
            <img
              className="h-[400px] w-[400px] rounded-lg"
              src={require("../images/atms/atm2.png")}
            />
            <div className="flex flex-col gap-5 grow">
              <h2 className="font-bold text-xl mb-5">
                {t("CryptoATMs.paragraph3.title")}
              </h2>
              <ul className="list-disc list-inside">
                <li>{t("CryptoATMs.paragraph3.descr.1")}</li>
                <li>{t("CryptoATMs.paragraph3.descr.2")}</li>
                <li>{t("CryptoATMs.paragraph3.descr.3")}</li>
                <li>{t("CryptoATMs.paragraph3.descr.4")}</li>
                <li>{t("CryptoATMs.paragraph3.descr.5")}</li>
                <li>{t("CryptoATMs.paragraph3.descr.6")}</li>
              </ul>
              <p className="font-bold">{t("CryptoATMs.paragraph3.descr.7")}</p>
            </div>
          </div>

          <div className="flex flex-col gap-2 md:flex-row md:gap-10 mt-16">
            <img
              className="h-[400px] w-[400px] rounded-lg"
              src={require("../images/atms/atm3.png")}
            />
            <div className="flex flex-col gap-5 grow">
              <h2 className="font-bold text-xl mb-5">
                {t("CryptoATMs.paragraph4.title")}
              </h2>
              <ul className="list-disc list-inside">
                <li>{t("CryptoATMs.paragraph4.descr.1")}</li>
                <li>{t("CryptoATMs.paragraph4.descr.2")}</li>
                <li>{t("CryptoATMs.paragraph4.descr.3")}</li>
                <li>{t("CryptoATMs.paragraph4.descr.4")}</li>
              </ul>
              <p className="font-bold">{t("CryptoATMs.paragraph4.descr.5")}</p>
            </div>
          </div>

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("CryptoATMs.paragraph5.title")}
          </h1>
          <p className="py-3 text-lg">{t(`CryptoATMs.paragraph5.descr.1`)}</p>
          <p className="py-3 text-lg">
            {t(`CryptoATMs.paragraph5.descr.2`)}
            <a href="https://newrealiy.cc" className="text-blue-400">
              {t(`CryptoATMs.paragraph5.descr.4`)}
            </a>
            {t(`CryptoATMs.paragraph5.descr.5`)}
            <span className="text-blue-400">contact@newreality.ltd</span>
          </p>
          <p className="py-3 text-lg">
            {t(`CryptoATMs.paragraph5.descr.3`)}
          </p>
        </div>
      </div>
    </section>
  );
};

export default CryptoATMsPage;
