import { motion, useScroll, useTransform } from "framer-motion";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

export default function Parallax() {
  const ref = useRef(null);
  const { t } = useTranslation();
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });

  const backgroundYC = useTransform(scrollYProgress, [0, 1], ["0%", "50%"]);
  const backgroundYC2 = useTransform(scrollYProgress, [0, 1], ["0%", "30%"]);
  const backgroundYC3 = useTransform(scrollYProgress, [0, 1], ["0%", "40%"]);
  const textY = useTransform(scrollYProgress, [0, 1], ["0%", "200%"]);

  return (
    <div ref={ref} className="w-full h-screen min-h-fit xl:min-h-[800px] overflow-hidden relative flex flex-col justify-center xl:justify-between items-center">
      <motion.div className="bg-black/50 rounded-3xl backdrop-blur-xl z-20 p-10 mt-2 xl:mt-[150px] mx-10 block xl:hidden">
        <motion.div className="z-30">
          <p className="mb-12 mt-5 text-center text-base !leading-relaxed text-white sm:text-lg md:text-xl lg:text-2xl duration-300 ease-in-out">
            {t("mainPage.hero.description.1")}
          </p>
          <p className="mb-12 mt-5 text-base font-bold !leading-relaxed text-white sm:text-lg md:text-xl lg:text-2xl duration-300 ease-in-out">
            {t("mainPage.hero.description.2")}
          </p>
          <div className="flex flex-col items-center justify-center space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
            <a className="p-[2px] relative " href="/LaunchToken">
              <div className="py-4 px-8 text-center inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg relative group transition duration-300 ease-in-out text-white zoom">
                {t("mainPage.hero.btn.token")}
              </div>
            </a>

            <a
              href="/TariffsPage"
              className="py-4 px-8 rounded-xl border-2 border-gray-200 dark:border-gray-200  text-neutral-700 bg-transparent  text-white hover:bg-gray-100 hover:bg-opacity-5 transition duration-300 ease-in-out zoom"
            >
              {t("mainPage.hero.btn.more")}
            </a>
          </div>
        </motion.div>
      </motion.div>

      <motion.div className="bg-black/50 rounded-3xl  backdrop-blur-xl z-20 p-10 pt-5 mt-[150px] hidden xl:block" style={{ y: textY }}>
        <motion.div className="z-30">
          <p className="mb-5 mt-5 text-base w-[800px] text-center text-white sm:text-lg md:text-4xl duration-300 ease-in-out">
            {t("mainPage.hero.description.1")}
          </p>
          <p className="mb-8 mt-5 text-base font-bold w-[800px] text-center text-white sm:text-lg md:text-xl duration-300 ease-in-out">
            {t("mainPage.hero.description.2")}
          </p>

          <div className="flex flex-col items-center justify-center space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
            <a className="p-[2px] relative " href="/LaunchToken">
              <div className="py-4 px-8  inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg relative group transition duration-300 ease-in-out text-white zoom">
                {t("mainPage.hero.btn.token")}
              </div>
            </a>

            <a
              href="/TariffsPage"
              className="py-4 px-8 rounded-xl border-2 border-gray-200 dark:border-gray-200  text-neutral-700 bg-transparent  text-white hover:bg-gray-100 hover:bg-opacity-5 transition duration-300 ease-in-out zoom"
            >
              {t("mainPage.hero.btn.more")}
            </a>
          </div>
        </motion.div>
      </motion.div>
      <motion.div
        className="absolute inset-0 z-0 parallax_full_m pointer-events-none block"
        style={{
          backgroundPosition: "bottom",
          backgroundSize: "cover",
        }}
      />
      <motion.div
        className="absolute inset-0 z-20 parallax_cf pointer-events-none hidden xl:block"
        style={{
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          y: backgroundYC3,
        }}
      />
      <motion.div
        className="absolute inset-0 z-10 parallax_c2 pointer-events-none hidden xl:block"
        style={{
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          y: backgroundYC,
        }}
      />
      <motion.div
        className="absolute inset-0 z-30 parallax_c pointer-events-none hidden xl:block"
        style={{
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          y: backgroundYC2,
        }}
      />
      <div
        className="absolute inset-0 z-40 parallax_b pointer-events-none block"
        style={{
          backgroundPosition: "bottom",
          backgroundSize: "cover",
        }}
      />
    </div>
  );
}
