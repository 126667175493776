import { useTranslation } from "react-i18next";
import image_1 from "../images/aboutPage/image-1.jpg";
import image_2 from "../images/aboutPage/image-2.jpg";
import image_3 from "../images/aboutPage/image-3.jpg";

const AboutPage = () => {
  const { t } = useTranslation();
  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="py-3 text-lg font-normal leading-8">
          {t(`AboutPage.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <div>{paragraphs}</div>;
  }
  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <div className="w-full mx-auto ">
          <h2 className="text-3xl font-bold leading-8 text-center my-10">
            {t("AboutPage.paragraph1.title")}
          </h2>
          {Paragraphs(1, 1)}

          <h2 className="text-3xl font-bold leading-8 text-center my-10">
            {t("AboutPage.paragraph2.title")}
          </h2>
          {Paragraphs(2, 3)}

          <h2 className="text-3xl font-bold leading-8 text-center my-10">
            {t("AboutPage.paragraph3.title")}
          </h2>
          {Paragraphs(3, 1)}
        </div>
        <div className="mt-[20px] lg:mt-[80px] bg-white dark:bg-gray-800 lg:h-auto w-full rounded-3xl flex border flex-col lg:flex-row">
          <div className="min-w-[300px] lg:min-w-[400px] 2xl:min-w-[500px]">
            <img
              className="h-full object-cover rounded-t-3xl lg:rounded-l-3xl lg:rounded-tr-none "
              src={image_2}
              alt="about image"
            />
          </div>
          <div className="p-3 lg:px-10 lg:p-5 bg-white dark:bg-gray-900 rounded-b-3xl lg:rounded-r-3xl lg:rounded-l-none flex justify-between flex-col text-gray-900 dark:text-white">
            <div>
              <h5 className="text-xl mb-5 text-center lg:text-4xl lg:font-bold tracking-tight">
                {t("AboutPage.companies.1.title")}
              </h5>
              <div className="mb-5 text-base">
                <p>{t("AboutPage.companies.1.descr.1")}</p>
                <p>{t("AboutPage.companies.1.descr.2")}</p>
                <p>{t("AboutPage.companies.1.descr.3")}</p>
                <p>{t("AboutPage.companies.1.descr.4")}</p>
              </div>
              <div className="mb-5">
                <span className="text-base">
                  {t("AboutPage.companies.1.descr.5")}
                </span>
                <a
                  href="https://aifc.kz/"
                  className="text-blue-600 dark:text-blue-500 hover:underline"
                >
                  {t("AboutPage.companies.1.descr.link")}
                </a>
                <span>{t("AboutPage.companies.1.descr.6")}</span>
              </div>
            </div>

            <div>
              <a
                href="https://newreality.cc/index.html"
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-opacity-90"
              >
                {t("AboutPage.companies.1.descr.7")}
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div className="mt-[20px] lg:mt-[80px] bg-white dark:bg-gray-800 lg:h-auto w-full rounded-3xl flex border flex-col lg:flex-row">
          <div className="min-w-[300px] lg:min-w-[400px] 2xl:min-w-[500px]">
            <img
              className="h-full object-cover rounded-t-3xl lg:rounded-l-3xl lg:rounded-tr-none"
              src={image_1}
              alt="about image"
            />
          </div>
          <div className="p-3 lg:px-10 lg:p-5 bg-white dark:bg-gray-900 rounded-b-3xl lg:rounded-r-3xl lg:rounded-l-none flex justify-between flex-col text-gray-900 dark:text-white">
            <div>
              <h5 className="text-xl mb-5 text-center lg:text-4xl lg:font-bold tracking-tight">
                {t("AboutPage.companies.2.title")}
              </h5>
              <div className="mb-5 text-black dark:text-white text-base">
                <p>{t("AboutPage.companies.2.descr.1")}</p>
                <p>{t("AboutPage.companies.2.descr.2")}</p>
                <p>{t("AboutPage.companies.2.descr.3")}</p>
                <p>{t("AboutPage.companies.2.descr.4")}</p>
              </div>
              <div className="mb-5">
                <ol className="space-y-4 text-black  list-inside dark:text-white">
                  <p className="mb-5 font-normal">
                    {t("AboutPage.companies.2.descr.5")}
                  </p>
                  <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
                    <li> {t("AboutPage.companies.2.descr.6")}</li>
                    <li> {t("AboutPage.companies.2.descr.7")}</li>
                    <li> {t("AboutPage.companies.2.descr.8")}</li>
                    <li> {t("AboutPage.companies.2.descr.9")}</li>
                    <li> {t("AboutPage.companies.2.descr.10")}</li>
                    <li>{t("AboutPage.companies.2.descr.11")}</li>
                    <li> {t("AboutPage.companies.2.descr.12")}</li>
                  </ul>
                  <p>{t("AboutPage.companies.2.descr.13")}</p>
                </ol>
              </div>
            </div>

            <div>
              <a
                href="https://newreality.cc/index.html"
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-opacity-90"
              >
                {t("AboutPage.companies.2.descr.14")}
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="mt-[20px] lg:mt-[80px] bg-white dark:bg-gray-800 lg:h-auto w-full rounded-3xl flex border flex-col lg:flex-row">
          <div className="min-w-[300px] lg:min-w-[400px] 2xl:min-w-[500px]">
            <img
              className="h-full object-cover rounded-t-3xl lg:rounded-l-3xl lg:rounded-tr-none"
              src={image_3}
              alt="about image"
            />
          </div>
          <div className="p-3 lg:px-10 lg:p-5 bg-white dark:bg-gray-900 rounded-b-3xl lg:rounded-r-3xl lg:rounded-l-none flex justify-between flex-col text-gray-900 dark:text-white">
            <div>
              <h5 className="mb-2 text-center text-4xl font-bold tracking-tight text-gray-900 dark:text-white">
                {t("AboutPage.companies.3.title")}
              </h5>
              <div className="text-black dark:text-white mb-5">
                <p> {t("AboutPage.companies.3.descr.1")}</p>
                <p>{t("AboutPage.companies.3.descr.2")}</p>
                <p>{t("AboutPage.companies.3.descr.3")}</p>
                <p>{t("AboutPage.companies.3.descr.4")}</p>
              </div>
              <div className="mb-3">
                <ol className="space-y-4 text-black  list-inside dark:text-white">
                  <p className="mb-3 font-normal">
                    {t("AboutPage.companies.3.descr.5")}
                  </p>
                  <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
                    <li>{t("AboutPage.companies.3.descr.6")}</li>
                    <li>{t("AboutPage.companies.3.descr.7")}</li>
                    <li>{t("AboutPage.companies.3.descr.8")}</li>
                    <li>{t("AboutPage.companies.3.descr.9")}</li>
                  </ul>
                </ol>
              </div>
            </div>
            <div>
              <a
                href="https://newreality.cc/index.html"
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-opacity-90"
              >
                {t("AboutPage.companies.3.descr.10")}
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutPage;
