import { useTranslation } from "react-i18next";

const checkIcon = (
  <svg width="16" height="13" viewBox="0 0 16 13" className="fill-primary">
    <path d="M5.8535 12.6631C5.65824 12.8584 5.34166 12.8584 5.1464 12.6631L0.678505 8.1952C0.483242 7.99994 0.483242 7.68336 0.678505 7.4881L2.32921 5.83739C2.52467 5.64193 2.84166 5.64216 3.03684 5.83791L5.14622 7.95354C5.34147 8.14936 5.65859 8.14952 5.85403 7.95388L13.3797 0.420561C13.575 0.22513 13.8917 0.225051 14.087 0.420383L15.7381 2.07143C15.9333 2.26669 15.9333 2.58327 15.7381 2.77854L5.8535 12.6631Z" />
  </svg>
);

const AboutSection = () => {
  const { t } = useTranslation();
  const List = ({ text }) => (
    <p className="mb-5 flex items-center text-base text-body-color dark:text-body-color-dark">
      <span className="mr-4 flex h-[30px] w-full max-w-[30px] items-center justify-center rounded-md bg-primary bg-opacity-10 text-primary">
        {checkIcon}
      </span>
      {text}
    </p>
  );

  return (
    <section id="about" className="pt-16 border-b-[1px] border-purple-500">
      <div className="container">
        <div className=" pb-16">
          <div className="-mx-4 flex flex-wrap items-center">
            <div className="w-full px-4 lg:w-1/2 relative">
              <div className="absolute  h-full w-full -left-[100px] GridLockup_beams-3 opacity-70 pointer-events-none"></div>
              <div className="w-full mx-auto mb-10">
                <h2 className="mb-4 text-3xl font-bold text-black dark:text-white sm:text-4xl md:text-[45px]">
                {t("mainPage.aboutSection.title")}
                </h2>
                <p className="text-base text-black dark:text-white md:text-lg">
                {t("mainPage.aboutSection.title2")}
                </p>
              </div>

              <div className=" mb-12 max-w-[570px] lg:mb-0 text-black dark:text-white">
                <div className="mx-[-12px] flex flex-wrap">
                  <div className="w-full px-3 sm:w-1/2 lg:w-full xl:w-1/2">
                    <List text={t("mainPage.aboutSection.descr.1")} />
                    <List text={t("mainPage.aboutSection.descr.2")} />
                    <List text={t("mainPage.aboutSection.descr.3")} />
                    <List text={t("mainPage.aboutSection.descr.4")} />
                    <List text={t("mainPage.aboutSection.descr.5")}/>
                  </div>

                  <div className="w-full px-3 sm:w-1/2 lg:w-full xl:w-1/2">
                    <List text={t("mainPage.aboutSection.descr.6")}/>
                    <List text={t("mainPage.aboutSection.descr.7")}/>
                    <List text={t("mainPage.aboutSection.descr.8")}/>
                    <List text={t("mainPage.aboutSection.descr.9")}/>
                    <List text={t("mainPage.aboutSection.descr.10")} />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full px-4 lg:w-1/2  flex justify-center">
              <div className="relative">
                <img
                  src={require("../../images/test10.png")}
                  alt="about"
                  className="mx-auto  h-[600px] drop-shadow-three rounded-3xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
