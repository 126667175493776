import { useTheme } from "../../providers/ThemeProvider";
import { FiMoon } from "react-icons/fi";
import { IoSunnyOutline } from "react-icons/io5";
import { IconContext } from "react-icons";
import { motion } from "framer-motion";

const ThemeSwitcher = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <motion.button
      className="text-dark dark:text-white"
      onClick={toggleTheme}
      whileTap={{ scale: 0.9 }}
    >
      <IconContext.Provider
        value={{ className: "cursor-pointer", size: "1.5rem" }}
      >
        {theme === "dark" ? <IoSunnyOutline /> : <FiMoon />}
      </IconContext.Provider>
    </motion.button>
  );
};

export default ThemeSwitcher;
