const SingleBrand = ({brand}) => {
  return (
    <div className="mx-3 flex w-[300px] items-center justify-center sm:mx-4 xl:mx-6 2xl:mx-8 p-2 text-center text-gray-700 dark:text-gray-100">
      <a
        href={brand.href}
        target="_blank"
        rel="nofollow noreferrer"
        className="relative w-full opacity-70 grayscale transition hover:opacity-100 hover:grayscale-0 dark:opacity-60 dark:hover:opacity-100"
      >
        <h1 className={`text-2xl ${brand.size} ${brand.style}`}>{brand.title}</h1>
      </a>
    </div>
  );
};
export default SingleBrand;
