import { useTranslation } from "react-i18next";

const ARTPage = () => {
  const { t } = useTranslation();

  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="py-3 text-lg font-normal leading-8">
          {t(`art.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <div>{paragraphs}</div>;
  }

  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <div className="mx-auto max-w-[1248px]">
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("art.paragraph1.title")}
          </h1>
          {Paragraphs(1, 21)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
           {t("art.paragraph2.title")}
          </h1>
          {Paragraphs(2, 6)}
          <ul className="list-none list-inside ps-10">
            <li>
              {Paragraphs(2, 13, 7)}
            </li>
          </ul>
          {Paragraphs(2, 66, 14)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
           {t("art.paragraph3.title")}
          </h1>
          {Paragraphs(3, 28)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
           {t("art.paragraph4.title")}
          </h1>
          {Paragraphs(4, 33)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
           {t("art.paragraph5.title")}
          </h1>
          {Paragraphs(5, 23)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
           {t("art.paragraph6.title")}
          </h1>
          {Paragraphs(6, 3)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
           {t("art.paragraph7.title")}
          </h1>
          {Paragraphs(7, 5)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
           {t("art.paragraph8.title")}
          </h1>
          {Paragraphs(8, 9)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
           {t("art.paragraph9.title")}
          </h1>
          {Paragraphs(9, 3)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
           {t("art.paragraph10.title")}
          </h1>
          {Paragraphs(10, 15)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
           {t("art.paragraph11.title")}
          </h1>
          {Paragraphs(11, 24)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
           {t("art.paragraph12.title")}
          </h1>
          {Paragraphs(12, 8)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
           {t("art.paragraph13.title")}
          </h1>
          {Paragraphs(13, 6)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
           {t("art.paragraph14.title")}
          </h1>
          {Paragraphs(14, 10)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
           {t("art.paragraph15.title")}
          </h1>
          {Paragraphs(15, 4)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
           {t("art.paragraph16.title")}
          </h1>
          {Paragraphs(16, 6)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
           {t("art.paragraph17.title")}
          </h1>
          {Paragraphs(17, 8)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
           {t("art.paragraph18.title")}
          </h1>
          {Paragraphs(18, 31)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
           {t("art.paragraph19.title")}
          </h1>
          {Paragraphs(19, 27)}
        </div>
      </div>
    </section>
  );
};

export default ARTPage;
