import { useTranslation } from "react-i18next";

const MerchantConfigPage = () => {
  const { t } = useTranslation();

  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start + 1; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="py-3 text-lg font-normal leading-8">
          {t(`MerchantConfig.descr.${i}`)}
        </p>
      );
    }
    return <div>{paragraphs}</div>;
  }

  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <div className="mx-auto max-w-[1248px]">
          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("MerchantConfig.title")}</h1>
          <p className="py-3 text-lg font-normal leading-8">{t(`MerchantConfig.descr.1`)}</p>
          <p className="py-3 text-lg font-normal leading-8">
            {t(`MerchantConfig.descr.2`)}{" "}
            <a className="text-blue-500" href="https://wallet.newreality.club/">
              https://wallet.newreality.club/
            </a>
          </p>
          {Paragraphs(1, 3, 2)}
          <p className="py-3 text-xl font-bold leading-8 text-green-700 dark:text-green-400">{t(`MerchantConfig.descr.4`)}</p>
          <ul className="list-disc list-inside">
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("MerchantConfig.descr.5")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("MerchantConfig.descr.6")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("MerchantConfig.descr.7")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("MerchantConfig.descr.8")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("MerchantConfig.descr.9")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("MerchantConfig.descr.10")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("MerchantConfig.descr.11")}</span>
            </li>
          </ul>
          {Paragraphs(1, 14, 13)}
          <p className="py-3 text-xl font-bold leading-8 text-green-700 dark:text-green-400">{t(`MerchantConfig.descr.15`)}</p>
          {Paragraphs(1, 33, 16)}
          <p className="py-3 text-xl font-bold leading-8 text-green-700 dark:text-green-400">{t(`MerchantConfig.descr.34`)}</p>
          {Paragraphs(1, 45, 35)}
          <p className="py-3 text-xl font-bold leading-8 text-green-700 dark:text-green-400">{t(`MerchantConfig.descr.46`)}</p>
          <ul className="list-disc list-inside">
            <li> <a className="text-blue-500 font-bold" href="https://doc.newreality.bond">{t("MerchantConfig.descr.47")}</a></li>
            <li> <a className="text-blue-500 font-bold" href="https://demo.newreality.bond">{t("MerchantConfig.descr.48")}</a></li>
            <li> <a className="text-blue-500 font-bold" href="https://wallet.newreality.club">{t("MerchantConfig.descr.49")}</a></li>
            <li> <a className="text-blue-500 font-bold" href="/faq">{t("MerchantConfig.descr.50")}</a></li>
            <li> <a className="text-blue-500 font-bold" href="https://newreality.cc">{t("MerchantConfig.descr.51")}</a></li>
            <li> <a className="text-blue-500 font-bold" href="/TariffsPage">{t("MerchantConfig.descr.52")}</a></li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default MerchantConfigPage;
