import { useTranslation } from "react-i18next";

const TermsPage = () => {
  const { t } = useTranslation();

  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="py-3 text-lg font-normal leading-8">
          {t(`Terms.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <div>{paragraphs}</div>;
  }

  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <div className="mx-auto max-w-[1248px]">
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Terms.paragraph1.title")}
          </h1>
          {Paragraphs(1, 1)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Terms.paragraph2.title")}
          </h1>
          {Paragraphs(2, 69)}
          
        </div>
      </div>
    </section>
  );
};

export default TermsPage;
