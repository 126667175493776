import { useTranslation } from "react-i18next";

const AboutSectionTwo = () => {
  const { t } = useTranslation();
  return (
    <section className="py-16 md:py-20 lg:py-28 border-b-[1px] border-purple-500">
      <div className="container">
        <div className="-mx-4 flex flex-wrap items-center">
          <div className="w-full px-4 lg:w-1/2">
            <div className="relative mx-auto mb-12 text-center lg:m-0">
              <img
                src={require("../../images/test9.png")}
                alt="about"
                className="mx-auto w-[500px] drop-shadow-three rounded-3xl"
              />
            </div>
          </div>
          <div className="w-full px-4 xl:pl-20 lg:w-1/2 relative">
            <div className="h-full w-full GridLockup_beams-4 opacity-70 pointer-events-none"></div>
            <div className="max-w-[470px] m-auto">
              <div className="mb-9">
                <h3 className="mb-4 text-xl font-bold text-black dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                  {t("mainPage.aboutSection2.1.title")}
                </h3>
                <p className="text-base leading-relaxed text-black dark:text-white">
                  {t("mainPage.aboutSection2.1.p")}
                </p>
              </div>
              <div className="mb-9">
                <h3 className="mb-4 text-xl font-bold text-black dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                  {t("mainPage.aboutSection2.2.title")}
                </h3>
                <p className="text-base leading-relaxed text-black dark:text-white">
                  {t("mainPage.aboutSection2.2.p")}
                </p>
              </div>
              <div className="mb-1">
                <h3 className="mb-4 text-xl font-bold text-black dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                  {t("mainPage.aboutSection2.3.title")}
                </h3>
                <p className="text-base leading-relaxed text-black dark:text-white">
                  {t("mainPage.aboutSection2.3.p")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSectionTwo;
