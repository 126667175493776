import React from 'react'
import SingleBrand from './SingleBrand';
import brandsData from './BrandsData';

const Brands = () => {
  return (
    <section className="py-16 border-b-[1px] border-purple-500">
      <div className="container">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div
              className="  flex flex-wrap items-center justify-center rounded-sm py-8 px-8 bg-gray-200 dark:bg-gray-900 sm:px-10 md:py-[40px] md:px-[50px] xl:p-[50px] 2xl:py-[60px] 2xl:px-[70px]"
              data-wow-delay=".1s
              "
            >
              {brandsData.map((brand) => (
                <SingleBrand key={brand.id} brand={brand} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Brands;