import { Tooltip, Typography } from "@material-tailwind/react";
import { t } from "i18next";
import { useState } from "react";
import { Link } from "react-router-dom";

const Card = (props) => {
  const cardData = props.cardItem;
  const [isHovered, setIsHovered] = useState(false);
  const gradientHover = isHovered
    ? "from-green-700 to-green-500"
    : "from-indigo-700 to-blue-500";
  const borderHover = isHovered ? "border-green-500" : "border-indigo-500 ";

  return (
    <Link to={`/token/${cardData.tiker}`}>
      <div
        className={`grid relative z-11 w-[280px] h-[360px] bg-white border ${borderHover} rounded-[10px] shadow dark:bg-gray-800 !bg-opacity-80 backdrop-blur-sm transition overflow-hidden mx-10  zoom cursor-pointer`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          style={{ backgroundImage: `url(${cardData.bg})` }}
          className="h-[95px] bg-center bg-no-repeat bg-cover rounded-t-[10px] border-b-[1px] border-purple-500"
        >
          <div className="absolute flex justify-center top-[10px] left-[10px] z-20  w-auto">
            <span className="rounded-md w-auto h-auto bg-green-600 p-1 mr-3 font-normal text-sm text-green-100">{cardData.type}</span>
            <span className="rounded-md w-auto bg-blue-700 p-1 mr-3 font-normal text-sm text-blue-100 ">{cardData.category}</span>
          </div>

          <div className="absolute top-[50px] right-[10px] flex items-center justify-center content-center w-[90px] h-[90px]  bg-white  rounded-xl border-2 border-purple-500 ">
            <img className="rounded-xl" src={cardData.icon} width={90} height={90} alt="" />
          </div>
        </div>
        <div className="p-[10px] pt-[15px] flex flex-col gap-[10px] justify-start h-[200px]">
          <div className="relative flex items-center justify-between">
            <div className="h-[60px] ">
              <h5 className="text-[32px] h-[38px] font-bold text-gray-900 dark:text-white max-w-[240px] uppercase">{cardData.tiker}</h5>
              <p className="text-[20px] h-[23px] font-normal text-gray-700 dark:text-gray-400 ">{cardData.title}</p>
            </div>
            <div className="flex self-end">
              {cardData.popular && (
                <Tooltip
                  placement="bottom-end"
                  className="z-30 px-4 py-3 shadow-xl shadow-black/10 bg-white border border-indigo-500 dark:bg-gray-800 dark:border-indigo-400"
                  content={
                    <div className="w-30">
                      <Typography color="white" className="font-normal text-gray-900 dark:text-white">
                        {t("tooltips.1")}
                      </Typography>
                    </div>
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" viewBox="0 -0.5 25 25">
                    <path
                      stroke="#f5d21b"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M14.349 8.515 12.5 5l-1.849 3.515A1.866 1.866 0 0 1 9.31 9.5l-3.81.747 2.66 3.181c.364.399.535.936.47 1.472L8.124 19l3.508-1.7a2 2 0 0 1 .868-.2c.282 0 .56.07.81.2l3.59 1.595-.506-4.051a1.851 1.851 0 0 1 .471-1.468l2.635-3.129-3.81-.747a1.842 1.842 0 0 1-1.341-.985Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Tooltip>
              )}
              <Tooltip
                placement="bottom-end"
                className="z-30 px-4 py-3 shadow-xl shadow-black/10 bg-white border border-indigo-500 dark:bg-gray-800 dark:border-indigo-400"
                content={
                  <div className="w-30">
                    <Typography color="white" className="font-normal text-gray-900 dark:text-white">
                      {t("tooltips.2")}
                    </Typography>
                    <Typography variant="small" color="white" className="font-normal text-gray-700 dark:text-gray-400">
                      {t("tooltips.3")}
                    </Typography>
                  </div>
                }
              >
                <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" viewBox="0 -0.5 25 25">
                  <g stroke="#22c55e" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
                    <path d="M5.5 12a7 7 0 1 1 14 .001 7 7 0 0 1-14 0Z" clipRule="evenodd" />
                    <path d="m9 12 2.333 2.333L16 9.667" />
                  </g>
                </svg>
              </Tooltip>
            </div>
          </div>

          <div className="flex flex-col justify-center gap-[5px] mt-[20px]">
            <div className="flex justify-between h-[16px]">
              <p className="font-normal text-gray-700 dark:text-gray-400">Base price</p>
              <p className="text-green-600 font-bold">{cardData.base_price}</p>
            </div>
            <div className="flex justify-between h-[16px]">
              <p className="font-normal text-gray-700 dark:text-gray-400">Selling price</p>
              <p className="font-normal text-gray-700 dark:text-gray-400">{cardData.selling_price}</p>
            </div>
            {cardData.allocation ? (
              <div className="flex justify-between h-[16px]">
                <p className="font-normal text-gray-700 dark:text-gray-400">Allocation</p>
                <p className="font-normal text-gray-700 dark:text-gray-400">{cardData.allocation}</p>
              </div>
            ) : null}

            {cardData.emission ? (
              <div className="flex justify-between h-[16px]">
                <p className="font-normal text-gray-700 dark:text-gray-400">Emission</p>
                <p className="font-normal text-gray-700 dark:text-gray-400">{cardData.emission}</p>
              </div>
            ) : null}
          </div>

          {cardData.percent ? (
            <div className="bottom-[30px] w-full pt-3">
              <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">
                <div style={{ width: `${cardData.percent}%` }} className="bg-blue-600 h-1.5 rounded-full dark:bg-blue-500"></div>
              </div>
              <div className="flex justify-between mb-1 ">
                <span className="font-normal text-gray-700 dark:text-white">150 holders per country</span>
              </div>
            </div>
          ) : null}
        </div>

        <div className={`max-h-[20px] self-end  rounded-b-[9px] w-full flex justify-center bg-gradient-to-r ${gradientHover}`}>
          <p className="font-normal text-white dark:text-white uppercase">{cardData.sale}</p>
        </div>
      </div>
    </Link>
  );
};

export default Card;
