import { useState } from "react";
import cz from "../images/flags/Cz.svg";
import kz from "../images/flags/Kz.svg";
import pl from "../images/flags/Pl.svg";
import { useTranslation } from "react-i18next";

const StandardtsPage = () => {
  const { t } = useTranslation();
  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="py-3 text-lg font-normal leading-8">
          {t(`Legal.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <div>{paragraphs}</div>;
  }

  return (
    <section className="py-16 md:py-20 lg:py-28">
      <div className="container ">
        <div className="w-full mx-auto text-center ">
          <h2 className="mb-4 text-3xl font-bold !leading-tight text-black dark:text-white sm:text-4xl md:text-[45px]">
            {t("Legal.paragraph1.title")}
          </h2>
          <p className="text-base !leading-relaxed text-black dark:text-white md:text-lg">
            {t("Legal.paragraph1.descr.1")}
          </p>
          <p className="text-base font-bold mt-5 !leading-relaxed text-black dark:text-white md:text-lg">
            {t("Legal.paragraph2.descr.1")}
          </p>
        </div>
        <div className="rounded-lg border-2 border-gray-400 bg-white shadow-three dark:bg-gray-900 dark:shadow-two my-[50px] p-5">
          <ol className="space-y-4 text-black  list-inside dark:text-white">
            <li>
              <div className="flex  gap-5 items-center">
                <img className="w-10 h-10 " src={kz} alt="" />
                <p className="text-xl font-bold">
                  {t("Legal.paragraph3.title")}
                </p>
              </div>
              <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
                <li>{t("Legal.paragraph3.descr.1")}</li>
                <li>{t("Legal.paragraph3.descr.2")}</li>
                <li>{t("Legal.paragraph3.descr.3")}</li>
              </ul>
            </li>
            <li>
              <p className="mt-5">{t("Legal.paragraph3.descr.4")}</p>
              <p>{t("Legal.paragraph3.descr.5")}</p>
              <p>
                {t("Legal.paragraph3.descr.6")}
                <a className="text-blue-500" href="https://newreality.cc">
                  https://newreality.cc
                </a>
              </p>
              <p className="mt-5">{t("Legal.paragraph3.descr.7")}</p>
              <p className="mt-5">{t("Legal.paragraph3.descr.8")}</p>
              <p>{t("Legal.paragraph3.descr.9")}</p>
              <ul className="ps-5 mt-2 space-y-3 list-none list-inside">
                <li>{t("Legal.paragraph3.descr.10")}</li>
                <li>{t("Legal.paragraph3.descr.11")}</li>
                <li>{t("Legal.paragraph3.descr.12")}</li>
              </ul>
              <p className="mt-5">
                {t("Legal.paragraph3.descr.13")}
                <a
                  className="text-blue-500"
                  href="https://aifc.kz/ru/ethics-code"
                >
                  {t("Legal.paragraph3.descr.14")}
                </a>
                <span> {t("Legal.paragraph3.descr.15")}</span>
              </p>
              <p>{t("Legal.paragraph3.descr.16")}</p>
              <ul className="ps-5 mt-2 space-y-3 list-none list-inside">
                <li>{t("Legal.paragraph3.descr.17")}</li>
                <li>{t("Legal.paragraph3.descr.18")}</li>
                <li>{t("Legal.paragraph3.descr.19")}</li>
              </ul>
            </li>
          </ol>
        </div>

        <div className="rounded-lg bg-white border-2 border-gray-400 shadow-three dark:bg-gray-900 dark:shadow-two my-[80px] p-5">
          <ol className="space-y-4 text-black  list-inside dark:text-white">
            <li>
              <div className="flex  gap-5 items-center">
                <img className="w-10 h-10 " src={cz} alt="" />
                <p className="text-xl font-bold">
                  {t("Legal.paragraph4.title")}
                </p>
              </div>
              <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
                <li>{t("Legal.paragraph4.descr.1")}</li>
                <li>{t("Legal.paragraph4.descr.2")}</li>
                <li>{t("Legal.paragraph4.descr.3")}</li>
              </ul>
            </li>
            <li>
              <p className="mt-5">{t("Legal.paragraph4.descr.4")}</p>
              <p className="mt-5">
                {t("Legal.paragraph4.descr.6")}
                <a className="text-blue-500" href="https://newreality.cc">
                  https://newreality.cc
                </a>
              </p>
              <p className="mt-5">{t("Legal.paragraph4.descr.7")}</p>
              <p className="mt-5">{t("Legal.paragraph4.descr.8")}</p>
              <ul className="ps-5 mt-2 space-y-3 list-disc list-inside">
                <li>{t("Legal.paragraph4.descr.9")}</li>
                <li>{t("Legal.paragraph4.descr.10")}</li>
                <li>{t("Legal.paragraph4.descr.11")}</li>
                <li>{t("Legal.paragraph4.descr.12")}</li>
                <li>{t("Legal.paragraph4.descr.13")}</li>
                <li>{t("Legal.paragraph4.descr.14")}</li>
                <li>{t("Legal.paragraph4.descr.15")}</li>
                <li>{t("Legal.paragraph4.descr.16")}</li>
              </ul>
              <ul className="mt-5 space-y-3">
                <li>{t("Legal.paragraph4.descr.17")}</li>
                <li>{t("Legal.paragraph4.descr.18")}</li>
                <li>{t("Legal.paragraph4.descr.19")}</li>
                <li>{t("Legal.paragraph4.descr.20")}</li>
                <li>{t("Legal.paragraph4.descr.21")}</li>
                <li>
                  {t("Legal.paragraph4.descr.22.1")}
                  <a className="text-blue-500" href="https://newreality.cc">
                    {t("Legal.paragraph4.descr.22.2")}
                  </a>
                </li>
                <li>
                  {t("Legal.paragraph4.descr.22.3")}
                  <a className="text-blue-500" href="/termsOfUse">
                    {t("Legal.paragraph4.descr.22.4")}
                  </a>
                  {t("Legal.paragraph4.descr.22.5")}
                </li>
                <li>{t("Legal.paragraph4.descr.23")}</li>
                <li>{t("Legal.paragraph4.descr.24")}</li>
                <li>{t("Legal.paragraph4.descr.25")}</li>
                <li>{t("Legal.paragraph4.descr.26")}</li>
                <li>{t("Legal.paragraph4.descr.27")}</li>
                <li>
                  {t("Legal.paragraph4.descr.28.1")}
                  <a
                    className="text-blue-500"
                    href="https://eur-lex.europa.eu/EN/legal-content/summary/european-crypto-assets-regulation-mica.html"
                  >
                    {t("Legal.paragraph4.descr.28.2")}
                  </a>
                </li>
                <li>{t("Legal.paragraph4.descr.29")}</li>
              </ul>
            </li>
          </ol>
        </div>

        <div className="rounded-lg border-2 border-gray-400 bg-white shadow-three dark:bg-gray-900 dark:shadow-two my-[80px] p-5">
          <ol className="space-y-4 text-black  list-inside dark:text-white">
            <li>
              <div className="flex  gap-5 items-center">
                <img className="w-10 h-10 " src={pl} alt="" />
                <p className="text-xl font-bold">
                  {t("Legal.paragraph5.title")}
                </p>
              </div>
              <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
                <li>{t("Legal.paragraph5.descr.1")}</li>
                <li>{t("Legal.paragraph5.descr.2")}</li>
                <li>{t("Legal.paragraph5.descr.3")}</li>
                <li>{t("Legal.paragraph5.descr.4")}</li>
              </ul>
            </li>
            <li>
              <p>{t("Legal.paragraph5.descr.5")}</p>
              <p>{t("Legal.paragraph5.descr.6")}</p>
              <p className="mt-5">
                {t("Legal.paragraph5.descr.7")}
                <a className="text-blue-500" href="https://newreality.cc">
                  https://newreality.cc
                </a>
              </p>
              <p className="mt-5">{t("Legal.paragraph5.descr.8")}</p>
              <p className="mt-5">{t("Legal.paragraph5.descr.9")}</p>
              <ul className="ps-5 mt-2 space-y-3 list-disc list-inside">
                <li>{t("Legal.paragraph5.descr.10")}</li>
                <li>{t("Legal.paragraph5.descr.11")}</li>
                <li>{t("Legal.paragraph5.descr.12")}</li>
                <li>{t("Legal.paragraph5.descr.13")}</li>
                <li>{t("Legal.paragraph5.descr.14")}</li>
                <li>{t("Legal.paragraph5.descr.15")}</li>
                <li>{t("Legal.paragraph5.descr.16")}</li>
                <li>{t("Legal.paragraph5.descr.17")}</li>
                <li>{t("Legal.paragraph5.descr.18")}</li>
                <li>{t("Legal.paragraph5.descr.19")}</li>
                <li>
                  {t("Legal.paragraph4.descr.22.1")}
                  <a className="text-blue-500" href="https://newreality.cc">
                    {t("Legal.paragraph4.descr.22.2")}
                  </a>
                </li>
                <li>
                  {t("Legal.paragraph4.descr.22.3")}
                  <a className="text-blue-500" href="/termsOfUse">
                    {t("Legal.paragraph4.descr.22.4")}
                  </a>
                  {t("Legal.paragraph4.descr.22.5")}
                </li>
                <li>
                  {t("Legal.paragraph5.descr.21.1")}
                  <a
                    className="text-blue-500"
                    href="https://eur-lex.europa.eu/EN/legal-content/summary/european-crypto-assets-regulation-mica.html"
                  >
                    {t("Legal.paragraph5.descr.21.2")}
                  </a>
                  {t("Legal.paragraph5.descr.21.3")}
                </li>
                <ul className="ps-5 mt-2 space-y-1 list-none list-inside">
                  <li>{t("Legal.paragraph5.descr.22")}</li>
                  <li>{t("Legal.paragraph5.descr.23")}</li>
                  <li>{t("Legal.paragraph5.descr.24")}</li>
                </ul>
              </ul>
              <p className="mt-5">{t("Legal.paragraph5.descr.25")}</p>
            </li>
          </ol>
        </div>

        <div className="rounded-lg border-2 border-gray-400 bg-white shadow-three dark:bg-gray-900 dark:shadow-two my-[80px] p-5 text-black   dark:text-white">
          <h1 className="text-3xl font-bold pt-9 leading-8 text-center">
            {t("Legal.paragraph6.title")}
          </h1>
          {Paragraphs(6, 1)}

          <h1 className="text-3xl font-bold pt-9 leading-8 text-center">
            {t("Legal.paragraph7.title")}
          </h1>
          {Paragraphs(7, 8)}

          <h1 className="text-3xl font-bold pt-9 leading-8 text-center">
            {t("Legal.paragraph8.title")}
          </h1>
          {Paragraphs(8, 1)}

          <h1 className="text-3xl font-bold pt-9 leading-8 text-center">
            {t("Legal.paragraph9.title")}
          </h1>
          {Paragraphs(9, 7)}
        </div>
      </div>
    </section>
  );
};

export default StandardtsPage;
