import { useTranslation } from "react-i18next";

const RiskWarningPage = () => {
  const { t } = useTranslation();

  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="py-3 text-lg font-normal leading-8">
          {t(`RiskWarning.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <div>{paragraphs}</div>;
  }
  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <div className="mx-auto max-w-[1248px]">
          <h1 className="text-4xl font-bold pt-9 leading-8  text-center mb-20">
            {t("RiskWarning.paragraph1.title")}
          </h1>

          <h2 className="text-3xl font-bold leading-8 text-center mt-20 my-10">
            {t("RiskWarning.paragraph2.title")}
          </h2>
          {Paragraphs(2, 1)}

          <h2 className="text-3xl font-bold leading-8 text-center my-10">
            {t("RiskWarning.paragraph3.title")}
          </h2>
          {Paragraphs(3, 3)}

          <h2 className="text-3xl font-bold leading-8 text-center my-10">
            {t("RiskWarning.paragraph4.title")}
          </h2>
          {Paragraphs(4, 5)}

          <h2 className="text-3xl font-bold leading-8 text-center my-10">
            {t("RiskWarning.paragraph5.title")}
          </h2>
          {Paragraphs(5, 2)}

          <h2 className="text-3xl font-bold leading-8 text-center my-10">
            {t("RiskWarning.paragraph6.title")}
          </h2>
          {Paragraphs(6, 6)}

          <h2 className="text-3xl font-bold leading-8 text-center my-10">
            {t("RiskWarning.paragraph7.title")}
          </h2>
          {Paragraphs(7, 4)}

          <h2 className="text-3xl font-bold leading-8 text-center my-10">
            {t("RiskWarning.paragraph8.title")}
          </h2>
          {Paragraphs(8, 2)}

          <h2 className="text-3xl font-bold leading-8 text-center my-10">
            {t("RiskWarning.paragraph9.title")}
          </h2>
          {Paragraphs(9, 3)}

          <h2 className="text-3xl font-bold leading-8 text-center my-10">
            {t("RiskWarning.paragraph10.title")}
          </h2>

          {Paragraphs(10, 5)}

          <h2 className="text-3xl font-bold leading-8 text-center my-10">
            {t("RiskWarning.paragraph11.title")}
          </h2>

          {Paragraphs(11, 2)}
          <h2 className="text-3xl font-bold leading-8 text-center my-10">
            {t("RiskWarning.paragraph12.title")}
          </h2>

          {Paragraphs(12, 3)}
          <h2 className="text-3xl font-bold leading-8 text-center my-10">
            {t("RiskWarning.paragraph13.title")}
          </h2>
          {Paragraphs(13, 6)}

          <ul className="list-none list-inside ps-10">
            <li>
              {Paragraphs(13, 18, 7)}
            </li>
          </ul>

          <h2 className="text-3xl font-bold leading-8 text-center my-10">
            {t("RiskWarning.paragraph14.title")}
          </h2>
          {Paragraphs(14, 1)}

          <h2 className="text-3xl font-bold leading-8 text-center my-10">
            {t("RiskWarning.paragraph15.title")}
          </h2>
          {Paragraphs(15, 1)}
          <h2 className="text-3xl font-bold leading-8 text-center my-10">
            {t("RiskWarning.paragraph16.title")}
          </h2>
          {Paragraphs(16, 1)}
          <h2 className="text-3xl font-bold leading-8 text-center my-10">
            {t("RiskWarning.paragraph17.title")}
          </h2>
          {Paragraphs(17, 1)}
        </div>
      </div>
    </section>
  );
};

export default RiskWarningPage;
