import { useTranslation } from "react-i18next";
const MerchantPage = () => {
  const { t } = useTranslation();

  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="py-3 text-lg font-normal leading-8">
          {t(`Merchant.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <div>{paragraphs}</div>;
  }

  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <div className="mx-auto max-w-[1248px]">
          <h1 className="text-3xl font-bold leading-8 text-center mt-10">{t("Merchant.paragraph1.title")}</h1>
          <h1 className="text-3xl font-bold leading-8 text-center mb-10 text-green-700 dark:text-green-400">{t("Merchant.paragraph1.title2")}</h1>
          {Paragraphs(1, 3)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Merchant.paragraph2.title")}</h1>
          {Paragraphs(2, 4)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Merchant.paragraph3.title")}</h1>
          {Paragraphs(3, 2)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Merchant.paragraph4.title")}</h1>
          {Paragraphs(4, 4)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Merchant.paragraph5.title")}</h1>
          {Paragraphs(5, 1)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Merchant.paragraph6.title")}</h1>
          {Paragraphs(6, 3)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Merchant.paragraph7.title")}</h1>
          {Paragraphs(7, 4)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Merchant.paragraph8.title")}</h1>
          {Paragraphs(8, 2)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Merchant.paragraph9.title")}</h1>
          {Paragraphs(9, 1)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Merchant.paragraph10.title")}</h1>
          {Paragraphs(10, 2)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Merchant.paragraph11.title")}</h1>
          {Paragraphs(11, 1)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Merchant.paragraph12.title")}</h1>
          {Paragraphs(12, 1)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Merchant.paragraph13.title")}</h1>
          {Paragraphs(13, 2)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Merchant.paragraph14.title")}</h1>
          {Paragraphs(14, 1)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Merchant.paragraph15.title")}</h1>
          {Paragraphs(15, 1)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Merchant.paragraph16.title")}</h1>
          {Paragraphs(16, 1)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Merchant.paragraph17.title")}</h1>
          {Paragraphs(17, 1)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Merchant.paragraph18.title")}</h1>
          {Paragraphs(18, 2)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Merchant.paragraph19.title")}</h1>

          <ul className="list-disc list-inside">
            <li>
              <a className="text-blue-500 font-bold" href="/MerchantConfig">
                {t("Merchant.paragraph19.descr.1")}
              </a>
            </li>
            <li>
              <a className="text-blue-500 font-bold" href="https://docs.newreality.bond/api/">
                {t("Merchant.paragraph19.descr.2")}
              </a>
            </li>
            <li>
              <a className="text-blue-500 font-bold" href="/TariffsPage">
                {t("Merchant.paragraph19.descr.3")}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default MerchantPage;
