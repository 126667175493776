import { useTranslation } from "react-i18next";

const CookieNotice = () => {
  const { t } = useTranslation();

  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="py-3 text-lg font-normal leading-8">
          {t(`CookieNotice.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <div>{paragraphs}</div>;
  }

  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <div className="mx-auto max-w-[1248px]">
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("CookieNotice.paragraph1.title")}
          </h1>
          {Paragraphs(1, 4)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("CookieNotice.paragraph2.title")}
          </h1>
          {Paragraphs(2, 1)}
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("CookieNotice.paragraph3.title")}
          </h1>
          {Paragraphs(3, 1)}
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("CookieNotice.paragraph4.title")}
          </h1>
          {Paragraphs(4, 2)}
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("CookieNotice.paragraph5.title")}
          </h1>
          {Paragraphs(5, 1)}
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("CookieNotice.paragraph6.title")}
          </h1>
          {Paragraphs(6, 2)}
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("CookieNotice.paragraph7.title")}
          </h1>
          {Paragraphs(7, 1)}

          <table className="rounded-3xl block overflow-auto w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 w-1/4">
                  {t("CookieNotice.table.thead.1")}
                </th>
                <th scope="col" className="px-6 py-3 ">
                  {t("CookieNotice.table.thead.2")}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {t("CookieNotice.table.tbody.r1.1")}
                </td>
                <td className="px-6 py-4">
                  {t("CookieNotice.table.tbody.r1.2")}
                </td>
              </tr>
              <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {t("CookieNotice.table.tbody.r2.1")}
                </th>
                <td className="px-6 py-4">
                  {t("CookieNotice.table.tbody.r2.2")}
                </td>
              </tr>
              <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {t("CookieNotice.table.tbody.r3.1")}
                </th>
                <td className="px-6 py-4">
                  {t("CookieNotice.table.tbody.r3.2")}
                </td>
              </tr>
              <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {t("CookieNotice.table.tbody.r4.1")}
                </th>
                <td className="px-6 py-4">
                  {t("CookieNotice.table.tbody.r4.2")}
                </td>
              </tr>
            </tbody>
          </table>

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("CookieNotice.paragraph8.title")}
          </h1>
          <p className="py-3 text-lg font-normal leading-8">
            {t(`CookieNotice.paragraph8.descr.1`)}
          </p>
          <p className="py-3 text-lg font-normal leading-8">
            {t(`CookieNotice.paragraph8.descr.2`)}
            <a
              href="https://newreality.cc"
              className="py-3 text-lg font-normal leading-8 text-blue-500"
            >
              {t(`CookieNotice.paragraph8.descr.link1`)}
            </a>
            <label className="py-3 text-lg font-normal leading-8">
              {t(`CookieNotice.paragraph8.descr.3`)}
            </label>
            <a
              href="/contact"
              className="py-3 text-lg font-normal leading-8  text-blue-500"
            >
              {t(`CookieNotice.paragraph8.descr.link2`)}
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default CookieNotice;
