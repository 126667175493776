import { Translation } from "react-i18next";

const faqData = [
  {
    id: 1,
    ques: (
      <Translation>{(t) => <p>{t("faq.paragraph1.title")}</p>}</Translation>
    ),
    ans: (
      <Translation>{(t) => <p>{t("faq.paragraph1.descr.1")}</p>}</Translation>
    ),
  },
  {
    id: 2,
    ques: (
      <Translation>{(t) => <p>{t("faq.paragraph2.title")}</p>}</Translation>
    ),
    ans: (
      <Translation>{(t) => <p>{t("faq.paragraph2.descr.1")}</p>}</Translation>
    ),
  },
  {
    id: 3,
    ques: (
      <Translation>{(t) => <p>{t("faq.paragraph3.title")}</p>}</Translation>
    ),
    ans: (
      <Translation>{(t) => <p>{t("faq.paragraph3.descr.1")}</p>}</Translation>
    ),
  },
  {
    id: 4,
    ques: (
      <Translation>{(t) => <p>{t("faq.paragraph4.title")}</p>}</Translation>
    ),
    ans: (
      <Translation>{(t) => <p>{t("faq.paragraph4.descr.1")}</p>}</Translation>
    ),
  },
  {
    id: 5,
    ques: (
      <Translation>{(t) => <p>{t("faq.paragraph5.title")}</p>}</Translation>
    ),
    ans: (
      <Translation>{(t) => <p>{t("faq.paragraph5.descr.1")}</p>}</Translation>
    ),
  },
  {
    id: 6,
    ques: (
      <Translation>{(t) => <p>{t("faq.paragraph6.title")}</p>}</Translation>
    ),
    ans: (
      <Translation>{(t) => <p>{t("faq.paragraph6.descr.1")}</p>}</Translation>
    ),
  },
  {
    id: 7,
    ques: (
      <Translation>{(t) => <p>{t("faq.paragraph7.title")}</p>}</Translation>
    ),
    ans: (
      <Translation>{(t) => <p>{t("faq.paragraph7.descr.1")}</p>}</Translation>
    ),
  },
  {
    id: 8,
    ques: (
      <Translation>{(t) => <p>{t("faq.paragraph8.title")}</p>}</Translation>
    ),
    ans: (
      <Translation>{(t) => <p>{t("faq.paragraph8.descr.1")}</p>}</Translation>
    ),
  },
];

export default faqData;
