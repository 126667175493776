import { useEffect, useRef } from "react";
import CarouselC from "../Carousel/Carousel";
import ScrollSpy from "../ScrollSpy/ScrollSpy";
import { motion, useInView } from "framer-motion";
import { Typography, Tooltip } from "@material-tailwind/react";
import { t } from "i18next";

const Tool = ({ popular }) => {
  return (
    <div className="flex self-end">
      {popular && (
        <Tooltip
          placement="bottom-end"
          className="z-30 px-4 py-3 shadow-xl shadow-black/10 bg-white  border-[1px] border-purple-500 dark:bg-black"
          content={
            <div className="w-30">
              <Typography
                color="white"
                className="font-normal text-gray-900 dark:text-white"
              >
                {t("tooltips.1")}
              </Typography>
            </div>
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            fill="none"
            viewBox="0 -0.5 25 25"
          >
            <path
              stroke="#f5d21b"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M14.349 8.515 12.5 5l-1.849 3.515A1.866 1.866 0 0 1 9.31 9.5l-3.81.747 2.66 3.181c.364.399.535.936.47 1.472L8.124 19l3.508-1.7a2 2 0 0 1 .868-.2c.282 0 .56.07.81.2l3.59 1.595-.506-4.051a1.851 1.851 0 0 1 .471-1.468l2.635-3.129-3.81-.747a1.842 1.842 0 0 1-1.341-.985Z"
              clipRule="evenodd"
            />
          </svg>
        </Tooltip>
      )}
      <Tooltip
        placement="bottom-end"
        className="z-30 px-4 py-3 shadow-xl shadow-black/10 bg-white border-[1px] border-purple-500 dark:bg-black"
        content={
          <div className="w-30">
            <Typography
              color="white"
              className="font-normal text-gray-900 dark:text-white"
            >
              {t("tooltips.2")}
            </Typography>
            <Typography
              variant="small"
              color="white"
              className="font-normal text-gray-700 dark:text-gray-400"
            >
              {t("tooltips.3")}
            </Typography>
          </div>
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={32}
          height={32}
          fill="none"
          viewBox="0 -0.5 25 25"
        >
          <g
            stroke="#22c55e"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
          >
            <path
              d="M5.5 12a7 7 0 1 1 14 .001 7 7 0 0 1-14 0Z"
              clipRule="evenodd"
            />
            <path d="m9 12 2.333 2.333L16 9.667" />
          </g>
        </svg>
      </Tooltip>
    </div>
  );
};

const BuyCard = ({ tokenItem }) => {
  return (
    <div className="grid justify-items-center gap-1 2xl:gap-5 border-[1px] border-purple-500  h-full rounded-xl bg-white bg-opacity-20 backdrop-blur-md dark:bg-black dark:bg-opacity-20">
      <div className="px-2 pt-2 lg:px-6 xl:px-10 lg:pt-6 xl:pt-10 w-full">
        <div className="flex justify-between ">
          <div className="flex items-start gap-3 md:flex-col xl:flex-row">
            <span className="rounded-md w-auto h-auto bg-green-200 p-1 font-normal text-sm text-green-700 capitalize">
              {tokenItem.type}
            </span>
            <span className="rounded-md w-auto bg-blue-200 p-1 font-normal text-sm text-blue-700 capitalize ">
              {tokenItem.category}
            </span>
          </div>

          <Tool popular={tokenItem.popular} />
        </div>
      </div>
      <div className="flex flex-col w-full px-3 lg:px-6 xl:px-10">
        <p className="text-base lg:text-xl">Emission:</p>
        <p className="font-bold text-xl lg:text-3xl">{tokenItem.emission}</p>
      </div>
      <div className="text-base lg:text-xl w-full flex flex-col gap-3 px-3 lg:px-6 xl:px-10">
        <div className="flex justify-between items-center">
          <p className="font-normal text-gray-700 dark:text-gray-400">
            Base price
          </p>
          <p className="text-green-600 font-bold">{tokenItem.base_price}</p>
        </div>
        <div className="flex justify-between ">
          <p className="font-normal text-gray-700 dark:text-gray-400">
            Selling price
          </p>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            {tokenItem.selling_price}
          </p>
        </div>
        {tokenItem.allocation ? (
          <div className="flex justify-between ">
            <p className="font-normal text-gray-700 dark:text-gray-400">
              Allocation
            </p>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              {tokenItem.allocation}
            </p>
          </div>
        ) : null}
      </div>
      <div className="self-end">
        <a
          className="p-[2px] relative "
          href={tokenItem.buy_link ? tokenItem.buy_link : "#"}
        >
          <div className="px-2 py-2 xl:py-4 xl:px-8  inset-0 bg-blue-600 rounded-lg relative group transition duration-300 ease-in-out text-white hover:text-white zoom">
            Купить
          </div>
        </a>
      </div>
      <div
        className={`max-h-[30px] self-end rounded-b-[11px] w-full flex justify-center bg-gradient-to-r from-indigo-700 to-blue-500`}
      >
        <p className="font-normal text-white dark:text-white uppercase">
          {tokenItem.sale}
        </p>
      </div>
    </div>
  );
};

const TokenInfo = ({ tokenItem }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const ref = useRef(null);
  const isInView = useInView(ref);

  return (
    <div className="text-black dark:text-white">
      <div className="flex items-start gap-[32px] md:px-10 mb-3 xl:py-5 xl:px-0  xl:mt-10">
        <div>
          <img
            className="min-w-[100px] max-w-[100px] rounded-xl bg-white"
            src={tokenItem.icon}
            alt="icon"
          />
        </div>
        <div className="flex flex-col gap-3 ">
          <div className="text-3xl font-bold">{tokenItem.title}</div>
          <div className="">{tokenItem.small_descr}</div>
        </div>
      </div>
      <div className="flex flex-col gap-5 md:px-10 xl:grid xl:grid-cols-3 xl:py-5 xl:px-0">
        <div className="col-span-2 ">
          <CarouselC imgs={tokenItem.imgs} />
        </div>
        <motion.div ref={ref} className="max-h-[500px] xl:h-96 2xl:h-[500px]">
          <BuyCard tokenItem={tokenItem} />
        </motion.div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-5 my-10 md:my-28 relative min-h-[300px]">
        <div className="col-span-2">
          <ScrollSpy descr={tokenItem.descr} />
        </div>
        <div className="col-span-1 hidden md:block">
          <motion.div
            className="sticky top-32  lg:h-[500px]"
            style={{
              transform: isInView ? "translateY(-100px)" : "none",
              opacity: isInView ? 0 : 1,
              transition: "all 0.4s",
            }}
          >
            <BuyCard tokenItem={tokenItem} />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default TokenInfo;
