import Carousel from "./Carousel";
import { motion, useScroll, useTransform } from "framer-motion";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

const NewProducts = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const { scrollYProgress: scrollYProgress1 } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });

  const { scrollYProgress: scrollYProgress2 } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const backgroundYC = useTransform(scrollYProgress2, [0, 1], ["-20%", "20%"]);

  return (
    <section
      ref={ref}
      id="marketplace"
      className="w-full h-fit overflow-hidden relative flex flex-col justify-start items-start gap-[180px] xl:h-screen"
    >
      <div className="container">
        <div className="w-auto xl:w-[900px] mx-auto mt-10  relative text-center mb-10 bg-black/50 rounded-b-3xl  backdrop-blur-xl p-3 z-50">
          <h2 className="mb-4 text-3xl font-bold !leading-tight text-white sm:text-4xl md:text-[45px]">
            {t("mainPage.newProducts.title")}
          </h2>
          <p className="text-base leading-relaxed md:text-lg text-white ">
            {t("mainPage.newProducts.descr")}
          </p>
          <a
            className="text-primary leading-relaxed text-base md:text-lg"
            href="popularTokens"
          >
            {t("mainPage.newProducts.button")}
          </a>
        </div>
      </div>
      <div className="relative">
        <div className="mx-auto w-screen">
          <Carousel />
        </div>
      </div>

      <motion.div
        className="absolute inset-0 z-0 factory_full_m pointer-events-none block xl:hidden"
        style={{
          backgroundPosition: "top",
          backgroundSize: "cover",
        }}
      />
      <motion.div
        className="absolute inset-0 z-0 factory_full pointer-events-none hidden xl:block"
        style={{
          backgroundPosition: "top",
          backgroundSize: "cover",
          y: backgroundYC,
        }}
      />
      <div
        className="absolute inset-0 z-40 factory pointer-events-none "
        style={{
          backgroundPosition: "top",
          backgroundSize: "cover",
        }}
      />
    </section>
  );
};

export default NewProducts;
