import FeaturesData from "./FeaturesData";
import { motion, useScroll, useTransform } from "framer-motion";
import React, {  useRef } from "react";
import SingleFeature from "./SingleFeature";

const Features = () => {
  const ref = useRef(null);

  const { scrollYProgress: scrollYProgress2 } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const backgroundYC3 = useTransform(scrollYProgress2, [0, 1], ["-20%", "20%"]);

  return (
    <div
      ref={ref}
      className="w-full min-h-[1000px] h-fit xl:h-screen overflow-hidden relative flex flex-col justify-center items-center gap-10"
    >
      <div className="z-50">
        <div className="container">
          <div className="grid  my-10 grid-cols-1 gap-x-8 gap-y-14 md:grid-cols-2 lg:grid-cols-3 ">
            {FeaturesData.map((feature) => (
              <SingleFeature key={feature.id} feature={feature} />
            ))}
          </div>
        </div>
      </div>
      <motion.div
        className="absolute inset-0 z-0 lab_full pointer-events-none hidden xl:block"
        style={{
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          y: backgroundYC3,
        }}
      />
      <div
        className="absolute inset-0 z-0 lab_full_m pointer-events-none block xl:hidden"
        style={{
          backgroundPosition: "bottom",
          backgroundSize: "cover",
        }}
      />
      <div
        className="absolute inset-0 z-40 lab pointer-events-none xl:block"
        style={{
          backgroundPosition: "bottom",
          backgroundSize: "cover",
        }}
      />
    </div>
  );
};

export default Features;
