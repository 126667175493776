import { useTranslation } from "react-i18next";

const ContactInfoBox = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 className="mb-10 text-2xl font-bold leading-tight text-black dark:text-white">
        {t("ContactsPage.title")}
      </h2>

      <table className="rounded-3xl block overflow-auto w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3 w-1/4">
              {t("ContactsPage.table.thead.1")}
            </th>
            <th scope="col" className="px-6 py-3 w-1/4">
              {t("ContactsPage.table.thead.2")}
            </th>
            <th scope="col" className="px-6 py-3 w-1/4">
              {t("ContactsPage.table.thead.3")}
            </th>
            <th scope="col" className="px-6 py-3 w-1/4">
              {t("ContactsPage.table.thead.4")}
            </th>
            <th scope="col" className="px-6 py-3 w-1/4">
              {t("ContactsPage.table.thead.5")}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
            <th
              scope="row"
              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              {t("ContactsPage.table.tbody.r1.1")}
            </th>
            <td className="px-6 py-4">{t("ContactsPage.table.tbody.r1.2")}</td>
            <td className="px-6 py-4">{t("ContactsPage.table.tbody.r1.3")}</td>
            <td className="px-6 py-4">
              {t("ContactsPage.table.tbody.r1.4")}
            </td>
            <th scope="col" className="px-6 py-3 w-1/4">
              {t("ContactsPage.table.tbody.r1.5")}
            </th>
          </tr>
          <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
            <th
              scope="row"
              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              {t("ContactsPage.table.tbody.r2.1")}
            </th>
            <td className="px-6 py-4">    {t("ContactsPage.table.tbody.r2.2")}</td>
            <td className="px-6 py-4">    {t("ContactsPage.table.tbody.r2.3")}</td>
            <td className="px-6 py-4">
              {t("ContactsPage.table.tbody.r2.4")}
            </td>
            <th scope="col" className="px-6 py-3 w-1/4">
              {t("ContactsPage.table.tbody.r2.5")}
            </th>
          </tr>
          <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
            <th
              scope="row"
              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              {t("ContactsPage.table.tbody.r3.1")}
            </th>
            <td className="px-6 py-4">         {t("ContactsPage.table.tbody.r3.2")}</td>
            <td className="px-6 py-4">         {t("ContactsPage.table.tbody.r3.3")}</td>
            <td className="px-6 py-4">         {t("ContactsPage.table.tbody.r3.4")}</td>
            <th scope="col" className="px-6 py-3 w-1/4">
              {t("ContactsPage.table.tbody.r3.5")}
            </th>
          </tr>
          {/* <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
            <th
              scope="row"
              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              {t("ContactsPage.table.tbody.r4.1")}
            </th>
            <td className="px-6 py-4">            {t("ContactsPage.table.tbody.r4.2")}</td>
            <td className="px-6 py-4">            {t("ContactsPage.table.tbody.r4.3")}</td>
            <td className="px-6 py-4">-</td>
            <th scope="col" className="px-6 py-3 w-1/4">
              {t("ContactsPage.table.tbody.r4.4")}
            </th>
          </tr>
          <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
            <th
              scope="row"
              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              {t("ContactsPage.table.tbody.r5.1")}
            </th>
            <td className="px-6 py-4">{t("ContactsPage.table.tbody.r5.2")}</td>
            <td className="px-6 py-4">{t("ContactsPage.table.tbody.r5.3")}</td>
            <td className="px-6 py-4">{t("ContactsPage.table.tbody.r5.4")}</td>
            <th scope="col" className="px-6 py-3 w-1/4">
              {t("ContactsPage.table.tbody.r5.5")}
            </th>
          </tr>
          <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
            <th
              scope="row"
              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              {t("ContactsPage.table.tbody.r6.1")}
            </th>
            <td className="px-6 py-4">   {t("ContactsPage.table.tbody.r6.2")}</td>
            <td className="px-6 py-4">   {t("ContactsPage.table.tbody.r6.3")}</td>
            <td className="px-6 py-4">   {t("ContactsPage.table.tbody.r6.4")}</td>
            <th scope="col" className="px-6 py-3 w-1/4">
              {t("ContactsPage.table.tbody.r6.5")}
            </th> */}
          {/* </tr> */}
        </tbody>
      </table>

      <div className="mt-16 bg-white text-black dark:text-white dark:bg-black p-5 rounded-[10px] flex flex-col gap-5">
        <p>{t("ContactsPage.paragraph1.1")} <span className="text-blue-400">sales@newreality.ltd.</span></p>
        <p>{t("ContactsPage.paragraph2.1")} <span className="text-blue-400">contact@newreality.ltd.</span></p>
        <p>{t("ContactsPage.paragraph3.1")} <a href="https://newreality.cc" className="text-blue-400">{t("ContactsPage.paragraph3.2")}</a></p>
      </div>
    </>
  );
};

export default ContactInfoBox;
