import { useTranslation } from "react-i18next";

const СlassifyingPage = () => {
  const { t } = useTranslation();

  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="py-3 text-lg font-normal leading-8">
          {t(`classifying.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <div>{paragraphs}</div>;
  }

  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white" >
      <div className="container">
        <div className="mx-auto max-w-[1248px]">
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("classifying.paragraph1.title")}
          </h1>
          {Paragraphs(1, 10)}
          <div className="mt-3 text-blue-500 ">
            <p className=" text-lg font-normal leading-8">
              <a href="/ARTPage">{t("classifying.paragraph1.descr.11")}</a>
            </p>
            <p className=" text-lg font-normal leading-8">
              <a href="/EmtPage">{t("classifying.paragraph1.descr.12")}</a>
            </p>
            <p className=" text-lg font-normal leading-8">
              <a href="/UnboundPage">{t("classifying.paragraph1.descr.13")}</a>
            </p>
            <p className=" text-lg font-normal leading-8">
              <a href="/Createtoken">{t("classifying.paragraph1.descr.14")}</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default СlassifyingPage;
