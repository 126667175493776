import { useTranslation } from "react-i18next";
const ExchangeDescPage = () => {
  const { t } = useTranslation();

  function Paragraphs(p, end, start = 1, subtitle = false, plus = false) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      if (plus) {
        paragraphs.push(
          <p key={i} className="py-3 text-lg font-normal leading-8">
            <span className="text-green-700 dark:text-green-400 font-bold text-xl "> + </span>
            {t(`ExchangeDesc.paragraph${p}.descr.${i}`)}
          </p>
        );
      } else if (subtitle) {
        paragraphs.push(
          <div key={i} className="my-5">
            <p className="text-lg font-bold leading-8 text-green-700 dark:text-green-400">{t(`ExchangeDesc.paragraph${p}.descr.${i}.subtitle`)}</p>
            <p className="text-lg font-normal leading-8">{t(`ExchangeDesc.paragraph${p}.descr.${i}.p`)}</p>
          </div>
        );
      } else {
        paragraphs.push(
          <p key={i} className="py-3 text-lg font-normal leading-8">
            {t(`ExchangeDesc.paragraph${p}.descr.${i}`)}
          </p>
        );
      }
    }
    return <div>{paragraphs}</div>;
  }

  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <div className="mx-auto max-w-[1248px]">
          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("ExchangeDesc.paragraph1.title")}</h1>
          {Paragraphs(1, 3)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("ExchangeDesc.paragraph2.title")}</h1>
          {Paragraphs(2, 13, 1, true)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("ExchangeDesc.paragraph3.title")}</h1>
          <ul className="list-disc list-inside">
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph3.descr.1")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph3.descr.2")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph3.descr.3")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph3.descr.4")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph3.descr.5")}</span>
            </li>
          </ul>

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("ExchangeDesc.paragraph4.title")}</h1>
          <ul className="list-disc list-inside">
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph4.descr.1")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph4.descr.2")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph4.descr.3")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph4.descr.4")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph4.descr.5")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph4.descr.6")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph4.descr.7")}</span>
            </li>
          </ul>

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("ExchangeDesc.paragraph5.title")}</h1>
          {Paragraphs(5, 11, 1, true)}
          <p className="py-3 text-lg font-normal leading-8">{t(`ExchangeDesc.paragraph5.descr.12`)}</p>

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("ExchangeDesc.paragraph6.title")}</h1>
          <ul className="list-disc list-inside">
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph6.descr.1")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph6.descr.2")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph6.descr.3")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph6.descr.4")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph6.descr.5")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph6.descr.6")}</span>
            </li>
          </ul>

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("ExchangeDesc.paragraph7.title")}</h1>
          <ul className="list-disc list-inside">
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph7.descr.1")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph7.descr.2")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph7.descr.3")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph7.descr.4")}</span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph7.descr.5")}</span>
            </li>
            <p className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph7.descr.6")}</p>
            <p className="py-3 text-lg font-normal leading-8">{t("ExchangeDesc.paragraph7.descr.7")}</p>
          </ul>

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("ExchangeDesc.paragraph8.title")}</h1>
          {Paragraphs(8, 1)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("ExchangeDesc.paragraph9.title")}</h1>
          {Paragraphs(9, 3, 1, true)}
          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("ExchangeDesc.paragraph9.descr.4.p")}</h1>
          {Paragraphs(9, 10, 5, true)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("ExchangeDesc.paragraph10.title")}</h1>
          {Paragraphs(10, 13, 1, true)}
          {Paragraphs(10, 17, 14)}
          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("ExchangeDesc.paragraph11.title")}</h1>
          {Paragraphs(11, 7, 1, true)}
          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("ExchangeDesc.paragraph12.title")}</h1>
          <p className="py-3 text-lg font-normal leading-8">{t(`ExchangeDesc.paragraph12.descr.1`)}</p>
          <p className="py-3 text-lg font-normal leading-8">{t(`ExchangeDesc.paragraph12.descr.2`)}</p>
          {Paragraphs(12, 16, 3, false, true)}
          <p className="py-3 text-lg font-normal leading-8">{t(`ExchangeDesc.paragraph12.descr.17`)}</p>
        </div>
      </div>
    </section>
  );
};

export default ExchangeDescPage;
