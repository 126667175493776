const starIcon = (
  <svg width="18" height="16" viewBox="0 0 18 16" className="fill-yellow-200">
    <path d="M9.09815 0.361679L11.1054 6.06601H17.601L12.3459 9.59149L14.3532 15.2958L9.09815 11.7703L3.84309 15.2958L5.85035 9.59149L0.595291 6.06601H7.0909L9.09815 0.361679Z" />
  </svg>
);

const SingleTestimonial = ({ testimonial }) => {
  const { star, name, image, content, designation } = testimonial;

  let ratingIcons = [];
  for (let index = 0; index < star; index++) {
    ratingIcons.push(
      <span key={index} className="text-yellow">
        {starIcon}
      </span>
    );
  }

  return (
    <div className="w-full">
      <div className="p-8 h-full duration-300  bg-white rounded-sm wow fadeInUp shadow-two dark:bg-dark dark:shadow-three lg:px-5 xl:px-8 hover:shadow-one dark:hover:shadow-gray-dark">
        <div className="flex items-center mb-5 space-x-1">{ratingIcons}</div>
        <p className="pb-8 mb-8 text-base min-h-[85px] leading-relaxed border-b border-body-color/10 text-body-color dark:border-white/10 dark:text-white">
          “{content}“
        </p>
        <div className="flex items-center">
          <div className="relative mr-4 h-[50px] w-full max-w-[50px] overflow-hidden rounded-full">
            <img src={image} alt={name} />
          </div>
          <div className="w-full">
            <h3 className="mb-1 text-lg font-semibold text-dark dark:text-white lg:text-base xl:text-lg">
              {name}
            </h3>
            <p className="text-sm text-black dark:text-white">{designation}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleTestimonial;
