import { Link } from "react-router-dom";
import Accordion from "./Accordion";
import faqData from "./faqData";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const faqs_1 = faqData.slice(0, Math.ceil(faqData.length / 2));
  const faqs_2 = faqData.slice(Math.ceil(faqData.length / 2));
  const { t } = useTranslation();
  return (
    <section className="py-16 md:py-20 lg:py-28 beams h-max min-h-screen">
      <div className="container">
        <div className=" text-black dark:text-white flex flex-col">
          <span className="text-2xl font-bold">{t("faq.dlc.1")}</span>
          <span className="my-5 text-xl">
            {t("faq.dlc.2")}
            <a
              href="https://eur-lex.europa.eu/eli/reg/2023/1114/oj"
              className="text-blue-400"
            >
              {t("faq.dlc.3")}
            </a>{" "}
            {t("faq.dlc.4")}
          </span>
        </div>
        <div className="w-full mx-auto text-center mb-10 ">
          <h2 className="mb-4 text-3xl font-bold !leading-tight text-black dark:text-white sm:text-4xl md:text-[45px]">
            {t("faq.dlc.5")}
          </h2>
          <p className="text-base mx-[20px] lg:mx-[250px] !leading-relaxed text-black dark:text-white md:text-lg ">
            {t("faq.dlc.6")}
            <Link to="/contact" className="text-primary">
              {t("faq.dlc.7")}
            </Link>
          </p>
        </div>

        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 lg:w-1/2">
            <Accordion faqData={faqs_1} />
          </div>
          <div className="w-full px-4 lg:w-1/2">
            <Accordion faqData={faqs_2} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
