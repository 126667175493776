import classNames from "classnames";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

const MobileMenu = ({ isOpen, onClose }) => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick1 = (e) => {
    e.stopPropagation();
    setIsOpen1((prevState) => !prevState);
    if (isOpen2) {
      setIsOpen2(false);
    }
  };

  const handleClick2 = (e) => {
    e.stopPropagation();
    setIsOpen2((prevState) => !prevState);
    if (isOpen1) {
      setIsOpen1(false);
    }
  };

  const itemVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 24 },
    },
    closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
  };

  return (
    <div className="relative">
      <motion.div
        className={classNames("fixed inset-0 bg-black/50 z-[100] top-[72px]", {
          hidden: !isOpen || !isMobile,
        })}
        onClick={onClose}
      ></motion.div>

      <motion.ul
        initial={false}
        animate={isOpen ? "open" : "closed"}
        variants={{
          open: {
            y: 0,
            opacity: 1,
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.7,
              delayChildren: 0.3,
              staggerChildren: 0.05,
            },
          },
          closed: {
            y: -300,
            opacity: 0,
            transition: {
              type: "spring",
              duration: 1,
            },
          },
        }}
        className={classNames("fixed w-full font-normal flex flex-col bg-white dark:bg-black/90 gap-[20px] items-center p-4 border-y-[1px] border-gray-100 md:mt-0 md:border-0 z-[150]", {  hidden: !isMobile})}
      >
        <motion.li variants={itemVariants} className="relative group">
          <a
            href="/"
            className="block px-4 py-2 text-xl text-dark dark:text-white"
            aria-current="page"
          >
            Home
          </a>
        </motion.li>
        <motion.li variants={itemVariants} className="relative group">
          <a
            href="https://newreality.exchange/"
            className="block px-4 py-2 text-xl text-dark dark:text-white"
          >
            Exchange
          </a>
        </motion.li>
        <motion.li variants={itemVariants} className="relative group">
          <p
            className="flex items-center justify-between py-2 text-xl cursor-pointer text-dark dark:text-white"
            onClick={handleClick1}
          >
            Services
            <span className="pl-3">
              <svg width="25" height="24" viewBox="0 0 25 24">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.29289 8.8427C6.68342 8.45217 7.31658 8.45217 7.70711 8.8427L12 13.1356L16.2929 8.8427C16.6834 8.45217 17.3166 8.45217 17.7071 8.8427C18.0976 9.23322 18.0976 9.86639 17.7071 10.2569L12 15.964L6.29289 10.2569C5.90237 9.86639 5.90237 9.23322 6.29289 8.8427Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </p>
          <div className={isOpen1 ? "block" : "hidden"}>
            <a
              href={"https://wallet.newreality.club"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              Wallet
            </a>
            <a
              href={"https://newreality.fund"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              Asset Fund
            </a>
            <a
              href={"https://newreality.exchange"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              Cryptocurrencies Exchange
            </a>
            <a
              href={"https://bond.money"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              Bond Money
            </a>
            <a
              href={"https://webpayment.bond"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              Webpayment Gateway
            </a>
            <a
              href={"https://explorer.newreality.club"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              BigDipper Explorer
            </a>
            <a
              href={"https://ping.newreality.club"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              Ping Explorer
            </a>
          </div>
        </motion.li>

        <motion.li
          variants={itemVariants}
          className="relative group"
          onClick={handleClick2}
        >
          <p className="flex items-center justify-between py-2 text-xl cursor-pointer text-dark dark:text-white lg:mr-0 lg:inline-flex lg:py-6 lg:px-0">
            Knowledge base
            <span className="pl-3">
              <svg width="25" height="24" viewBox="0 0 25 24">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.29289 8.8427C6.68342 8.45217 7.31658 8.45217 7.70711 8.8427L12 13.1356L16.2929 8.8427C16.6834 8.45217 17.3166 8.45217 17.7071 8.8427C18.0976 9.23322 18.0976 9.86639 17.7071 10.2569L12 15.964L6.29289 10.2569C5.90237 9.86639 5.90237 9.23322 6.29289 8.8427Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </p>
          <div className={isOpen2 ? "block" : "hidden"}>
            <a
              href={"https://wallet.newreality.club"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              Terms and definitions
            </a>
            <a
              href={"https://newreality.fund"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              Сryptoasset classification
            </a>
            <a
              href={"https://newreality.exchange"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              ART Tokens
            </a>
            <a
              href={"https://bond.money"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              EMT Tokens
            </a>
            <a
              href={"https://webpayment.bond"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              MISC Tokens
            </a>
            <a
              href={"https://explorer.newreality.club"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              Резерв
            </a>
            <a
              href={"https://explorer.newreality.club"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              Резерв 2
            </a>
            <a
              href="/faq"
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              FAQ
            </a>
          </div>
        </motion.li>
        <motion.li variants={itemVariants} className="relative group">
          <a
            className="p-[2px] relative w-20 block"
            href="https://wallet.newreality.club"
          >
            <div className="px-2 py-2 xl:py-4 xl:px-8 text-center inset-0 bg-blue-600 rounded-lg relative group transition duration-300 ease-in-out text-white zoom">
              Sing in
            </div>
          </a>
        </motion.li>
      </motion.ul>
    </div>
  );
};

export default MobileMenu;
