import { useTranslation } from "react-i18next";

const BlockchainPage = () => {
  const { t } = useTranslation();

  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="py-3 text-lg font-normal leading-8">
          {t(`Blockchain.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <div>{paragraphs}</div>;
  }

  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <div className="mx-auto max-w-[1248px]">
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Blockchain.paragraph1.title")}
          </h1>
          <p className="py-3 text-lg leading-8 font-bold">
            {t(`Blockchain.paragraph1.descr.1`)}
          </p>
          {Paragraphs(1, 3, 2)}
          <p className="py-3 text-lg leading-8 font-bold">
            {t(`Blockchain.paragraph1.descr.4`)}
          </p>
          <ul className="list-disc list-inside ps-10">
            <li>{t(`Blockchain.paragraph1.descr.5`)}</li>
            <li>{t(`Blockchain.paragraph1.descr.6`)}</li>
            <li>{t(`Blockchain.paragraph1.descr.7`)}</li>
          </ul>
          {Paragraphs(1, 9, 8)}
          <p className="py-3 text-lg leading-8 font-bold">
            {t(`Blockchain.paragraph1.descr.10`)}
          </p>
          {Paragraphs(1, 15, 11)}
          
          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Blockchain.paragraph2.title")}
          </h1>
          {Paragraphs(2, 2)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Blockchain.paragraph3.title")}
          </h1>
          {Paragraphs(3, 15)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Blockchain.paragraph4.title")}
          </h1>
          <p className="py-3 text-lg leading-8 font-bold">
            {t(`Blockchain.paragraph4.descr.1`)}
          </p>
          {Paragraphs(4, 3, 2)}

          <p className="py-3 text-lg leading-8 font-bold">
            {t(`Blockchain.paragraph4.descr.4`)}
          </p>
          {Paragraphs(4, 6, 5)}

          <p className="py-3 text-lg leading-8 font-bold">
            {t(`Blockchain.paragraph4.descr.7`)}
          </p>
          {Paragraphs(4, 11, 8)}

          <p className="py-3 text-lg leading-8 font-bold">
            {t(`Blockchain.paragraph4.descr.12`)}
          </p>
          <p className="py-3 text-lg leading-8">
            {t(`Blockchain.paragraph4.descr.13`)}
            <a href="https://wallet.newreality.club" className="text-blue-400">
            https://wallet.newreality.club
            </a>
          </p>
          <p className="py-3 text-lg leading-8">
            {t(`Blockchain.paragraph4.descr.14`)}
            <a href="/TariffsPage" className="text-blue-400">
              {t(`Blockchain.paragraph4.descr.19`)}
            </a>
          </p>

          <p className="py-3 text-lg leading-8 font-bold">
            {t(`Blockchain.paragraph4.descr.15`)}
          </p>
          {Paragraphs(4, 18, 16)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Blockchain.paragraph5.title")}
          </h1>

          <p className="py-3 text-lg leading-8 font-bold">
            {t(`Blockchain.paragraph5.descr.1`)}
          </p>
          {Paragraphs(5, 2, 2)}

          <p className="py-3 text-lg leading-8 font-bold">
            {t(`Blockchain.paragraph5.descr.3`)}
          </p>
          {Paragraphs(5, 5, 4)}
          <ul className="list-disc list-inside ps-10">
            <li>
              <a href="/ARTPage" className="text-blue-400">
                {t(`Blockchain.paragraph5.descr.6`)}
              </a>
            </li>
            <li>
              <a href="/EmtPage" className="text-blue-400">
                {t(`Blockchain.paragraph5.descr.7`)}
              </a>
            </li>
            <li>
              <a href="/UnboundPage" className="text-blue-400">
                {t(`Blockchain.paragraph5.descr.8`)}
              </a>
            </li>
          </ul>
          <p className="py-3 text-lg leading-8 mb-3">
            {t(`Blockchain.paragraph5.descr.9`)}
          </p>

          <p className="py-3 text-lg leading-8 mb-3">
            {t(`Blockchain.paragraph5.descr.10`)}
          </p>
          <p className="py-3 text-lg leading-8 mb-3">
            {t(`Blockchain.paragraph5.descr.11`)}
            <a href="/Createtoken" className="text-blue-400">
              {t(`Blockchain.paragraph5.descr.13`)}
            </a>
          </p>
          <p className="py-3 text-lg leading-8  mb-3">
            {t(`Blockchain.paragraph5.descr.12`)}
          </p>

          <h1 className="text-3xl font-bold leading-8 text-center my-10">
            {t("Blockchain.paragraph6.title")}
          </h1>
          <ul className="list-disc list-inside ">
            <li>{t(`Blockchain.paragraph6.descr.1`)}</li>
            <li>{t(`Blockchain.paragraph6.descr.2`)}</li>
            <li>{t(`Blockchain.paragraph6.descr.3`)}</li>
            <li>{t(`Blockchain.paragraph6.descr.4`)}</li>
            <li>{t(`Blockchain.paragraph6.descr.5`)}</li>
            <li>{t(`Blockchain.paragraph6.descr.6`)}</li>
          </ul>
          <p className="py-3 text-lg leading-8  mb-3">
            {t(`Blockchain.paragraph6.descr.7`)}
            <a href="/Forvalidators" className="text-blue-400">
              {t(`Blockchain.paragraph6.descr.9`)}
            </a>
          </p>
          <p className="py-3 text-lg leading-8  mb-3">
            {t(`Blockchain.paragraph6.descr.8`)}
            <a href="/CryptoATM" className="text-blue-400">
              {t(`Blockchain.paragraph6.descr.9`)}
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default BlockchainPage;
