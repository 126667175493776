import React from "react";
import tokenData from "../TokenInfo/TokenData";
import Marquee from "react-fast-marquee";
import { useTheme } from "../../providers/ThemeProvider";
import Card from "./Card";

function Carousel() {
  const { theme } = useTheme();
  return (
    <>
      <div className=" h-[390px] hidden xl:block">
        <Marquee
          className="h-full"
          autoFill={false}
          pauseOnHover={true}
          gradient={true}
          gradientColor={
            theme === "light" ? "rgba(255, 255, 255, 1)" : "rgba(18, 23, 35, 1)"
          }
          gradientWidth={100}
        >
          {tokenData.map((cardItem) => (
            <Card key={cardItem.id} cardItem={cardItem} />
          ))}
        </Marquee>
      </div>
      <div className="flex flex-wrap xl:hidden z-50 relative gap-10 justify-center items-center mb-[200px]">
          {tokenData.map((cardItem) => (
            <Card key={cardItem.id} cardItem={cardItem} />
          ))}
      </div>
    </>
  );
}

export default Carousel;
