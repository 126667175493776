import Faq from "../components/Faq/faq";

const FaqPage = () => {
  return (
    <section className="h-auto">
      <div className="container">
        <Faq />
      </div>
    </section>
  );
};

export default FaqPage;
