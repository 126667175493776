import { FaBars } from 'react-icons/fa';
import classNames from 'classnames';
import { motion } from "framer-motion";

const MobileMenuButton = ({ onClick, isOpen }) => {
    return (
      <motion.button
        whileTap={{ scale: 0.9 }}
        className={classNames('block xl:hidden px-2 text-3xl text-black dark:text-white')}
        onClick={onClick}
      >
        <FaBars className={classNames({'transform rotate-180': isOpen })} />
      </motion.button>
    );
  };

export default MobileMenuButton