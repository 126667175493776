import wallet from "../../images/brands/wallet.svg";
import asset_fund from "../../images/brands/asset_fund.svg";
import cryptocurrencie from "../../images/brands/cryptocurrencies_exchange.svg";


const brandsData = [
  {
    id: 1,
    name: "wallet",
    href: "https://webpayment.bond",
    image: wallet,
    title: "#Webpayment Gateway",
    size: "text-2xl",
    style: "italic",
  },
  {
    id: 2,
    name: "asset fund",
    href: "https://newreality.cc",
    image: asset_fund,
    title: "#Chat service",
    size: "text-2xl",
    style: "",
  },
  {
    id: 3,
    name: "Cryptocurrencies Exchange",
    href: "https://newreality.exchange",
    image: cryptocurrencie,
    title: "#New Reality Exchange",
    size: "text-lg",
    style: "font-bold",
  },
  {
    id: 4,
    href: "https://p2p.newreality.exchange",
    image: wallet,
    title: "#P2P Exchange Service",
    size: "text-md",
    style: "",
  },
  {
    id: 5,
    href: "https://wallet.newreality.club",
    image: asset_fund,
    title: "#Crypto Wallet",
    size: "text-2xl",
    style: "font-bold",
  },
  {
    id: 6,
    href: "https://newreality.bond",
    image: cryptocurrencie,
    title: "#New Reality Tokenizer",
    style: "italic",
  },
  {
    id: 7,
    name: "PlainAdmin",
    href: "https://newreality.fund",
    image: wallet,
    title: "#Digital Asset Fund",
  },
  {
    id: 8,
    name: "PlainAdmin",
    href: "https://explorer.newreality.club",
    image: asset_fund,
    title: "#BigDipper Explorer",
  },
];

export default brandsData;
