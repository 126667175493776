import { useTranslation } from "react-i18next";

const CreateToken = () => {
  const { t } = useTranslation();

  return (
    <section className="py-16 px-3 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="mx-auto max-w-[1248px]">
        <h1 className="text-3xl font-bold text-center my-10">{t("createToken.title")}</h1>
        <div className="flex flex-col text-lg gap-5">
          <div>
            <p className="">{t("createToken.descr.1")}</p>
            <p className="my-3">{t("createToken.descr.2")}</p>
            <p className="text-blue-500">
              <a href="/ARTPage">{t("createToken.descr.3")}</a>
            </p>
            <p className="text-blue-500">
              <a href="/EmtPage">{t("createToken.descr.4")}</a>
            </p>
            <p className="text-blue-500">
              <a href="/UnboundPage">{t("createToken.descr.5")}</a>
            </p>
            <p className="mt-3">{t("createToken.descr.6")}</p>
            <p className="mt-3">
              {t("createToken.descr.7")}
              <a className="text-blue-500" href="https://wallet.newreality.club">
                https://wallet.newreality.club
              </a>
            </p>
            <img src={require("../images/createToken/1.png")} alt="Create" className="mx-auto rounded-xl mt-8" />
          </div>
          <div>
            <p className="">{t("createToken.descr.8")}</p>
            <img src={require("../images/createToken/2.png")} alt="Create" className="mx-auto rounded-xl mt-8" />
          </div>
          <div>
            <p className="">{t("createToken.descr.9")}</p>
            <p className="mt-5">{t("createToken.descr.10")}</p>
            <p className="">{t("createToken.descr.11")}</p>
            <p className="">{t("createToken.descr.12")}</p>
            <p className="">{t("createToken.descr.13")}</p>
            <p className="">{t("createToken.descr.14")}</p>
            <img src={require("../images/createToken/3.png")} alt="Create" className="mx-auto rounded-xl mt-20" />
          </div>
          <div>
            <p className="mt-2">{t("createToken.descr.15")}</p>
            <p className="mt-5">{t("createToken.descr.16")}</p>
            <p className="mt-5">{t("createToken.descr.17")}</p>
            <p className="mt-5">{t("createToken.descr.18")}</p>
            <p className="mt-5">{t("createToken.descr.19")}</p>
            <img src={require("../images/createToken/4.png")} alt="Create" className="mx-auto rounded-xl mt-20" />
          </div>
          <div>
            <p className="mt-5">{t("createToken.descr.20")}</p>
            <p className="mt-5">{t("createToken.descr.21")}</p>
            <p className="mt-5">{t("createToken.descr.22")}</p>
            <p className="mt-5">{t("createToken.descr.23")}</p>
            <img src={require("../images/createToken/5.png")} alt="Create" className="mx-auto rounded-xl mt-20" />
          </div>
          <div>
            <p className="">{t("createToken.descr.24")}</p>
            <img src={require("../images/createToken/6.png")} alt="Create" className="mx-auto rounded-xl mt-20" />
          </div>
          <div>
            <p className="mt-1">{t("createToken.descr.25")}</p>
            <p className="mt-5">{t("createToken.descr.26")}</p>
            <img src={require("../images/createToken/7.png")} alt="Create" className="mx-auto rounded-xl mt-20" />
          </div>
          <div>
            <p className="mt-1">{t("createToken.descr.27")}</p>
            <p className="mt-5">{t("createToken.descr.28")}</p>
            <p className="mt-5">{t("createToken.descr.29")}</p>
            <img src={require("../images/createToken/8.png")} alt="Create" className="mx-auto rounded-xl mt-20" />
          </div>
          <div>
            <p className="">{t("createToken.descr.30")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateToken;
