import { useParams } from "react-router-dom";
import TokenInfo from "../components/TokenInfo/TokenInfo";
import TokenData from "../components/TokenInfo/TokenData";
import BreadcrumbC from "../components/Breadcrumb/Breadcrumb";


const TokenPage = () => {
  const { tiker } = useParams();
  const tokenItem = TokenData.find((token) => token.tiker === tiker);
  return (
    <section className="pt-16 md:pt-20 lg:pt-24  min-h-screen beams_big bg-contain xl:bg-cover">
        <div className="container beams">
          <BreadcrumbC tokenItem={tokenItem} />
          <TokenInfo tokenItem={tokenItem} />
        </div>
    </section>
  );
};

export default TokenPage;
