import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";

function BreadcrumbC({tokenItem}) {
  return (
    <Breadcrumb className="my-6 ">
      <Breadcrumb.Item href="/" icon={HiHome}>
        Home
      </Breadcrumb.Item>
      <Breadcrumb.Item href="/popularTokens">{"Products"}</Breadcrumb.Item>
      <Breadcrumb.Item href="">{tokenItem.title}</Breadcrumb.Item>
    </Breadcrumb>
  );
}

export default BreadcrumbC;
