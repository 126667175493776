import { useTranslation } from "react-i18next";
const PresalePage = () => {
  const { t } = useTranslation();

  function Paragraphs(p, end, start = 1, plus = false) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      if (plus) {
        paragraphs.push(
          <p key={i} className="py-3 text-lg font-normal leading-8">
            <span className="text-green-700 dark:text-green-400 font-bold text-xl "> + </span>
            {t(`Presale.paragraph${p}.descr.${i}`)}
          </p>
        );
      } else {
        paragraphs.push(
          <p key={i} className="py-3 text-lg font-normal leading-8">
            {t(`Presale.paragraph${p}.descr.${i}`)}
          </p>
        );
      }
    }
    return <div>{paragraphs}</div>;
  }

  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <div className="mx-auto max-w-[1248px]">
          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Presale.paragraph1.title")}</h1>
          <p className="py-3 text-lg font-bold text-green-700 dark:text-green-400 leading-8">{t(`Presale.paragraph1.descr.1`)}</p>
          {Paragraphs(1, 3, 2)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Presale.paragraph2.title")}</h1>
          {Paragraphs(2, 8)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Presale.paragraph3.title")}</h1>
          {Paragraphs(3, 3)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Presale.paragraph4.title")}</h1>
          {Paragraphs(4, 2)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Presale.paragraph5.title")}</h1>
          {Paragraphs(5, 11)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Presale.paragraph6.title")}</h1>
          
          <ul className="list-disc list-inside">
            <li> <a className="text-blue-500 font-bold" href="https://wallet.newreality.club">{t("Presale.paragraph6.descr.1")}</a></li>
            <li> <a className="text-blue-500 font-bold" href="/MerchantConfig">{t("Presale.paragraph6.descr.2")}</a></li>
            <li> <a className="text-blue-500 font-bold" href="https://doc.newreality.bond">{t("Presale.paragraph6.descr.3")}</a></li>
            <li> <a className="text-blue-500 font-bold" href="https://forms.gle/j4V2Q6r6evYBfgBy9">{t("Presale.paragraph6.descr.4")}</a></li>
            <li> <a className="text-blue-500 font-bold" href="https://forms.gle/G7dEavsNZuPK7guN8">{t("Presale.paragraph6.descr.5")}</a></li>
            <li> <a className="text-blue-500 font-bold" href="/TariffsPage">{t("Presale.paragraph6.descr.6")}</a></li>
          </ul>
          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Presale.paragraph7.title")}</h1>
          <p className="py-3 text-lg font-bold text-green-700 dark:text-green-400 leading-8">{t(`Presale.paragraph7.descr.1`)}</p>
          {Paragraphs(7, 2, 2)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Presale.paragraph8.title")}</h1>

          {Paragraphs(8, 5)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Presale.paragraph9.title")}</h1>
          <p className="py-3 text-lg font-bold text-green-700 dark:text-green-400 leading-8">{t(`Presale.paragraph9.descr.1`)}</p>
          {Paragraphs(9, 2, 2)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Presale.paragraph10.title")}</h1>
          {Paragraphs(10, 6)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Presale.paragraph11.title")}</h1>
          {Paragraphs(11, 3)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Presale.paragraph12.title")}</h1>
          {Paragraphs(12, 14, 1, true)}

          <h1 className="text-3xl font-bold leading-8 text-center my-10">{t("Presale.paragraph13.title")}</h1>

          <ul className="list-disc list-inside">
            <li>
              <a className="text-blue-500 font-bold" href="/СlassifyingPage">
                {t("Presale.paragraph13.descr.1")}
              </a>
            </li>

            <li>
              <a className="text-blue-500 font-bold" href="/Createtoken">
                {t("Presale.paragraph13.descr.2")}
              </a>
            </li>

            <li>
              <a className="text-blue-500 font-bold" href="/ExchangeDesc">
                {t("Presale.paragraph13.descr.3")}
              </a>
            </li>

            <li>
              <a className="text-blue-500 font-bold" href="https://wallet.newreality.club">
                {t("Presale.paragraph13.descr.4")}
              </a>
            </li>

            <li>
              <a className="text-blue-500 font-bold" href="/faq">
                {t("Presale.paragraph13.descr.5")}
              </a>
            </li>

            <li>
              <a className="text-blue-500 font-bold" href="https://forms.gle/j4V2Q6r6evYBfgBy9">
                {t("Presale.paragraph13.descr.6")}
              </a>
            </li>

            <li>
              <a className="text-blue-500 font-bold" href="https://forms.gle/G7dEavsNZuPK7guN8">
                {t("Presale.paragraph13.descr.7")}
              </a>
            </li>

            <li>
              <a className="text-blue-500 font-bold" href="/TariffsPage">
                {t("Presale.paragraph13.descr.8")}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default PresalePage;
