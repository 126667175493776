import { useTranslation } from "react-i18next";

const PrivacyNoticePage = () => {
  const { t } = useTranslation();
  function Paragraphs(p, end, start = 1) {
    const paragraphs = [];
    for (let i = start; i <= end; i++) {
      paragraphs.push(
        <p key={i} className="py-3 text-lg font-normal leading-8">
          {t(`PrivacyNotice.paragraph${p}.descr.${i}`)}
        </p>
      );
    }
    return <div>{paragraphs}</div>;
  }
  return (
    <section className="py-16 md:py-20 lg:py-24 h-auto text-black dark:text-white">
      <div className="container">
        <div className="mx-auto max-w-[1248px]">
          <h1 className="text-3xl font-bold pt-9 leading-8">
            {t("PrivacyNotice.paragraph1.title")}
          </h1>
          {Paragraphs(1, 7)}

          <h1 className="text-3xl font-bold pt-9 leading-8">
            {t("PrivacyNotice.paragraph2.title")}
          </h1>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg my-10">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="px-6 py-3 w-1/4">
                    {t("PrivacyNotice.paragraph2.table.thead.1")}
                  </th>
                  <th scope="col" class="px-6 py-3 w-1/4">
                    {t("PrivacyNotice.paragraph2.table.thead.2")}
                  </th>
                  <th scope="col" class="px-6 py-3 w-1/4">
                    {t("PrivacyNotice.paragraph2.table.thead.3")}
                  </th>
                  <th scope="col" class="px-6 py-3 w-1/4">
                    {t("PrivacyNotice.paragraph2.table.thead.4")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph2.table.tbody.r1.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph2.table.tbody.r1.2")}
                  </td>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph2.table.tbody.r1.3")}
                  </td>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph2.table.tbody.r1.4")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph2.table.tbody.r2.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph2.table.tbody.r2.2")}
                  </td>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph2.table.tbody.r2.3")}
                  </td>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph2.table.tbody.r2.4")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph2.table.tbody.r3.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph2.table.tbody.r3.2")}
                  </td>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph2.table.tbody.r3.3")}
                  </td>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph2.table.tbody.r3.4")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph3.title")}
          </h1>
          {Paragraphs(3, 5)}

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph4.title")}
          </h1>
          {Paragraphs(4, 1)}

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph5.title")}
          </h1>
          {Paragraphs(5, 3)}

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph6.title")}
          </h1>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg my-10">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="px-6 py-3 w-1/4">
                    {t("PrivacyNotice.paragraph6.table.thead.1")}
                  </th>
                  <th scope="col" class="px-6 py-3">
                    {t("PrivacyNotice.paragraph6.table.thead.2")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph6.table.tbody.r1.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph6.table.tbody.r1.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph6.table.tbody.r2.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph6.table.tbody.r2.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph6.table.tbody.r3.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph6.table.tbody.r3.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph6.table.tbody.r4.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph6.table.tbody.r4.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph6.table.tbody.r5.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph6.table.tbody.r5.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph6.table.tbody.r6.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph6.table.tbody.r6.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph6.table.tbody.r7.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph6.table.tbody.r7.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph6.table.tbody.r8.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph6.table.tbody.r8.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph6.table.tbody.r9.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph6.table.tbody.r9.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph6.table.tbody.r10.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph6.table.tbody.r10.2")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph7.title")}
          </h1>
          {Paragraphs(7, 1)}

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph8.title")}
          </h1>

          <div class="relative overflow-x-auto shadow-md sm:rounded-lg my-10">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="px-6 py-3 w-1/4">
                    {t("PrivacyNotice.paragraph8.table.thead.1")}
                  </th>
                  <th scope="col" class="px-6 py-3">
                    {t("PrivacyNotice.paragraph8.table.thead.2")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph8.table.tbody.r1.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph8.table.tbody.r1.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph8.table.tbody.r2.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph8.table.tbody.r2.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph8.table.tbody.r3.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph8.table.tbody.r3.2")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph9.title")}
          </h1>
          <p className="py-3 text-lg font-normal leading-8">
            {t("PrivacyNotice.paragraph9.descr.1")}
          </p>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg my-10">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="px-6 py-3 w-1/4">
                    {t("PrivacyNotice.paragraph9.descr.table.thead.1")}
                  </th>
                  <th scope="col" class="px-6 py-3">
                    {t("PrivacyNotice.paragraph9.descr.table.thead.2")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph9.descr.table.tbody.r1.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph9.descr.table.tbody.r1.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph9.descr.table.tbody.r2.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph9.descr.table.tbody.r2.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {t("PrivacyNotice.paragraph9.descr.table.tbody.r3.1")}
                  </th>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph9.descr.table.tbody.r3.2")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph10.title")}
          </h1>

          <p className="py-3 text-lg font-normal leading-8">
            {t("PrivacyNotice.paragraph10.descr.1")}
          </p>
          <p className="py-3 text-lg font-normal leading-8">
            {t("PrivacyNotice.paragraph10.descr.2")}
          </p>
          <p className="py-3 text-lg font-normal leading-8">
            {t("PrivacyNotice.paragraph10.descr.3")}
          </p>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg my-10">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    {t("PrivacyNotice.paragraph10.descr.table.thead.1")}
                  </th>
                  <th scope="col" class="px-6 py-3 w-1/3">
                    {t("PrivacyNotice.paragraph10.descr.table.thead.2")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <td class="px-6 py-4  text-gray-900  dark:text-white">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r1.1.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r1.1.2")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r1.1.3")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r1.1.4")}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph10.descr.table.tbody.r1.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <td class="px-6 py-4  text-gray-900  dark:text-white">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r2.1.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r2.1.2")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r2.1.3")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r2.1.4")}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r2.2.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r2.2.2")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r2.2.3")}
                    </p>
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <td class="px-6 py-4  text-gray-900  dark:text-white">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r3.1.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r3.1.2")}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph10.descr.table.tbody.r3.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <td class="px-6 py-4  text-gray-900  dark:text-white">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r4.1.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r4.1.2")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r4.1.3")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r4.1.4")}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r4.2.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r4.2.2")}
                    </p>
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <td class="px-6 py-4  text-gray-900  dark:text-white">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r5.1.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r5.1.2")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r5.1.3")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r5.1.4")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r5.1.5")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r5.1.6")}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph10.descr.table.tbody.r5.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <td class="px-6 py-4  text-gray-900  dark:text-white">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r6.1.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r6.1.2")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r6.1.3")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r6.1.4")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r6.1.5")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r6.1.6")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r6.1.7")}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph10.descr.table.tbody.r6.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <td class="px-6 py-4  text-gray-900  dark:text-white">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r7.1.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r7.1.2")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r7.1.3")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r7.1.4")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r7.1.5")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r7.1.6")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r7.1.7")}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r7.2.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r7.2.1")}
                    </p>
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <td class="px-6 py-4  text-gray-900  dark:text-white">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r8.1.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r8.1.2")}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r8.2.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r8.2.2")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r8.2.3")}
                    </p>
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <td class="px-6 py-4  text-gray-900  dark:text-white">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r9.1.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r9.1.2")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r9.1.3")}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph10.descr.table.tbody.r9.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <td class="px-6 py-4  text-gray-900  dark:text-white">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r10.1.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r10.1.2")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r10.1.3")}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    {t("PrivacyNotice.paragraph10.descr.table.tbody.r10.2")}
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <td class="px-6 py-4  text-gray-900  dark:text-white">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r11.1.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r11.1.2")}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r11.2.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r11.2.2")}
                    </p>
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <td class="px-6 py-4  text-gray-900  dark:text-white">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r12.1.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r12.1.2")}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r12.2.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r12.2.2")}
                    </p>
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <td class="px-6 py-4  text-gray-900  dark:text-white">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r13.1.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r13.1.2")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r13.1.3")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r13.1.4")}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r13.2.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r13.2.2")}
                    </p>
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <td class="px-6 py-4  text-gray-900  dark:text-white">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r14.1.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r14.1.2")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r14.1.3")}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r14.2.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r14.2.2")}
                    </p>
                  </td>
                </tr>
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <td class="px-6 py-4  text-gray-900  dark:text-white">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r15.1.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r15.1.2")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r15.1.3")}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r15.2.1")}
                    </p>
                    <p className="py-3">
                      {t("PrivacyNotice.paragraph10.descr.table.tbody.r15.2.2")}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph11.title")}
          </h1>
          {Paragraphs(11, 2)}

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph12.title")}
          </h1>
          {Paragraphs(12, 3)}

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph13.title")}
          </h1>
          <p className="py-3 text-lg font-normal leading-8 ">
            {t("PrivacyNotice.paragraph13.descr.1")}
          </p>
          <p className="py-3 text-lg font-normal leading-8">
            {t("PrivacyNotice.paragraph13.descr.2")}
          </p>
          <p className="py-3 text-lg font-normal leading-8 ">
            {t("PrivacyNotice.paragraph13.descr.3")}
          </p>
          <ul className="ps-5 mt-2 space-y-2 list-decimal list-inside">
            <li>
              <span className="py-3 text-lg font-normal leading-8 my-5">
                {t("PrivacyNotice.paragraph13.descr.list.1")}
              </span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8 my-5">
                {t("PrivacyNotice.paragraph13.descr.list.2")}
              </span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8 my-5">
                {t("PrivacyNotice.paragraph13.descr.list.3")}
              </span>
              <ul className="ps-5 my-3 space-y-3 list-none list-inside text-lg font-normal">
                <li> {t("PrivacyNotice.paragraph13.descr.list.4")}</li>
                <li>{t("PrivacyNotice.paragraph13.descr.list.5")}</li>
                <li>{t("PrivacyNotice.paragraph13.descr.list.6")}</li>
                <li>{t("PrivacyNotice.paragraph13.descr.list.7")}</li>
                <li>{t("PrivacyNotice.paragraph13.descr.list.8")}</li>
                <li>{t("PrivacyNotice.paragraph13.descr.list.9")}</li>
                <li>{t("PrivacyNotice.paragraph13.descr.list.10")}</li>
              </ul>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8 my-5">
                {t("PrivacyNotice.paragraph13.descr.list.11")}
              </span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8 my-5">
                {t("PrivacyNotice.paragraph13.descr.list.12")}
              </span>
            </li>
          </ul>

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph14.title")}
          </h1>
          {Paragraphs(14, 3)}

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph15.title")}
          </h1>
          {Paragraphs(15, 2)}

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph16.title")}
          </h1>
          {Paragraphs(16, 1)}

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph17.title")}
          </h1>
          <p className="py-3 text-lg font-normal leading-8">
            {t("PrivacyNotice.paragraph17.descr.1")}
          </p>
          <ul className="ps-5 mt-2 space-y-1 list-disc list-inside">
            <li>
              <span className="py-3 text-lg font-normal leading-8">
                {t("PrivacyNotice.paragraph17.descr.list.1")}
              </span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">
                {t("PrivacyNotice.paragraph17.descr.list.2")}
              </span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">
                {t("PrivacyNotice.paragraph17.descr.list.3")}
              </span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">
                {t("PrivacyNotice.paragraph17.descr.list.4")}
              </span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">
                {t("PrivacyNotice.paragraph17.descr.list.5")}
              </span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">
                {t("PrivacyNotice.paragraph17.descr.list.6")}
              </span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">
                {t("PrivacyNotice.paragraph17.descr.list.7")}
              </span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">
                {t("PrivacyNotice.paragraph17.descr.list.8")}
              </span>
            </li>
            <li>
              <span className="py-3 text-lg font-normal leading-8">
                {t("PrivacyNotice.paragraph17.descr.list.9")}
              </span>
            </li>
          </ul>
          <p className="py-3 text-lg font-normal leading-8">
            {t("PrivacyNotice.paragraph17.descr.1")}
          </p>

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph18.title")}
          </h1>
          {Paragraphs(18, 7)}

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph19.title")}
          </h1>
          {Paragraphs(19, 3)}

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph20.title")}
          </h1>
          {Paragraphs(20, 1)}

          <h1 className="text-3xl font-bold pt-9 leading-8 my-5">
            {t("PrivacyNotice.paragraph21.title")}
          </h1>
          <span className="py-3 text-lg font-normal leading-8">
            {t("PrivacyNotice.paragraph21.descr.1")}
          </span>
          <a href="/" className="py-3 text-lg font-normal leading-8 text-blue-500">
            {t("PrivacyNotice.paragraph21.descr.link.1")}
          </a>
          <a href="/contact" className="py-3 text-lg font-normal leading-8 text-blue-500">
            {t("PrivacyNotice.paragraph21.descr.link.2")}
          </a>
          <span className="py-3 text-lg font-normal leading-8">
            {t("PrivacyNotice.paragraph21.descr.2")}
          </span>
          <a href="/" className="py-3 text-lg font-normal leading-8 text-blue-500">
            {t("PrivacyNotice.paragraph21.descr.link.3")}
          </a>

          <p className="py-3 text-lg font-normal leading-8">
            {t("PrivacyNotice.paragraph21.descr.3")}
          </p>
        </div>
      </div>
    </section>
  );
};

export default PrivacyNoticePage;
